import React, { useState, useEffect, useContext, useCallback } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { CiCalculator1, CiPalette } from "react-icons/ci";
import { VscReferences } from "react-icons/vsc";
import { BsThreeDotsVertical, BsExclamationTriangle, BsX } from "react-icons/bs";
import Question from "./MathQuestion";
import axios from "axios";
import Timer from "./Timer";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import { BASELINE } from "../../util";
import Review from "./Review";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DirectionModal from './DirectionModal';
import ReferenceSheet from './ReferenceSheet';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'react-bootstrap';

const ACTQuiz = ({ free, freesat }) => {
  const { id: initialId } = useParams();
  const [id, setId] = useState(initialId);

  const [questionsarr, setquestionsarr] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const [section, setsection] = useState(4);

  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [showCalculator, setShowCalculator] = useState(false);
  const [questionOverViewOpen, setQuestionOverviewOpen] = useState(false);
  const [showReference, setShowReference] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [bookmarkedQuestions, setBookMarkedQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [error, setError] = useState('');
  const [showDirectionModal, setShowDirectionModal] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [showDirectionModalRef, setShowDirectionRefModal] = useState(false);
  const [sat, setsat] = useState("");
  const [answers, setanswers] = useState([]);
  const [breaks, setbreaks] = useState(true);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const [test_id, settest_id] = useState(uuidv4());
  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [context, setContext] = useState('');

  const handleSubmit2 = () => {
    const questionId = currentQuestion.q_num;
    const reportFrom = user.first_name + " " + user.last_name;

    // Do something with questionId, reportFrom, and context

    var data = {
      questionId: questionId,
      name: reportFrom,
      context: context,
      type: "ACT"
    }

    axios.post(BASELINE + 'questions/report/note', [data])
      .then(response => {
        alert('Report submitted successfully!');
      })
      .catch(error => {
        console.error(error);
      });

    setContext('');
    setShowModal(false);
  };

  const handleContextChange = (event) => {
    setContext(event.target.value);
  };

  const handleReportClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = () => {
    // Clear the context and close the modal
    setContext('');
    setShowModal(false);
  };

  useEffect(() => {
    if (breaks === false && (section === 2)) {
      setLoadingQuestions(true);
      setTimeLeft(600);
      setbreaks(true);
    } else {
      fetchMathData();
    }


    // Start the countdown
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [section, id]);

  useEffect(() => {
    if (timeLeft == 0) {
      if (breaks == false) {
        alert("Time's up!");
        handleSubmit();
      } else {
        alert("Time's up! Resume the test.");
        setbreaks(true);
        setLoadingQuestions(false);
        fetchMathData();
      }
    }

    if (timeLeft == -1) {
      alert("Resume the test.");
      setbreaks(true);
      setLoadingQuestions(false);
      fetchMathData();
    }
  }, [timeLeft]);

  useEffect(() => {
    if (questionsarr > 0) {
      getQuestions();
    }
  }, [questionsarr]);

  useEffect(() => {
    var elt = document.getElementById('calculator');
    var calculator = window.Desmos?.GraphingCalculator(elt, {
      graphpaper: true,
      keypad: true
    });
    return () => {
      calculator?.destroy();
    }
  }, [showCalculator]);

  useEffect(() => {
    if (questions.length > 0 && currentQuestion) {
      let q = questions.find((ques => ques?.q_num === currentQuestion?.q_num));
      setCurrentQuestion(q);
    }
  }, [questions]);

  const fetchMathData = async () => {
    setbreaks(false);

    setquestionsarr([]);

    if (section === 4) {
      setTimeLeft(2700); //2700
      setsat("english");
      setquestionsarr(75);
    } else if (section === 3) {
      setTimeLeft(3600); //3600
      setsat("math");
      setquestionsarr(60);
    } else if (section === 2) {
      setTimeLeft(2100); //2100
      setsat("reading");
      setquestionsarr(40);
    } else {
      setTimeLeft(2100); //2100
      setsat("science");
      setquestionsarr(41);
    }

    setLoadingQuestions(true);
  };

  async function getQuestions() {
    var know_sec;
    if (section === 4) {
      know_sec = "english"
    } else if (section === 3) {
      know_sec = "math"
    } else if (section === 2) {
      know_sec = "reading"
    } else if (section === 1) {
      know_sec = "science"
    }

    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "get/act/quiz", { params: { exam_id: id, section: section } });
      let arr = [res.data]
      let mappedQuestions = arr[0].map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });


      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[0]);
      setLoadingQuestions(false);
    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  // Function to find the ACT score based on correct answers
  function getACTScore(correctAnswers, scoringArray) {
    let score = 36; // Start with the maximum score

    for (let i = 0; i < scoringArray.length; i++) {
      const range = scoringArray[i];

      // Skip if the range is "-"
      if (range === "-") {
        score--; // Decrement the score even though we're skipping this range
        continue;
      }

      if (range.includes("-")) {
        const [min, max] = range.split("-").map(Number);
        if (correctAnswers >= min && correctAnswers <= max) {
          return score; // Return the current score
        }
      } else {
        const num = parseInt(range);
        if (correctAnswers == num) {
          return score; // Return the current score
        }
      }

      score--; // Decrement the score as we move to the next lower score range
    }

    return 1; // Return the lowest score if no match is found
  }

  async function handleSubmit() {
    if (section === 4) {
      let newDat = questions.map((question) => {
        let correctAnswer = question.answer.toLowerCase();

        if (question.selectedOption === correctAnswer) {
          question.correct = true;
        } else {
          question.correct = false;
        }


        //question.correct = Math.random() < 0.5;

        return {
          section: "english",
          bookMarked: question.bookMarked,
          correct: question.correct,
          selectedOption: question.selectedOption,
          answered: question.answered,
          questionIds: question.q_num,
          tag: 'english',
          topic: question.practice_2,
          correct_answer: correctAnswer,
          type: 'ACT',
          qid: question.qid,
          category: question.category,
        }
      });
      setanswers(newDat);
      setbreaks(false);
      setsection(3);

      return;
    }

    if (section === 3) {
      let newDat = questions.map((question) => {
        let correctAnswer = question.answer.toLowerCase();
        if (question.selectedOption === correctAnswer) {
          question.correct = true;
        } else {
          question.correct = false;
        }


        //question.correct = Math.random() < 0.5;


        return {
          section: "math",
          bookMarked: question.bookMarked,
          correct: question.correct,
          selectedOption: question.selectedOption,
          answered: question.answered,
          questionIds: question.q_num,
          tag: 'math',
          topic: question.practice_2,
          correct_answer: correctAnswer,
          type: 'ACT',
          qid: question.qid,
          category: question.category,
        }
      });

      setanswers(prevAnswers => ([
        ...prevAnswers,
        ...newDat
      ]));

      setbreaks(false);

      setsection(2);

      return;
    }

    if (section === 2) {
      let newDat = questions.map((question) => {
        let correctAnswer = question.answer.toLowerCase();
        if (question.selectedOption === correctAnswer) {
          question.correct = true;
        } else {
          question.correct = false;
        }


        //question.correct = Math.random() < 0.5;

        return {
          section: "reading",
          bookMarked: question.bookMarked,
          correct: question.correct,
          selectedOption: question.selectedOption,
          answered: question.answered,
          questionIds: question.q_num,
          tag: 'reading',
          topic: question.practice_2,
          correct_answer: correctAnswer,
          type: 'ACT',
          qid: question.qid,
          category: question.category,
        }
      });

      setanswers(prevAnswers => ([
        ...prevAnswers,
        ...newDat
      ]));

      setbreaks(false);

      setsection(1);
      return;
    }

    try {
      let newDat = questions.map((question) => {
        var correctAnswer;

        if (question.correct_answer) {
          // Parse the string into a JSON array
          correctAnswer = JSON.parse(question.correct_answer);

          // If there's only one element, convert it to lowercase
          if (correctAnswer.length === 1) {
            correctAnswer = [correctAnswer[0].toLowerCase()];
          }
        } else {
          correctAnswer = ["No Answer"];
        }

        let isCorrect = false;
        if (Array.isArray(correctAnswer)) {
          for (let i = 0; i < correctAnswer.length; i++) {
            if (String(question.selectedOption).trim() === String(correctAnswer[i]).trim()) {
              isCorrect = true;
              break;
            }
          }
        } else {
          isCorrect = question.selectedOption === correctAnswer;
        }
        if (question.selectedOption === correctAnswer) {
          question.correct = true;
        } else {
          question.correct = false;
        }


        //question.correct = Math.random() < 0.5;


        return {
          section: "science",
          bookMarked: question.bookMarked,
          correct: question.correct,
          selectedOption: question.selectedOption,
          answered: question.answered,
          questionIds: question.q_num,
          tag: 'science',
          topic: question.practice_2,
          correct_answer: correctAnswer,
          type: 'ACT',
          qid: question.qid,
          category: question.category,
        }
      });

      let updatedAnswers = [
        ...answers,
        ...newDat
      ];

      setanswers(updatedAnswers);

      let res = await axios.get(BASELINE + "get/act/grading", { params: { exam_id: id } });
      let arr = [res.data]

      const englishScores = JSON.parse(arr[0][0].english);
      const mathScores = JSON.parse(arr[0][0].math);
      const readingScores = JSON.parse(arr[0][0].reading);
      const scienceScores = JSON.parse(arr[0][0].science);

      // Initialize counters for each section
      let correctCounts = {
        english: 0,
        math: 0,
        reading: 0,
        science: 0
      };

      // Count the number of correct answers for each section
      for (let i = 0; i < updatedAnswers.length; i++) {
        let section = updatedAnswers[i].section;
        if (updatedAnswers[i].correct === true) {
          correctCounts[section] += 1;
        }
      }

      // Use the `getACTScore` function to get the score for each section
      const englishScore = getACTScore(correctCounts.english, englishScores);
      const mathScore = getACTScore(correctCounts.math, mathScores);
      const readingScore = getACTScore(correctCounts.reading, readingScores);
      const scienceScore = getACTScore(correctCounts.science, scienceScores);

      const actScores = {
        english: englishScore,
        math: mathScore,
        reading: readingScore,
        science: scienceScore
      };


      const correctQuestions = updatedAnswers.filter(question => question.correct === true);
      const incorrectQuestions = updatedAnswers.filter(question => question.correct === false);
      const bookmarkedQuestions = updatedAnswers.filter(question => question.bookMarked === true); // Assuming 'bookMarked' exists
      const nullCorrectAnswerQuestions = updatedAnswers.filter(question => question.selectedOption === null);

      let data = {
        ACT_scores: updatedAnswers, //answers arr
        userId: user.u_id,
        username: user.first_name + " " + user.last_name,
        test_name: "ACT " + id,
        test_id: test_id,
        act_scores: actScores,
        type: "ACT",
        correctQuestions: correctQuestions,
        incorrectQuestions: incorrectQuestions,
        bookmarkedQuestions: bookmarkedQuestions,
        nullCorrectAnswerQuestions: nullCorrectAnswerQuestions
      }

      try {
        let saveResult = await axios.post(BASELINE + "tests/act", data);
        navigate(`/results/act/${saveResult.data.testId}`);
      } catch (err) {
        console.error("Error saving test results:", err);
        // Handle error (e.g., show a notification)
      }

    } catch (err) {
      toast.error(err.message);
      setError(err.message);
    }
  }

  const handleBookMark = (itemId) => {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.q_num == itemId) {
          if (question.bookMarked) {
            return { ...question, bookMarked: false };
          } else {
            return { ...question, bookMarked: true };
          }
        } else {
          return question;
        }
      })
    }))
  }

  async function handleSelectOption(itemId, option) {
    setQuestions((pre) => {
      const updatedQuestions = pre.map((question) => {
        if (question?.q_num == itemId) {
          return { ...question, selectedOption: option };
        } else {
          return question;
        }
      });

      // Find the updated question
      const updatedQuestion = updatedQuestions.find((question) => question.q_num == itemId);

      // Update the currentQuestion state
      setCurrentQuestion(updatedQuestion);

      return updatedQuestions;
    });
  }

  async function handleCrossOutOptions(itemId, option) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.q_num == itemId) {
          let crossedOutOptions = question.crossedOutOptions;
          if (crossedOutOptions.includes(option)) {
            crossedOutOptions = crossedOutOptions.filter((el) => el !== option);
          } else {
            crossedOutOptions.push(option);
          }
          return { ...question, crossedOutOptions: crossedOutOptions };
        } else {
          return question;
        }
      })
    }))
  }

  const handleAnswerValueChange = (itemId, value) => {
    setQuestions(prevQuestions =>
      prevQuestions.map(question =>
        question.q_num === itemId ? { ...question, selectedOption: value } : question
      )
    );
  };

  async function handleNext() {
    if (currentQuestion.index < questions[questions.length - 1].index) {
      setCurrentQuestion((pre) => questions[pre.index + 1])
    } else {
      setCurrentQuestion(null);
      setQuestionOverviewOpen(false);
    }
  }

  async function handleBack() {
    if (!currentQuestion) {
      setCurrentQuestion(questions[questions.length - 1]);
      return;
    }
    if (currentQuestion.index > 0) {
      setCurrentQuestion((pre) => questions[pre.index - 1])
    } else {

    }
  }

  const closeRefModal = () => {
    setShowDirectionRefModal(false);
  };

  const closeModal = () => {
    setShowDirectionModal(false);
  };

  const getSectionDisplay = (section) => {
    switch (section) {
      case 4:
        return 1;
      case 3:
        return 2;
      case 2:
        return 3;
      case 1:
        return 4;
      default:
        return section;
    }
  };

  return (
    <div className="min-h-screen bg-white p-1 relative">
      {loadingQuestions ?
        <div className="flex flex-col justify-center items-center py-48">
          <div className="border-t-transparent border-solid animate-spin rounded-full border-blue-400 border-8 h-52 w-52"></div>
          <div className="absolute mt-[-50px]">
            {breaks === true ? <Timer minutes={minutes} seconds={seconds} /> : null}
          </div>
          {breaks === true ?
            <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800 mt-5" onClick={() => { setTimeLeft(-1); }}>
              Resume the test
            </motion.button>
            : null}
        </div>
        :
        <>
          <div className="flex flex-row justify-between">

            <div className="flex flex-row justify-between items-center w-[100%] mt-[20px] pb-[10px] border-b border-gray-200">
              <div className="absolute ml-[20px] flex flex-col">
                <h3>
                  <strong>
                    Section:  {sat}
                  </strong>
                </h3>
                <motion.div whileTap={{ scale: 0.97 }} className="left-0 flex flex-row gap-1 hover:text-blue-900">
                  <div>
                    <button onClick={() => setShowDirectionModal((pre) => !pre)}>Directions</button>
                  </div>
                  <div onClick={() => setShowDirectionModal(true)} className="flex flex-row items-center cursor-pointer">
                    <BsChevronDown />
                  </div>
                </motion.div>
                {showDirectionModal && <DirectionModal onClose={closeModal} mode={sat} />}
              </div>

              <div className="flex flex-col items-center justify-center flex-grow">
                <div className="flex flex-col sm:flex-row items-center text-center sm:text-left">
                  <Timer minutes={minutes} seconds={seconds} />
                </div>
              </div>


              {sat === "math" && (<>
                <motion.button whileTap={{ scale: 0.97 }} onClick={() => setShowCalculator(pre => !pre)} className={`mr-[4px] flex flex-col items-center cursor-pointer hover:text-blue-900 ${showCalculator ? "text-blue-900" : "text-black"}`}>
                  <div className={`flex flex-row justify-center`}>
                    <CiCalculator1 size="1.5em" />
                  </div>
                  <div>
                    <p className="text-sm">Calculator</p>
                  </div>
                </motion.button>
                <motion.button whileTap={{ scale: 0.97 }} className={`mr-[4px] flex flex-col items-center cursor-pointer hover:text-blue-900 ${showReference === true ? 'text-blue-900' : "text-black"} `} onClick={() => setShowDirectionRefModal(!showDirectionModalRef)}>
                  <div className="flex flex-row justify-center">
                    <VscReferences size="1.5em" />
                  </div>
                  <div>
                    <p className="text-sm">Reference</p>
                  </div>
                </motion.button>
                {showDirectionModalRef && <ReferenceSheet onClose={closeRefModal} />}
              </>)}

              <div className="ml-[5px] flex flex-col items-center mr-[20px]">
                {!free && (
                  <>
                    <div className="flex flex-col items-center" onClick={() => setShowOptions(!showOptions)}>
                      <BsThreeDotsVertical size="1.5em" />
                      <p className="text-sm mt-1 text-center">More</p>
                    </div>

                    {showOptions && (
                      <div className="fixed mt-[70px] mr-[10px] flex flex-row justify-center cursor-pointer hover:text-red-900 z-[100]" onClick={handleReportClick}>
                        <BsExclamationTriangle size="1.5em" />
                        <p className="text-sm ml-1">Report</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>


            <div className="flex flex-row gap-2">

              <div className="flex flex-col cursor-pointer">
                {showModal && (
                  <div className="fixed z-[20] inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                      </div>
                      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                          <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                Report Card
                              </h3>
                              <div className="mt-2">
                                <form>
                                  <label>Question ID:</label>
                                  <input type="text" className="border p-1 mt-1 w-full" value={currentQuestion.q_num} readOnly />
                                  <label>Report from:</label>
                                  <input type="text" className="border p-1 mt-1 w-full" value={user.first_name + " " + user.last_name} readOnly />
                                  <label>Context:</label>
                                  <textarea
                                    className="border p-1 mt-1 w-full"
                                    value={context}
                                    onChange={handleContextChange}
                                  />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={handleSubmit2}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={handleCloseModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>

          <div className="h-[90%]">
            <motion.div drag style={{
              width: '600px',
              height: '400px',
              position: 'absolute',
              zIndex: 100,
              display: showCalculator ? "inline" : "none",
            }}
              dragConstraints={{
                top: -125,
                right: 500,
                bottom: 300,
                left: -125,
              }}
            >
              <div id="calculator" className="w-full h-full">

              </div>

            </motion.div>
            {/* question */}
            <div className="flex flex-col">

              {currentQuestion ?
                <div className="flex flex-col sm:flex-row justify-center h-[50%]">
                  <Question
                    sat={sat}
                    handleAnswerValueChange={handleAnswerValueChange}
                    handleCrossOutOptions={handleCrossOutOptions}
                    handleSelectOption={handleSelectOption}
                    currentQuestion={currentQuestion}
                    idx={1}
                    bookmarkedQuestions={bookmarkedQuestions}
                    setBookMarkedQuestions={setBookMarkedQuestions}
                    handleBookMark={handleBookMark}
                  />
                </div>
                :
                <div className=" min-h-[50%]">
                  <Review currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} questions={questions} setQuestions={setQuestions} />
                </div>
              }
            </div>

          </div>

          <div className="z-[300] fixed inset-x-0 bottom-[2px] flex flex-row justify-around border-t-2 border-black pt-4 bg-white">
            {questionOverViewOpen && (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  type: "spring",
                }}
                className="absolute z-[3000] rounded-md flex flex-row justify-center p-1 bg-white -top-40 border-[1px] border-gray-300"
              >
                <div className="bg-white min-h-[140px] max-h-[140px] min-w-[200px] max-w-[400px] flex flex-row flex-wrap rounded-md overflow-x-auto overflow-y-auto">
                  {questions.map((ques, idx) => (
                    <div
                      key={'questions-' + idx}
                      onClick={() => setCurrentQuestion(questions[idx])}
                      className={`${ques.bookMarked ? 'bg-red-500 border-none text-white' : ''} 
        m-1 rounded-full border-[1px] w-[25px] h-[25px] flex flex-row justify-center items-center cursor-pointer 
        ${currentQuestion?.q_num === ques?.q_num ? 'bg-black text-white' : ''}
        ${ques.selectedOption != null ? 'border-none bg-blue-700 text-white' : ''}
        border-black`}
                    >
                      {idx + 1}
                    </div>
                  ))}
                </div>
              </motion.div>

            )}

            <div className="flex flex-row items-center z-[999]">
              <p className="font-semibold text-lg">{free ? 'Free Trial' : `${user?.first_name ?? ''} ${user?.last_name ?? ''}`.trim() || 'John Doe'}</p>
            </div>
            {currentQuestion && (
              <motion.button
                whileTap={{ scale: 0.97 }}
                onClick={() => setQuestionOverviewOpen((pre) => !pre)}
                className="flex flex-row items-center gap-1 bg-black hover:bg-gray-800 text-white rounded-md p-2 cursor-pointer"
              >
                <div className="flex flex-row items-center">
                  <p className="text-sm md:text-l">
                    Question {currentQuestion?.index + 1} of {questions?.length}
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  {questionOverViewOpen ? <BsChevronDown /> : <BsChevronUp />}
                </div>
              </motion.button>
            )}
            <div className="flex flex-row items-center gap-2">
              <motion.button
                whileTap={{ scale: 0.97 }}
                className="py-2 px-5 bg-slate-700 rounded-3xl text-white hover:bg-slate-800"
                onClick={() => handleBack()}
              >
                Back
              </motion.button>
              {currentQuestion ? (
                <motion.button
                  whileTap={{ scale: 0.97 }}
                  className="py-2 px-5 bg-slate-700 rounded-3xl text-white hover:bg-slate-800"
                  onClick={() => handleNext()}
                >
                  Next
                </motion.button>
              ) : (
                <motion.button
                  whileTap={{ scale: 0.97 }}
                  className="py-2 px-5 bg-slate-700 rounded-3xl text-white hover:bg-slate-800"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </motion.button>
              )}
            </div>
          </div>

        </>
      }
    </div>
  );
};
export default ACTQuiz;