import React, { useState, useEffect, useContext, useCallback } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { CiCalculator1 } from "react-icons/ci";
import { VscReferences } from "react-icons/vsc";
import { BsThreeDotsVertical, BsExclamationTriangle } from "react-icons/bs";
import Question from "./MathQuestion";
import axios from "axios";
import Timer from "./Timer";
import { motion } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import { BASELINE } from "../../util";
import Review from "./Review";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DirectionModal from './DirectionModal';
import ReferenceSheet from './ReferenceSheet';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { useStopwatch } from "react-timer-hook";

const PresetSAT = ({ free, freesat }) => {
  const { id: initialId } = useParams();
  const [id, setId] = useState(initialId);

  useEffect(() => {
    if (freesat) {
      setId(freesat);
    }
  }, [freesat]);

  const [questionsarr, setquestionsarr] = useState([]);
  const [section, setsection] = useState(4);

  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [showCalculator, setShowCalculator] = useState(false);
  const [questionOverViewOpen, setQuestionOverviewOpen] = useState(false);
  const [showReference] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [bookmarkedQuestions, setBookMarkedQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [Error, setError] = useState('');
  const [showDirectionModal, setShowDirectionModal] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [showDirectionModalRef, setShowDirectionRefModal] = useState(false);
  const [sat, setsat] = useState("");
  const [answers, setanswers] = useState([]);
  const [breaks, setbreaks] = useState(true);

  const [test_id, settest_id] = useState(uuidv4());
  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [context, setContext] = useState('');

  const [timeSpentPerQuestion, setTimeSpentPerQuestion] = useState({});
  const [timer, setTimer] = useState(null);
  const [userResponses, setUserResponses] = useState({});
  const [loadcurr, setloadcurr] = useState(0);
  const [qanswers, setqanswers] = useState([]);
  const [qbookmarks, setqbookmarks] = useState([]);
  const [qarr, setqarr] = useState([]);
  const location = useLocation();
  const responseData = location.state?.data;
  const [auto, setauto] = useState(true);
  const [timeLeft, setTimeLeft] = useState(null); // Countdown timer
  const [timerActive, setTimerActive] = useState(false); // Track if countdown timer is active
  const { start, reset } = useStopwatch({ autoStart: false }); // Use stopwatch for untimed mode

  const displayMinutes = Math.floor(timeLeft / 60);
  const displaySeconds = timeLeft % 60;

  const satScoringTable = [
    { numcor: 1, Eng: [200, 200], math: [200, 200] },
    { numcor: 2, Eng: [200, 200], math: [200, 200] },
    { numcor: 3, Eng: [220, 220], math: [210, 210] },
    { numcor: 4, Eng: [240, 240], math: [220, 220] },
    { numcor: 5, Eng: [240, 260], math: [230, 230] },
    { numcor: 6, Eng: [260, 280], math: [240, 240] },
    { numcor: 7, Eng: [270, 290], math: [250, 250] },
    { numcor: 8, Eng: [280, 300], math: [260, 300] },
    { numcor: 9, Eng: [290, 300], math: [270, 310] },
    { numcor: 10, Eng: [300, 310], math: [280, 310] },
    { numcor: 11, Eng: [300, 340], math: [290, 330] },
    { numcor: 12, Eng: [310, 360], math: [300, 350] },
    { numcor: 13, Eng: [310, 380], math: [310, 360] },
    { numcor: 14, Eng: [320, 400], math: [320, 380] },
    { numcor: 15, Eng: [320, 400], math: [330, 400] },
    { numcor: 16, Eng: [330, 410], math: [340, 420] },
    { numcor: 17, Eng: [330, 420], math: [350, 430] },
    { numcor: 18, Eng: [340, 430], math: [360, 540] },
    { numcor: 19, Eng: [350, 440], math: [370, 550] },
    { numcor: 20, Eng: [360, 450], math: [380, 560] },
    { numcor: 21, Eng: [370, 460], math: [390, 570] },
    { numcor: 22, Eng: [380, 490], math: [400, 580] },
    { numcor: 23, Eng: [390, 500], math: [410, 590] },
    { numcor: 24, Eng: [400, 510], math: [420, 600] },
    { numcor: 25, Eng: [410, 520], math: [430, 610] },
    { numcor: 26, Eng: [420, 530], math: [440, 620] },
    { numcor: 27, Eng: [430, 540], math: [450, 630] },
    { numcor: 28, Eng: [440, 550], math: [460, 640] },
    { numcor: 29, Eng: [450, 560], math: [470, 650] },
    { numcor: 30, Eng: [460, 570], math: [480, 670] },
    { numcor: 31, Eng: [470, 580], math: [490, 680] },
    { numcor: 32, Eng: [480, 590], math: [500, 690] },
    { numcor: 33, Eng: [490, 600], math: [510, 700] },
    { numcor: 34, Eng: [500, 610], math: [600, 710] },
    { numcor: 35, Eng: [510, 620], math: [600, 720] },
    { numcor: 36, Eng: [520, 630], math: [600, 730] },
    { numcor: 37, Eng: [530, 640], math: [600, 740] },
    { numcor: 38, Eng: [540, 650], math: [600, 750] },
    { numcor: 39, Eng: [550, 660], math: [600, 760] },
    { numcor: 40, Eng: [560, 670], math: [600, 770] },
    { numcor: 41, Eng: [570, 680], math: [600, 780] },
    { numcor: 42, Eng: [580, 690], math: [600, 790] },
    { numcor: 43, Eng: [590, 700], math: [600, 790] },
    { numcor: 44, Eng: [600, 710], math: [600, 800] },
    { numcor: 45, Eng: [600, 720], math: [800, 800] },
    { numcor: 46, Eng: [600, 730], math: [800, 800] },
    { numcor: 47, Eng: [600, 740], math: [800, 800] },
    { numcor: 48, Eng: [600, 750], math: [800, 800] },
    { numcor: 49, Eng: [600, 760], math: [800, 800] },
    { numcor: 50, Eng: [600, 770], math: [800, 800] },
    { numcor: 51, Eng: [600, 780], math: [800, 800] },
    { numcor: 52, Eng: [600, 790], math: [800, 800] },
    { numcor: 53, Eng: [600, 790], math: [800, 800] },
    { numcor: 54, Eng: [600, 800], math: [800, 800] },
  ];

  const handleSubmit2 = () => {
    var type;
    if (id.startsWith('p89_')) {
      type = 'SAT89';
    } else if (id.startsWith('p10_')) {
      type = 'SAT10';
    } else {
      type = 'SAT';
    }
    const questionId = currentQuestion.questionIds;
    const reportFrom = user.first_name + " " + user.last_name;

    // Do something with questionId, reportFrom, and context

    var data = {
      questionId: questionId,
      name: reportFrom,
      context: context,
      type: type
    }

    axios.post(BASELINE + 'questions/report/note', [data])
      .then(response => {
        alert('Report submitted successfully!');
      })
      .catch(error => {
        console.error(error);
      });

    setContext('');
    setShowModal(false);
  };

  const handleContextChange = (event) => {
    setContext(event.target.value);
  };

  const handleReportClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = () => {
    // Clear the context and close the modal
    setContext('');
    setShowModal(false);
  };

  useEffect(() => {
    if (!free) { // Assuming `free` is a boolean that if true, should not run this effect.
      const localStorageData = localStorage.getItem('currentTest');
      if (localStorageData) {
        setauto(false);
        settest_id(localStorageData);

        axios.get(BASELINE + 'getautosavebytid', { params: { tid: localStorageData } })
          .then(async (response) => { // Make sure to mark this function as async
            const savedData = response.data.data[0];
            if (savedData) {
              try {
                const jsonsetting = JSON.parse(savedData.Setting);
                const jsondata = JSON.parse(savedData.test_data);
                setsection(jsonsetting.section);
                if (jsonsetting.breaks === true) {
                  setTimeLeft(jsonsetting.timeLeft);
                  setbreaks(true);
                  setLoadingQuestions(true);
                } else {
                  if (jsonsetting.section === 4) {
                    setsat("English");

                  } else if (jsonsetting.section === 31 || jsonsetting.section === 32) {
                    setsat("English");

                  } else if (jsonsetting.section === 2) {
                    setsat("Math");

                  } else {
                    setsat("Math");
                  }
                  try {
                    const res = await axios.get(BASELINE + "sat/preset", { params: { satNumber: id } });
                    const stages = {
                      4: "stage1",
                      31: "stage21",
                      32: "stage22",
                      2: "stage3",
                      11: "stage41",
                      12: "stage42"
                    };
                    const stageKey = stages[jsonsetting.section];
                    if (stageKey) {
                      setquestionsarr(res.data.preset[stageKey]);
                      setqarr(res.data.preset[stageKey]);
                    }
                  } catch (err) {
                    toast.error('Something went wrong. Please try again.');
                  }


                  setqanswers(jsondata.qanswers);
                  setqbookmarks(jsondata.qbookmarks);
                  setanswers(jsondata.answers);
                  setTimeLeft(jsonsetting.timeLeft);
                  setTimeSpentPerQuestion(jsonsetting.timeperq || {});

                  const currentQuestionIndex = jsondata.questions.findIndex(q => q === savedData.questionIds);
                  setloadcurr(currentQuestionIndex);

                  setTimeout(() => {
                    localStorage.removeItem('currentTest');
                    setauto(true);
                  }, 1000);
                }
              } catch (parseError) {
                console.error("Error parsing autosave data:", parseError);
                toast.error('Failed to load saved test data. Starting a new test.');
                localStorage.removeItem('currentTest');
              }
            }
          })
          .catch(error => {
            console.error("Error fetching autosave data:", error);
            toast.error('Failed to load saved test data. Please try again.');
          });
      }
    }
  }, []); // Ensure dependencies are correctly listed

  const autosaveData = async () => {
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');

    const qanswers = questions.map(question => question.selectedOption);
    const qbookmarks = questions.map(question => question.bookMarked);
    const qids = questions.map(question => question.questionIds);

    var name;
    if (id?.startsWith('p89_')) {
      name = "Digital SAT 8/9"
    }
    else if (id?.startsWith('p10_')) {
      name = "Digital NMSQT"
    }
    else {
      name = "Digital SAT " + id
    }

    const data = {
      id: id,
      user_id: user.u_id,
      section: section,
      questions: qids,
      questionIds: currentQuestion?.questionIds || null,
      timeLeft: timeLeft,
      answers: answers,
      qanswers: qanswers,
      qbookmarks: qbookmarks,
      test_id: test_id,
      date: formattedDate,
      timeperq: timeSpentPerQuestion,
      type: 'TEST',
      name: name,
      breaks: breaks
    };

    try {
      await axios.post(BASELINE + "autosave", { params: { data: JSON.stringify(data) } });
    } catch (err) {
      toast.error(err.message);
      setError(err.message);
    }
  };

  useEffect(() => {
    const localStorageData = localStorage.getItem('currentTest');
    if (!localStorageData) {
      if (free || !currentQuestion) {
        if (timer) {
          clearInterval(timer);
        }
        return;
      }

      const startTime = Date.now();

      const newTimer = setInterval(() => {
        const currentTime = Date.now();
        const elapsedTime = (currentTime - startTime) / 1000 + (timeSpentPerQuestion[currentQuestion.questionIds] || 0);
        setTimeSpentPerQuestion(prev => ({
          ...prev,
          [currentQuestion.questionIds]: elapsedTime
        }));
      }, 1000);

      setTimer(newTimer);

      if (!responseData) {
        autosaveData();
      }

      return () => {
        clearInterval(newTimer);
      };
    }
  }, [currentQuestion, user, section, questions, answers, id, test_id, timeSpentPerQuestion, breaks]);

  const fetchMathData = async () => {
    setbreaks(false);
    setLoadingQuestions(true);
    setquestionsarr([]);

    if (section === 4) {
      setTimeLeft(1925); //1925
      setsat("English");

    } else if (section === 31 || section === 32) {
      setTimeLeft(1925); //1925
      setsat("English");

    } else if (section === 2) {
      setTimeLeft(2100); //2100
      setsat("Math");

    } else {
      setTimeLeft(2100); //2100
      setsat("Math");
    }

    if (free !== true) {
      if (responseData) {
        if (section === 4) {
          setquestionsarr(responseData.result.stage1)
        }
        else if (section === 31) {
          setquestionsarr(responseData.result.stage21)
        }
        else if (section === 32) {
          setquestionsarr(responseData.result.stage22)
        }
        else if (section === 2) {
          setquestionsarr(responseData.result.stage3)
        }
        else if (section === 11) {
          setquestionsarr(responseData.result.stage41)
        }
        else if (section === 12) {
          setquestionsarr(responseData.result.stage42)
        }
      }
      else {
        try {
          let res = await axios.get(BASELINE + "sat/preset", { params: { satNumber: id } });
          if (section === 4) {
            setquestionsarr(res.data.preset["stage1"])
          }
          else if (section === 31) {
            setquestionsarr(res.data.preset["stage21"])
          }
          else if (section === 32) {
            setquestionsarr(res.data.preset["stage22"])
          }
          else if (section === 2) {
            setquestionsarr(res.data.preset["stage3"])
          }
          else if (section === 11) {
            setquestionsarr(res.data.preset["stage41"])
          }
          else if (section === 12) {
            setquestionsarr(res.data.preset["stage42"])
          }
        } catch (err) {
          toast.error('Something went wrong. Please try again.');
        }
      }
    } else {
      try {
        let res = await axios.get(BASELINE + "sat/preset", { params: { satNumber: 1 } });
        if (section === 4) {
          setquestionsarr(res.data.preset["stage1"])
        }
        else if (section === 31) {
          setquestionsarr(res.data.preset["stage21"])
        }
        else if (section === 32) {
          setquestionsarr(res.data.preset["stage22"])
        }
        else if (section === 2) {
          setquestionsarr(res.data.preset["stage3"])
        }
        else if (section === 11) {
          setquestionsarr(res.data.preset["stage41"])
        }
        else if (section === 12) {
          setquestionsarr(res.data.preset["stage42"])
        }
      } catch (err) {
        toast.error('Something went wrong. Please try again.');
      }
    }
  };

  useEffect(() => {
    if (auto === true) {
      if (breaks === false && (section === 2)) {
        setTimeLeft(600);
        setLoadingQuestions(true);
        setbreaks(true);
      } else {
        fetchMathData();
      }
    }

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [section]);

  useEffect(() => {
    if (timeLeft === 0) {
      if (breaks === false) {
        alert("Time's up!");
        handleSubmit();
      } else {
        alert("Time's up! Resume the test.");
        setbreaks(true);
        setLoadingQuestions(false);
        fetchMathData();
      }
    }

    if (timeLeft === -1) {
      alert("Resume the test.");
      setbreaks(true);
      setLoadingQuestions(false);
      fetchMathData();
    }
  }, [timeLeft]);

  useEffect(() => {
    if (questionsarr.length > 0 && auto === true) {
      getQuestions();
    }
  }, [questionsarr, auto]);

  useEffect(() => {
    var elt = document.getElementById('calculator');
    var calculator = window.Desmos?.GraphingCalculator(elt, {
      graphpaper: true,
      keypad: true
    });
    return () => {
      calculator?.destroy();
    }
  }, [showCalculator]);

  useEffect(() => {
    if (questions.length > 0 && currentQuestion) {
      let q = questions.find((ques => ques?.questionIds === currentQuestion?.questionIds));
      setCurrentQuestion(q);
    }
  }, [questions]);

  async function getQuestions() {
    var know_sec;
    if (section === 31 || section === 32 || section === 4) {
      know_sec = "Eng"
    }
    else if (section === 11 || section === 12 || section === 2) {
      know_sec = "Math"
    }

    if (qarr.length > 0) { setquestionsarr(qarr); setqarr([]) }

    try {
      setLoadingQuestions(true);
      let res;

      if (id.startsWith('p89_')) {
        res = await axios.get(BASELINE + "questions/sat89", { params: { id: questionsarr, section: know_sec } });
      }
      else if (id.startsWith('p10_')) {
        res = await axios.get(BASELINE + "questions/sat10", { params: { id: questionsarr, section: know_sec } });
      }
      else {
        res = await axios.get(BASELINE + "questions/sat", { params: { id: questionsarr, section: know_sec } });
      }
      let arr = [res.data]
      let mappedQuestions = arr[0].map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });


      if (qanswers.length > 0) {
        mappedQuestions = mappedQuestions.map((q, idx) => ({
          ...q,
          selectedOption: qanswers[idx] || null,
          bookMarked: qbookmarks[idx] || false,
        }));
        setqanswers([]);
      }
      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[loadcurr]);
      setLoadingQuestions(false);
    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  async function handleSubmit() {
    if (section === 4) {
      let obtainedMarks = 0;
      let newDat = questions.map((question) => {
        let correctAnswer = question.correct_answer[2].toLowerCase();

        if (question.selectedOption === correctAnswer) {
          obtainedMarks++;

          question.correct = true;
        } else {
          question.correct = false;
        }

        const bookMarked = question.bookMarked;

        if (id.startsWith('p10_')) {
          return {
            section: "1Hard",
            correct: question.correct,
            selectedOption: question.selectedOption,
            correct_answer: correctAnswer,
            questionIds: question.questionIds,
            tag: 'eng',
            topic: question.skill_descs,
            type: 'SAT10',
            bookmark: bookMarked,
            difficultys: question.difficultys
          }
        } else if (id.startsWith('p89_')) {
          return {
            section: "1Hard",
            correct: question.correct,
            selectedOption: question.selectedOption,
            correct_answer: correctAnswer,
            questionIds: question.questionIds,
            tag: 'eng',
            topic: question.skill_descs,
            type: 'SAT89',
            bookmark: bookMarked,
            difficultys: question.difficultys
          }
        } else {
          return {
            section: "1Hard",
            correct: question.correct,
            selectedOption: question.selectedOption,
            correct_answer: correctAnswer,
            questionIds: question.questionIds,
            tag: 'eng',
            topic: question.skill_descs,
            type: 'SAT',
            bookmark: bookMarked,
            difficultys: question.difficultys
          }
        }

      });
      setanswers(newDat);
      setbreaks(false);

      if (obtainedMarks >= 17) {
        setsection(32) //harder
      } else {
        setsection(31) //easy
      }

      setloadcurr(0);
      return;
    }
    if (section === 31 || section === 32) {
      let obtainedMarks = 0;
      let newDat = questions.map((question) => {
        let correctAnswer = question.correct_answer[2].toLowerCase();
        if (question.selectedOption === correctAnswer) {
          obtainedMarks++;

          question.correct = true;
        } else {
          question.correct = false;
        }
        let sectionName = section === 31 ? '2Easy' : '2Hard';
        const bookMarked = question.bookMarked;
        if (id.startsWith('p10_')) {
          return {
            section: sectionName,
            correct: question.correct,
            selectedOption: question.selectedOption,
            correct_answer: correctAnswer,
            questionIds: question.questionIds,
            tag: 'eng',
            topic: question.skill_descs,
            type: 'SAT10',
            bookmark: bookMarked,
            difficultys: question.difficultys
          }
        } else if (id.startsWith('p89_')) {
          return {
            section: sectionName,
            correct: question.correct,
            selectedOption: question.selectedOption,
            correct_answer: correctAnswer,
            questionIds: question.questionIds,
            tag: 'eng',
            topic: question.skill_descs,
            type: 'SAT89',
            bookmark: bookMarked,
            difficultys: question.difficultys
          }
        } else {
          return {
            section: sectionName,
            correct: question.correct,
            selectedOption: question.selectedOption,
            correct_answer: correctAnswer,
            questionIds: question.questionIds,
            tag: 'eng',
            topic: question.skill_descs,
            type: 'SAT',
            bookmark: bookMarked,
            difficultys: question.difficultys
          }
        }
      });

      setanswers(prevAnswers => ([
        ...prevAnswers,
        ...newDat
      ]));

      setbreaks(false);

      setsection(2);
      setloadcurr(0);
      return;
    }
    if (section === 2) {
      let obtainedMarks = 0;
      let newDat = questions.map((question) => {
        var correctAnswer;

        if (question.correct_answer) {
          // Parse the string into a JSON array
          correctAnswer = JSON.parse(question.correct_answer);

          // If there's only one element, convert it to lowercase
          if (correctAnswer.length === 1) {
            correctAnswer = [correctAnswer[0].toLowerCase()];
          }
        } else {
          correctAnswer = ["No Answer"];
        }

        let isCorrect = false;
        if (Array.isArray(correctAnswer)) {
          for (let i = 0; i < correctAnswer.length; i++) {
            if (String(question.selectedOption).trim() === String(correctAnswer[i]).trim()) {
              isCorrect = true;
              break;
            }
          }
        } else {
          isCorrect = question.selectedOption === correctAnswer;
        }

        if (isCorrect) {
          obtainedMarks++;
          question.correct = true;
        } else {
          question.correct = false;
        }
        const bookMarked = question.bookMarked;
        if (id.startsWith('p10_')) {
          return {
            section: "3Hard",
            correct: question.correct,
            selectedOption: question.selectedOption,
            correct_answer: question.correct_answer,
            questionIds: question.questionIds,
            tag: 'math',
            topic: question.skill_descs,
            type: 'SAT10',
            bookMarked: bookMarked,
            difficultys: question.difficultys
          }
        } else if (id.startsWith('p89_')) {
          return {
            section: "3Hard",
            correct: question.correct,
            selectedOption: question.selectedOption,
            correct_answer: question.correct_answer,
            questionIds: question.questionIds,
            tag: 'math',
            topic: question.skill_descs,
            type: 'SAT89',
            bookMarked: bookMarked,
            difficultys: question.difficultys
          }
        } else {
          return {
            section: "3Hard",
            correct: question.correct,
            selectedOption: question.selectedOption,
            correct_answer: question.correct_answer,
            questionIds: question.questionIds,
            tag: 'math',
            topic: question.skill_descs,
            type: 'SAT',
            bookMarked: bookMarked,
            difficultys: question.difficultys
          }
        }
      });

      setanswers(prevAnswers => ([
        ...prevAnswers,
        ...newDat
      ]));

      setbreaks(false);

      if (obtainedMarks >= 12) {
        setsection(12) //harder
      } else {
        setsection(11) //easy
      }
      setloadcurr(0);
      return;
    }


    try {
      let obtainedMarks = 0;
      let newDat = questions.map((question) => {
        var correctAnswer;

        if (question.correct_answer) {
          // Parse the string into a JSON array
          correctAnswer = JSON.parse(question.correct_answer);

          // If there's only one element, convert it to lowercase
          if (correctAnswer.length === 1) {
            correctAnswer = [correctAnswer[0].toLowerCase()];
          }
        } else {
          correctAnswer = ["No Answer"];
        }

        let isCorrect = false;
        if (Array.isArray(correctAnswer)) {
          for (let i = 0; i < correctAnswer.length; i++) {

            //  question.selectedOption = getRandomChoice(); //this remove

            if (String(question.selectedOption).trim() === String(correctAnswer[i]).trim()) {
              isCorrect = true;
              break;
            }
          }
        } else {
          isCorrect = question.selectedOption === correctAnswer;
        }

        if (isCorrect) {
          obtainedMarks++;
          question.correct = true;
        } else {
          question.correct = false;
        }

        let sectionName = section === 11 ? '4Easy' : '4Hard';
        const bookMarked = question.bookMarked; // Get a random true or false

        if (id.startsWith('p10_')) {
          return {
            section: sectionName,
            correct: question.correct,
            selectedOption: question.selectedOption,
            correct_answer: question.correct_answer,
            questionIds: question.questionIds,
            tag: 'math',
            topic: question.skill_descs,
            type: 'SAT10',
            bookmark: bookMarked,
            difficultys: question.difficultys
          }
        } else if (id.startsWith('p89_')) {
          return {
            section: sectionName,
            correct: question.correct,
            selectedOption: question.selectedOption,
            correct_answer: question.correct_answer,
            questionIds: question.questionIds,
            tag: 'math',
            topic: question.skill_descs,
            type: 'SAT89',
            bookmark: bookMarked,
            difficultys: question.difficultys
          }
        } else {
          return {
            section: sectionName,
            correct: question.correct,
            selectedOption: question.selectedOption,
            correct_answer: question.correct_answer,
            questionIds: question.questionIds,
            tag: 'math',
            topic: question.skill_descs,
            type: 'SAT',
            bookmark: bookMarked,
            difficultys: question.difficultys
          }
        }
      });

      let updatedAnswers = [
        ...answers,
        ...newDat
      ];

      setanswers(updatedAnswers);
      const first54Answers = updatedAnswers.slice(0, 54);
      const lastElement = first54Answers[first54Answers.length - 1];

      let engscore = null;
      if (lastElement) {
        const section = lastElement.section;
        const correctCount = first54Answers.filter(answer => answer.correct).length;
        const scoreItem = satScoringTable[correctCount];
        engscore = section === '2Easy' ? scoreItem.Eng[0] : scoreItem.Eng[1];
      }

      const restOfAnswers = updatedAnswers.slice(54);
      const lastElement2 = restOfAnswers[restOfAnswers.length - 1];
      let mathscore = null;
      if (lastElement2) {
        const section = lastElement2.section;
        const correctCount = restOfAnswers.filter(answer => answer.correct).length;
        const scoreItem = satScoringTable[correctCount];
        mathscore = section === '1Easy' ? scoreItem.math[0] : scoreItem.math[1];
      }

      // Assuming SAT_scores is populated appropriately:
      const correctQuestions = updatedAnswers.filter(question => question.correct === true);
      const incorrectQuestions = updatedAnswers.filter(question => question.correct === false);
      const bookmarkedQuestions = updatedAnswers.filter(question => question.bookmark === true); // Assuming 'bookMarked' exists
      const nullCorrectAnswerQuestions = updatedAnswers.filter(question => question.selectedOption === null);

      let data;

      if (!responseData) {
        if (user) {
          if (id.startsWith('p10_')) {
            data = {
              SAT_scores: updatedAnswers,
              userId: user.u_id,
              username: user.first_name + " " + user.last_name,
              test_name: "SAT10 " + id,
              test_id: test_id,
              eng_score: engscore,
              math_score: mathscore,
              usertime: timeSpentPerQuestion,
              opthistory: userResponses,
              type: 'SAT10',
              correctQuestions: correctQuestions,
              incorrectQuestions: incorrectQuestions,
              bookmarkedQuestions: bookmarkedQuestions,
              nullCorrectAnswerQuestions: nullCorrectAnswerQuestions
            }
          } else if (id.startsWith('p89_')) {
            data = {
              SAT_scores: updatedAnswers,
              userId: user.u_id,
              username: user.first_name + " " + user.last_name,
              test_name: "SAT89 " + id,
              test_id: test_id,
              eng_score: engscore,
              math_score: mathscore,
              usertime: timeSpentPerQuestion,
              opthistory: userResponses,
              type: 'SAT89',
              correctQuestions: correctQuestions,
              incorrectQuestions: incorrectQuestions,
              bookmarkedQuestions: bookmarkedQuestions,
              nullCorrectAnswerQuestions: nullCorrectAnswerQuestions
            }
          } else {
            data = {
              SAT_scores: updatedAnswers,
              userId: user.u_id,
              username: user.first_name + " " + user.last_name,
              test_name: "SAT " + id,
              test_id: test_id,
              eng_score: engscore,
              math_score: mathscore,
              usertime: timeSpentPerQuestion,
              opthistory: userResponses,
              type: 'SAT',
              correctQuestions: correctQuestions,
              incorrectQuestions: incorrectQuestions,
              bookmarkedQuestions: bookmarkedQuestions,
              nullCorrectAnswerQuestions: nullCorrectAnswerQuestions
            }
          }
        } else {
          data = {
            SAT_scores: updatedAnswers,
            userId: "Free User",
            username: "Free User",
            test_name: "Free SAT",
            test_id: test_id,
            eng_score: engscore,
            math_score: mathscore,
            usertime: timeSpentPerQuestion,
            opthistory: userResponses,
            type: 'SAT',
            correctQuestions: correctQuestions,
            incorrectQuestions: incorrectQuestions,
            bookmarkedQuestions: bookmarkedQuestions,
            nullCorrectAnswerQuestions: nullCorrectAnswerQuestions
          }
        }
      } else {
        if (id.startsWith('p10_')) {
          data = {
            SAT_scores: updatedAnswers,
            userId: user.u_id,
            username: user.first_name + " " + user.last_name,
            test_name: "CustomRandom_SAT10",
            test_id: test_id,
            eng_score: engscore,
            math_score: mathscore,
            usertime: timeSpentPerQuestion,
            opthistory: userResponses,
            type: 'SAT10',
            correctQuestions: correctQuestions,
            incorrectQuestions: incorrectQuestions,
            bookmarkedQuestions: bookmarkedQuestions,
            nullCorrectAnswerQuestions: nullCorrectAnswerQuestions
          }
        } else if (id.startsWith('p89_')) {
          data = {
            SAT_scores: updatedAnswers,
            userId: user.u_id,
            username: user.first_name + " " + user.last_name,
            test_name: "CustomRandom_SAT89",
            test_id: test_id,
            eng_score: engscore,
            math_score: mathscore,
            usertime: timeSpentPerQuestion,
            opthistory: userResponses,
            type: 'SAT89',
            correctQuestions: correctQuestions,
            incorrectQuestions: incorrectQuestions,
            bookmarkedQuestions: bookmarkedQuestions,
            nullCorrectAnswerQuestions: nullCorrectAnswerQuestions
          }
        }
        else {
          data = {
            SAT_scores: updatedAnswers,
            userId: user.u_id,
            username: user.first_name + " " + user.last_name,
            test_name: "CustomRandom_SAT",
            test_id: test_id,
            eng_score: engscore,
            math_score: mathscore,
            usertime: timeSpentPerQuestion,
            opthistory: userResponses,
            type: 'SAT',
            correctQuestions: correctQuestions,
            incorrectQuestions: incorrectQuestions,
            bookmarkedQuestions: bookmarkedQuestions,
            nullCorrectAnswerQuestions: nullCorrectAnswerQuestions
          }
        }
      }

      try {
        let saveResult = await axios.post(BASELINE + "tests", data);
        navigate(`/results/presat/${saveResult.data.testId}`);
      } catch (err) {
        console.error("Error saving test results:", err);
        // Handle error (e.g., show a notification)
      }


    } catch (err) {
      toast.error(err.message);
      setError(err.message);
    }
  }

  const handleBookMark = (itemId) => {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.questionIds === itemId) {
          if (question.bookMarked) {
            return { ...question, bookMarked: false };
          } else {
            return { ...question, bookMarked: true };
          }
        } else {
          return question;
        }
      })
    }))
  }

  function updateQuestionsAfterInteraction(itemId, selectedOption) {
    setQuestions(prev => {
      const updatedQuestions = prev.map(question => {
        if (question.questionIds === itemId) {
          return {
            ...question,
            selectedOption: selectedOption ? selectedOption : question.selectedOption,
          };
        }
        return question;
      });
      const updatedQuestion = updatedQuestions.find(question => question.questionIds === itemId);
      setCurrentQuestion(updatedQuestion);
      return updatedQuestions;
    });
  }

  async function handleSelectOption(itemId, option) {
    setUserResponses(prev => {
      const currentActions = prev[itemId] ? [...prev[itemId].actions] : [];
      currentActions.push({ action: "mark", option: option });

      return {
        ...prev,
        [itemId]: {
          ...prev[itemId],
          actions: currentActions,
        }
      };
    });

    setQuestions((pre) => {
      const updatedQuestions = pre.map((question) => {
        if (question?.questionIds === itemId) {
          return { ...question, selectedOption: option };
        } else {
          return question;
        }
      });

      // Find the updated question
      const updatedQuestion = updatedQuestions.find((question) => question.questionIds === itemId);

      // Update the currentQuestion state
      setCurrentQuestion(updatedQuestion);
      updateQuestionsAfterInteraction(itemId, option);

      return updatedQuestions;
    });
  }

  async function handleCrossOutOptions(itemId, option) {
    setUserResponses(prev => {
      const currentActions = prev[itemId] ? [...prev[itemId].actions] : [];
      if (prev[itemId] && prev[itemId].actions.some(act => act.action === "crossout" && act.option === option)) {
        // Remove the crossout action if it exists
        return {
          ...prev,
          [itemId]: {
            ...prev[itemId],
            actions: currentActions.filter(act => !(act.action === "crossout" && act.option === option)),
          }
        };
      } else {
        // Add a new crossout action
        currentActions.push({ action: "crossout", option: option });
        return {
          ...prev,
          [itemId]: {
            ...prev[itemId],
            actions: currentActions,
          }
        };
      }
    });
    updateQuestionsAfterInteraction(itemId, null);  // No selection change, just update for crossout.

    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.questionIds === itemId) {
          let crossedOutOptions = question.crossedOutOptions;
          if (crossedOutOptions.includes(option)) {
            crossedOutOptions = crossedOutOptions.filter((el) => el !== option);
          } else {
            crossedOutOptions.push(option);
          }
          return { ...question, crossedOutOptions: crossedOutOptions };
        } else {
          return question;
        }
      })
    }))
  }

  const handleAnswerValueChange = (itemId, value) => {
    setQuestions(prevQuestions =>
      prevQuestions.map(question =>
        question.questionIds === itemId ? { ...question, selectedOption: value } : question
      )
    );
  };

  async function handleNext() {
    if (currentQuestion.index < questions[questions.length - 1].index) {
      setCurrentQuestion((pre) => questions[pre.index + 1])
    } else {
      setCurrentQuestion(null);
      setQuestionOverviewOpen(false);
    }
  }

  async function handleBack() {
    if (!currentQuestion) {
      setCurrentQuestion(questions[questions.length - 1]);
      return;
    }
    if (currentQuestion.index > 0) {
      setCurrentQuestion((pre) => questions[pre.index - 1])
    } else {

    }
  }

  const closeRefModal = () => {
    setShowDirectionRefModal(false);
  };

  const closeModal = () => {
    setShowDirectionModal(false);
  };

  const getSectionDisplay = (section) => {
    switch (section) {
      case 4:
        return 1;
      case 31:
      case 32:
        return 2;
      case 2:
        return 3;
      case 11:
      case 12:
        return 4;
      default:
        return section;
    }
  };

  return (
    <div className="min-h-screen bg-white p-1 relative">
      {loadingQuestions ?
        <div className="flex flex-col justify-center items-center py-48">
          <div className="border-t-transparent border-solid animate-spin rounded-full border-blue-400 border-8 h-52 w-52"></div>
          <div className="absolute mt-[-50px]">
            {breaks === true ? <Timer
              minutes={displayMinutes.toString().padStart(2, '0')}
              seconds={displaySeconds.toString().padStart(2, '0')} /> : null}
          </div>
          {breaks === true ?
            <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800 mt-5" onClick={() => { setTimeLeft(-1); }}>
              Resume the test
            </motion.button>
            : null}
        </div>
        :
        <>
          <div className="flex flex-row justify-between">

            <div className="flex flex-row justify-between items-center w-[100%] mt-[20px] pb-[10px] border-b border-gray-200">
              <div className="ml-[20px] flex flex-col">
                <h3>
                  <strong>
                    {section === 4 || section === 31 || section === 32 ? "Section: English" : "Section: Math"}
                  </strong>
                </h3>
                <motion.div whileTap={{ scale: 0.97 }} className="left-0 flex flex-row gap-1 hover:text-blue-900">
                  <div>
                    <button onClick={() => setShowDirectionModal((pre) => !pre)}>Directions</button>
                  </div>
                  <div onClick={() => setShowDirectionModal(true)} className="flex flex-row items-center cursor-pointer">
                    <BsChevronDown />
                  </div>
                </motion.div>
                {showDirectionModal && <DirectionModal onClose={closeModal} mode={sat} />}
              </div>

              <div className="flex flex-col items-center justify-center flex-grow">
                <div className="flex flex-col sm:flex-row items-center text-center sm:text-left">
                  <Timer
                    minutes={displayMinutes.toString().padStart(2, '0')}
                    seconds={displaySeconds.toString().padStart(2, '0')}
                  />
                  <span className="mx-2 hidden sm:block">/</span>
                  <span>Section {getSectionDisplay(section)}</span>
                </div>
              </div>


              {sat === "Math" && (<>
                <motion.button whileTap={{ scale: 0.97 }} onClick={() => setShowCalculator(pre => !pre)} className={`mr-[4px] flex flex-col items-center cursor-pointer hover:text-blue-900 ${showCalculator ? "text-blue-900" : "text-black"}`}>
                  <div className={`flex flex-row justify-center`}>
                    <CiCalculator1 size="1.5em" />
                  </div>
                  <div>
                    <p className="text-sm">Calculator</p>
                  </div>
                </motion.button>
                <motion.button whileTap={{ scale: 0.97 }} className={`mr-[4px] flex flex-col items-center cursor-pointer hover:text-blue-900 ${showReference === true ? 'text-blue-900' : "text-black"} `} onClick={() => setShowDirectionRefModal(!showDirectionModalRef)}>
                  <div className="flex flex-row justify-center">
                    <VscReferences size="1.5em" />
                  </div>
                  <div>
                    <p className="text-sm">Reference</p>
                  </div>
                </motion.button>
                {showDirectionModalRef && <ReferenceSheet onClose={closeRefModal} />}
              </>)}

              <div className="ml-[5px] flex flex-col items-center mr-[20px]">
                {!free && (
                  <>
                    <div className="flex flex-col items-center" onClick={() => setShowOptions(!showOptions)}>
                      <BsThreeDotsVertical size="1.5em" />
                      <p className="text-sm mt-1 text-center">More</p>
                    </div>

                    {showOptions && (
                      <div className="fixed mt-[70px] mr-[10px] flex flex-row justify-center cursor-pointer hover:text-red-900 z-[100]" onClick={handleReportClick}>
                        <BsExclamationTriangle size="1.5em" />
                        <p className="text-sm ml-1">Report</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>


            <div className="flex flex-row gap-2">

              <div className="flex flex-col cursor-pointer">
                {showModal && (
                  <div className="fixed z-[20] inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                      </div>
                      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                          <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                Report Card
                              </h3>
                              <div className="mt-2">
                                <form>
                                  <label>Question ID:</label>
                                  <input type="text" className="border p-1 mt-1 w-full" value={currentQuestion.questionIds} readOnly />
                                  <label>Report from:</label>
                                  <input type="text" className="border p-1 mt-1 w-full" value={user?.first_name + " " + user?.last_name} readOnly />
                                  <label>Context:</label>
                                  <textarea
                                    className="border p-1 mt-1 w-full"
                                    value={context}
                                    onChange={handleContextChange}
                                  />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={handleSubmit2}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={handleCloseModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>

          <div className="h-[90%]">
            <motion.div drag style={{
              width: '600px',
              height: '400px',
              position: 'absolute',
              zIndex: 100,
              display: showCalculator ? "inline" : "none",
            }}
              dragConstraints={{
                top: -125,
                right: 500,
                bottom: 300,
                left: -125,
              }}
            >
              <div id="calculator" className="w-full h-full">

              </div>

            </motion.div>
            {/* question */}
            <div className="flex flex-col">

              {currentQuestion ?
                <div className="flex flex-col sm:flex-row justify-center h-[50%]">
                  <Question
                    sat={sat}
                    handleAnswerValueChange={handleAnswerValueChange}
                    handleCrossOutOptions={handleCrossOutOptions}
                    handleSelectOption={handleSelectOption}
                    currentQuestion={currentQuestion}
                    idx={1}
                    bookmarkedQuestions={bookmarkedQuestions}
                    setBookMarkedQuestions={setBookMarkedQuestions}
                    handleBookMark={handleBookMark}
                  />
                </div>
                :
                <div className=" min-h-[50%]">
                  <Review currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} questions={questions} setQuestions={setQuestions} />
                </div>
              }
            </div>

          </div>

          <div className="z-[300] fixed inset-x-0 bottom-[10px] flex flex-row justify-around border-t-2 border-black pt-4 bg-white">
            {questionOverViewOpen && (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  type: "spring",
                }}
                className="absolute z-[3000] rounded-md flex flex-row justify-center p-1 bg-white -top-40 border-[1px] border-gray-300"
              >
                <div className="bg-white min-h-[140px] max-h-[140px] min-w-[200px] max-w-[400px] flex flex-row flex-wrap rounded-md">
                  {questions.map((ques, idx) => (
                    <div
                      key={'questions-' + idx}
                      onClick={() => setCurrentQuestion(questions[idx])}
                      className={`${ques.bookMarked && 'bg-red-500 border-none text-white'} 
                      m-1 rounded-full border-[1px] w-[25px] h-[25px] flex flex-row justify-center items-center cursor-pointer 
                      ${currentQuestion?.questionIds === ques?.questionIds && 'bg-black text-white'}
                      ${ques.selectedOption != null && 'border-none bg-blue-700 text-white'}
                      border-black`}
                    >
                      {idx + 1}
                    </div>
                  ))}
                </div>
              </motion.div>
            )}

            <div className="flex flex-row items-center z-[999]">
              <p className="font-semibold text-lg">{free ? 'Free Trial' : `${user?.first_name ?? ''} ${user?.last_name ?? ''}`.trim() || 'John Doe'}</p>
            </div>
            {currentQuestion && (
              <motion.button
                whileTap={{ scale: 0.97 }}
                onClick={() => setQuestionOverviewOpen((pre) => !pre)}
                className="flex flex-row items-center gap-1 bg-black hover:bg-gray-800 text-white rounded-md p-2 cursor-pointer"
              >
                <div className="flex flex-row items-center">
                  <p className="text-sm md:text-l">
                    Question {currentQuestion?.index + 1} of {questions?.length}
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  {questionOverViewOpen ? <BsChevronDown /> : <BsChevronUp />}
                </div>
              </motion.button>
            )}
            <div className="flex flex-row items-center gap-2">
              <motion.button
                whileTap={{ scale: 0.97 }}
                className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                onClick={() => handleBack()}
              >
                Back
              </motion.button>
              {currentQuestion ? (
                <motion.button
                  whileTap={{ scale: 0.97 }}
                  className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                  onClick={() => handleNext()}
                >
                  Next
                </motion.button>
              ) : (
                <motion.button
                  whileTap={{ scale: 0.97 }}
                  className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </motion.button>
              )}
            </div>
          </div>

        </>
      }
    </div>
  );
};
export default PresetSAT;