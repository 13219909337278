import { useState, useEffect, useContext, useRef } from "react";
import { useStopwatch } from "react-timer-hook";
import DevQuestion from "./MathQuestion";
import axios from "axios";
import { motion } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import { useLocation } from "react-router-dom";
import { BASELINE } from "../../util";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from 'react-select'
import NavBar from "./navbar";

const topics = {
  "Algebra": ["Simple Algebra", "Literal Equation", "Complex Fraction"],
  "Exponent & Radicals": ["Simplifying", "Solving", "Advanced"],
  "Linear Equation": ["Evaluating", "Intercepts", "Graphing", "Slopes", "Parallel / Perpendicular", "Interpreting Equation"],
  "Quadratic Equation": ["Evaluating", "Key Points", "Equivalent Expressions", "Solving", "Graphs"],
  "Functions": ["Evaluating", "Key Points", "Transformation", "Features"],
  "Unit Conversion, Rates and Proportion": ["Unit Conversion", "Variable Rates", "Ratios", "Proportions"],
  "Percents": ["Basics", "Percent Change", "Tables", "Expressions"],
  "Probability": ["Simple", "Tables", "Compound", "Conditional"],
  "Systems of Equation": ["Evaluating", "Graphing", "Interpreting Equations", "Interest Problems"],
  "Inequalities & Absolutes": ["Linear Inequalities", "Non-linear", "Systems", "Solve Absolute", "Graph Absolute"],
  "Exponential Equation": ["Basics", "Transformation of Exponential functions", "Constructing exponential equations"],
  "Polynomials & Rationals": ["Polynomial Division and the Remainder Theorem", "Simplifying Rational Expressions", "Graphing Polynomial and Rational Functions"],
  "Geometry": ["Angle", "Triangle", "Circle", "Area", "Volume", "Ratios and Similar Figures"],
  "Trigonometry": ["Special Right Triangle", "Problem Solving with Trigonometry", "Co-function", "Radians & Unit Circle"],
  "Working with Data": ["Statistics", "Reading Tables", "Reading Graphs", "Inference", "Observational studies and experiments"],
  "Word Problems": ["Word Translations", "Classic Word Problems", "Advanced/Combined mixed-topic problems"],
};


const DevQuiz89 = () => {
  const {
    seconds,
    minutes,
    start
  } = useStopwatch();

  const boxItemLeftRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [questionOverViewOpen, setQuestionOverviewOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [bookmarkedQuestions, setBookMarkedQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [error, setError] = useState('');
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [status, setStatus] = useState(null);
  const [tnum, setTnum] = useState(null);
  const [q_id, setQ_id] = useState(null);
  const [note, setNote] = useState("");
  const [ans, setans] = useState(null);
  const [raion, setRaion] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [selectopic, settopic] = useState('');
  const [selectskills, setskills] = useState([]);
  const [format, setformat] = useState(null);

  const [state, setState] = useState(true);
  const [sat, setsat] = useState(true);

  const [devmode, setdevmode] = useState(false);

  useEffect(() => {
    const script2 = document.createElement('script');
    script2.src = "https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js";
    script2.integrity = "sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05";
    script2.crossOrigin = "anonymous";
    script2.defer = true;

    script2.onload = () => {
      if (boxItemLeftRef.current) {
        if (window.renderMathInElement) {
          window.renderMathInElement(boxItemLeftRef.current, {
            delimiters: [
              { left: '\\(', right: '\\)', display: false },
              { left: '\\[', right: '\\]', display: true },
              { left: '$$', right: '$$', display: false },
              { left: "$", right: "$", display: false },
            ],
            throwOnError: false,
          });
        }
      }
    };
    document.body.appendChild(script2);

    // Clean up: remove the script when the component unmounts
    return () => {
      document.body.removeChild(script2);
    };
  }, [currentQuestion]); // Trigger this effect whenever the currentQuestion changes

  const handleBookMark = (itemId) => {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          if (question.bookMarked) {
            return { ...question, bookMarked: false };
          } else {
            return { ...question, bookMarked: true };
          }
        } else {
          return question;
        }
      })
    }))
  }

  async function getAllNotes(q_id) {
    try {
      let res = await axios.get(BASELINE + "questions/notes", {
        params: {
          q_id: q_id
        }
      });

      if (res.data.length < 1) {
        setStatus("Pass");
        return;
      } else {
        setStatus("Fail");
        setNote(res.data[0]);
      }
    } catch (err) {
      //  console.log(err);
    }
  }

  async function getAllQuestions() {
    try {
      let res = await axios.get(BASELINE + "questions/psat89all");
      setTnum(res.data);
    } catch (err) {
      //  console.log(err);
    }
  }

  useEffect(() => {
    getAllQuestions()
  }, [])

  useEffect(() => {
    if (isNaN(id)) {
      setState(true);
      setStatus("Pass");
      setNote("");
      setans(null);
      setSelectedTopic("");
      setSelectedSkills([]);
      settopic("");
      setskills([]);
      getQuestionsbyid();
      setformat(null);
      setdevmode(true);
    } else {
      setState(true);
      setStatus("Pass");
      setNote("");
      setans(null);
      setSelectedTopic("");
      setSelectedSkills([]);
      settopic("");
      setskills([]);
      getQuestions();
      setformat(null);
      setdevmode(false);
    }
  }, [id])

  useEffect(() => {
    if (questions.length > 0 && currentQuestion) {
      let q = questions.find((ques => ques?.item_id === currentQuestion?.item_id));
      setQ_id(q.questionIds);
      //  setformat(q.stat[0]);
      if (q.a !== null) {
        q.style = "Multiple Choice";
      }
      setCurrentQuestion(q);

    }
  }, [questions])

  async function handleSelectOption(itemId, option) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          return { ...question, selectedOption: option };
        } else {
          return question;
        }
      })
    }))
  }
  async function handleCrossOutOptions(itemId, option) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          let crossedOutOptions = question.crossedOutOptions;
          if (crossedOutOptions.includes(option)) {
            crossedOutOptions = crossedOutOptions.filter((el) => el !== option);
          } else {
            crossedOutOptions.push(option);
          }
          return { ...question, crossedOutOptions: crossedOutOptions };
        } else {
          return question;
        }
      })
    }))
  }
  async function handleAnswerValueChange(itemId, value) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          return { ...question, selectedOption: value };
        } else {
          return question;
        }
      })
    }))
  }

  async function getQuestionsbyid() {
    setsat("Math")
    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "questions/psat89/byqid", { params: { qid: id } });
      if (res.data.length < 1) {
        toast.error("Questions With these topics not found. Try other topics for now by going back")
      } else {
        start();
      }
      let arr = [res.data]
      let mappedQuestions = arr.map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });


      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[0]);
      setLoadingQuestions(false);
      getAllNotes(arr[0].questionIds);
      setans(arr[0].correct_answer);
      setRaion(mappedQuestions[0].rationale);

      setState(arr[0].latex === "" ? true : arr[0].latex === 1);


      if (Array.isArray(arr) && arr.length > 0) {
        //console.log(arr);
        if (arr[0].practice_category_primary_section) {
          settopic(arr[0].practice_category_primary_section);
        }
        if (arr[0].skills) {
          setskills(arr[0].skills.split(","));
        }
      }
    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  async function getQuestions() {

    if (id > 938) {
      setsat("Math")
    }
    else if (id <= 938) {
      setsat("English")
    }

    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "questions/psat89", { params: { id: id } });
      if (res.data.length < 1) {
        toast.error("Questions With these topics not found. Try other topics for now by going back")
      } else {
        start();
      }
      let arr = [res.data]
      let mappedQuestions = arr.map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });


      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[0]);
      setLoadingQuestions(false);
      getAllNotes(arr[0].questionIds);
      setans(arr[0].correct_answer);
      setRaion(mappedQuestions[0].rationale);

      if (Array.isArray(arr) && arr.length > 0) {
        if (arr[0].practice_category_primary_section) {
          settopic(arr[0].practice_category_primary_section);
        }
        if (arr[0].skills) {
          setskills(arr[0].skills.split(","));
        }
      }
    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  const handleNext = () => {
    const nextId = Number(id) + 1;
    navigate(`/devpsat89/${nextId}`);
  }

  const handleBack = () => {
    const nextId = Number(id) - 1;
    navigate(`/devpsat89/${nextId}`);
  }

  const handleOptionClick = (option) => {
    if (option === "Pass") {
      const userConfirmed = window.confirm('Are you sure you want to remove this?');

      if (userConfirmed) {
        var data = {
          q_id: q_id,
        };

        axios.post(BASELINE + 'questions/remove/note/byqid', [data])
          .then((response) => {
            alert("Note Removed");
          })
          .catch((error) => {
            //  console.log(error);
          });
      }
    }
    setStatus(option);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.post(BASELINE + "update/sat89/questionids", currentQuestion);
      if (response.status === 200) {
        toast.success("Question updated successfully!");
      } else {
        toast.error("Failed to update question.");
      }
    } catch (error) {
      console.error("Error updating question:", error);
      toast.error("Error updating question.");
    }
  };

  const handlerationale = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      rationale: newContent,
    }));
  };

  const handlestem = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      stem: newContent,
    }));
  };

  const handleprompt = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      prompt: newContent,
    }));
  };

  const handlestimulus = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      stimulus: newContent,
    }));
  };

  return (
    <div className="fbg-white">

      <NavBar
        qidOptions={id}
        handleTestIdChange={(value) => console.log('Selected Test ID:', value)}
        handleSubjectChange={(value) => console.log('Selected Subject:', value)}
        handleQidChange={(value) => console.log('Selected QID:', value)}
        currentQuestion={currentQuestion}
        handleNext={handleNext}
        handleBack={handleBack}
        handleOptionClick={handleOptionClick}
        handleUpdate={handleUpdate}
      />

      {loadingQuestions ?
        <div className="flex flex-row justify-center items-center py-48">
          <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-52 w-52"></div>
        </div>
        :
        <>
          <div className=" flex flex-row justify-between bg-white">
            <div>
              <div className="panel">
                <div className="options">
                  <button
                    className={`hidden px-4 py-2 rounded ml-2 text-white ${status === 'Fail' ? 'bg-red-700' : 'bg-red-500 hover:bg-red-700'}`}
                    onClick={async () => {
                      handleOptionClick('Fail');
                      try {
                        const response = await fetch(BASELINE + "questions/notes", {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' },
                          body: JSON.stringify({ note: 'Failed', q_id: q_id, id: id, sat: "sat" })
                        });
                        if (!response.ok) {
                          // handle error
                        }
                      } catch (err) {
                        //  console.log(err);
                      }
                    }}
                  >
                    Fail
                  </button>

                </div>
                {status === 'Fail' && (
                  <>
                    <p>Note:</p>
                    <textarea
                      readOnly
                      className="mt-2 w-full p-2 border rounded"
                      placeholder="Please enter your note here..."
                      value={note.note.context}
                      onChange={async (e) => {
                        setNote(e.target.value);
                        const response = await fetch(BASELINE + "questions/notes", {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' },
                          body: JSON.stringify({ note: e.target.value, q_id: q_id, id: id, sat: "sat" })
                        });
                        if (!response.ok) {
                          // handle error
                        }
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="flex gap-2 p-2">
            <div className="w-[50%]">
              <p>Topic:</p>
              <Select
                value={currentQuestion?.primary_class_cd_descs
                  ? currentQuestion.primary_class_cd_descs.split(',').map(topic => ({ value: topic, label: topic }))
                  : []} // Convert string to an array of select options
                isMulti
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map(option => option.value);
                  setCurrentQuestion((prev) => ({
                    ...prev,
                    primary_class_cd_descs: selectedValues.join(','), // Convert array back to comma-separated string
                  }));
                }}
                options={Object.keys(topics).map((topic) => ({ value: topic, label: topic }))} // Options from the topics object
                className="basic-multi-select lg:min-w-[300px] lg:max-w-[300px]"
                classNamePrefix="select"
              />
            </div>

            <div className="w-[50%]">
              <p>Skills:</p>
              <Select
                value={currentQuestion?.skill_descs
                  ? currentQuestion.skill_descs.split(',').map(skill => ({ value: skill, label: skill }))
                  : []} // Convert string to an array of select options
                isMulti
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map(option => option.value);
                  setCurrentQuestion((prev) => ({
                    ...prev,
                    skill_descs: selectedValues.join(','), // Convert array back to comma-separated string
                  }));
                }}
                options={Object.values(topics).flat().map((skill) => ({ value: skill, label: skill }))} // Flatten the topics object for all skills
                className="basic-multi-select lg:min-w-[300px] lg:max-w-[300px]"
                classNamePrefix="select"
              />
            </div>
          </div>

          {/* question */}

          {currentQuestion ? <>
            <DevQuestion
              ans={ans}
              sat={sat}
              format={format}
              q_id={q_id}
              id={id}
              handleAnswerValueChange={handleAnswerValueChange}
              handleCrossOutOptions={handleCrossOutOptions}
              handleSelectOption={handleSelectOption}
              currentQuestion={currentQuestion}
              idx={1}
              bookmarkedQuestions={bookmarkedQuestions}
              setBookMarkedQuestions={setBookMarkedQuestions}
              handleBookMark={handleBookMark}
            />

            <div className="flex z-[10] mt-[10px]">
              <div className="flex flex-col justify-start w-[50%]">
                <div>

                  <textarea
                    value={currentQuestion.stem} // Bind to local state
                    onChange={(e) => handlestem(e.target.value)}
                    className={`w-full ${currentQuestion.stem ? 'h-[500px]' : 'h-[20px]'} p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] border-gray-300`}
                    placeholder="Edit question content here"
                  />
                  <textarea
                    value={currentQuestion.prompt} // Bind to local state
                    onChange={(e) => handleprompt(e.target.value)}
                    className={`w-full ${currentQuestion.prompt ? 'h-[500px]' : 'h-[20px]'} p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] border-gray-300`}
                    placeholder="Edit question content here"
                  />
                  <textarea
                    value={currentQuestion.stimulus} // Bind to local state
                    onChange={(e) => handlestimulus(e.target.value)}
                    className={`w-full ${currentQuestion.stimulus ? 'h-[500px]' : 'h-[20px]'} p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] border-gray-300`}
                    placeholder="Edit question content here"
                  />

                </div>
              </div>

              <div className="flex flex-col gap-2 my-4 w-[50%]">
                <p className="mt-2">Correct Answer:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.correct_answer} // Bind the value to currentQuestion.correct_answer
                  onChange={(e) => {
                    const newAnswer = e.target.value;
                    setCurrentQuestion((prev) => ({
                      ...prev,
                      correct_answer: newAnswer, // Update the correct answer
                    }));
                  }}
                  placeholder="Edit correct answer"
                />

                <p className="mt-2">Correct choice:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.placeholder} // Bind the value to currentQuestion.correct_answer
                  onChange={(e) => {
                    const newAnswer = e.target.value;
                    setCurrentQuestion((prev) => ({
                      ...prev,
                      correct_choice: newAnswer, // Update the correct answer
                    }));
                  }}
                  placeholder="Edit correct answer"
                />

                <p className="mt-2">Answer A:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.a} // Bind the value to currentQuestion.a
                  onChange={(e) => {
                    const newAnswerA = e.target.value;
                    setCurrentQuestion((prev) => ({
                      ...prev,
                      a: newAnswerA, // Update the answer for A
                    }));
                  }}
                  placeholder="Edit option A"
                />

                <p className="mt-2">Answer B:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.b} // Bind the value to currentQuestion.b
                  onChange={(e) => {
                    const newAnswerB = e.target.value;
                    setCurrentQuestion((prev) => ({
                      ...prev,
                      b: newAnswerB, // Update the answer for B
                    }));
                  }}
                  placeholder="Edit option B"
                />

                <p className="mt-2">Answer C:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.c} // Bind the value to currentQuestion.c
                  onChange={(e) => {
                    const newAnswerC = e.target.value;
                    setCurrentQuestion((prev) => ({
                      ...prev,
                      c: newAnswerC, // Update the answer for C
                    }));
                  }}
                  placeholder="Edit option C"
                />

                <p className="mt-2">Answer D:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.d} // Bind the value to currentQuestion.d
                  onChange={(e) => {
                    const newAnswerD = e.target.value;
                    setCurrentQuestion((prev) => ({
                      ...prev,
                      d: newAnswerD, // Update the answer for D
                    }));
                  }}
                  placeholder="Edit option D"
                />
              </div>
            </div>

            <p className="font-bold">Question Rationale</p>
            <div className="ml-[20px] flex z-[10] mt-[10px] gap-2 p-2" id="box-item-left" ref={boxItemLeftRef}>
              <div className="max-w-[50%]">
                <div
                  dangerouslySetInnerHTML={{ __html: currentQuestion.rationale }} // This inserts raw HTML with LaTeX
                  className="min-h-[40%] overflow-y-auto overflow-x-hidden my-list-style"
                />
              </div>

              <div className="w-[50%]">
                <textarea
                  value={currentQuestion.rationale} // Bind to local state
                  onChange={(e) => handlerationale(e.target.value)}
                  className="h-[100%] w-full p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] p-2"
                  placeholder="Edit question content here"
                />
              </div>
            </div>
          </> : <></>}

          <div className="flex flex-row justify-around  border-t-2 border-black pt-4 relative">
            {devmode === false && (
              <div className="mb-2 flex flex-row items-center gap-2 text-white">
                <button
                  className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                  onClick={() => window.location.reload()} // Reload the page when clicked
                >
                  Reset
                </button>
                <button className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                  onClick={() => handleUpdate()}
                >Update</button>
                <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800" onClick={handleBack}>
                  Back
                </motion.button>
                {currentQuestion ?
                  <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800" onClick={handleNext}>
                    Next
                  </motion.button>
                  :
                  <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800">
                    Done
                  </motion.button>
                }
              </div>
            )}

          </div>
        </>
      }
    </div >
  );
};
export default DevQuiz89;