import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { fadeInFromLeft } from "../../util/FadeFromLeftAnimation";
import { UserContext } from "../../context/UserContext";
import { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASELINE } from "../../util";

const MySVGIcon = () => {
  return (
    <svg
      fill="#FFFFFF" // Set the fill color to white
      version="1.1"
      id="Capa_1"
      viewBox="0 0 31.481 31.481"
      className="w-6 h-6" // Example for sizing
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <path d="M31.455,6.595c0.143-1.89-0.301-3.312-1.322-4.224c-0.957-0.858-2.183-1.03-3.002-1.032 c0.036-0.369,0.065-0.732,0.075-1.066H4.273c0.01,0.333,0.039,0.697,0.077,1.066C3.529,1.342,2.306,1.513,1.347,2.371 c-1.019,0.912-1.463,2.334-1.32,4.224c0.398,5.27,4.929,9.679,11.855,11.611c0.518,0.452,1.046,0.851,1.594,1.13 c-0.052,0.198-0.09,0.402-0.09,0.616c0,0.726,0.335,1.367,0.851,1.799v6.603h-1.229c-1.224,0-2.348,0.679-2.919,1.762l-0.576,1.093 h12.455l-0.577-1.094c-0.571-1.083-1.694-1.761-2.919-1.761h-1.228v-6.603c0.517-0.432,0.85-1.073,0.85-1.799 c0-0.214-0.037-0.418-0.09-0.616c0.548-0.279,1.075-0.679,1.595-1.13C26.525,16.274,31.056,11.865,31.455,6.595z M29.137,3.497 c0.647,0.585,0.923,1.589,0.814,2.984c-0.256,3.397-2.549,6.406-6.251,8.459c-0.247,0.137-0.557,0.092-0.754-0.111 c-0.198-0.203-0.235-0.513-0.092-0.757c2.019-3.441,3.489-7.776,4.075-11.224C27.478,2.825,28.453,2.88,29.137,3.497z M1.528,6.482 C1.422,5.087,1.698,4.083,2.344,3.498C3.029,2.88,4.002,2.826,4.551,2.85c0.587,3.448,2.057,7.782,4.075,11.223 c0.144,0.244,0.105,0.554-0.092,0.756c-0.198,0.203-0.506,0.249-0.754,0.112C4.08,12.887,1.785,9.878,1.528,6.482z"></path>
        </g>
      </g>
    </svg>
  );
};

const badgeColors = {
  gold: "bg-yellow-400",
  silver: "bg-gray-400",
  bronze: "bg-orange-400",
  pink: "bg-pink-400",
};

const Leaderboard = () => {
  const [showAll, setShowAll] = useState(false);
  const [rankings, setRankings] = useState([]);
  const [currentLeaderIndex, setCurrentLeaderIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);

  const displayedLeaders = showAll ? rankings : rankings.slice(0, 3);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const res = await axios.get(BASELINE + "rank");
        let rankedLeaders = assignBadges(res.data);
        if (rankedLeaders.length > 10) {
          rankedLeaders = rankedLeaders.slice(0, 10);
        }
        setRankings(rankedLeaders);
      } catch (error) {
        console.error('Error fetching ranking data:', error);
      }
    };
    fetchRankings();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLeaderIndex((prevIndex) =>
        prevIndex === displayedLeaders.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [displayedLeaders.length]);

  useEffect(() => {
    setOpacity(0); // Start fade-out
    const timeout = setTimeout(() => {
      setOpacity(1); // Fade-in after a brief delay
    }, 100); // Adjust delay as needed

    return () => clearTimeout(timeout);
  }, [currentLeaderIndex]);

  const assignBadges = (leaders) => {
    return leaders.map((leader, index) => {
      if (index === 0) {
        leader.badge = 'gold';
      } else if (index === 1) {
        leader.badge = 'silver';
      } else if (index === 2) {
        leader.badge = 'bronze';
      } else {
        leader.badge = 'pink';
      }
      return leader;
    });
  };

  return (
    <div className="max-w-sm mx-auto">

      <div className="mt-8 flex items-center justify-center">
        {displayedLeaders.length > 0 && (
          <div
            key={currentLeaderIndex}
            className="flex items-center justify-between p-4 border bg-white w-full max-w-md"
            style={{ opacity: opacity, transition: 'opacity 0.5s' }}
          >
            <div className="flex items-center space-x-4">
              <div>
                <h3 className="font-bold text-slate-700">
                  {displayedLeaders[currentLeaderIndex].user_name}
                </h3>
                <span className="text-sm text-slate-700">
                  scores: {displayedLeaders[currentLeaderIndex].correct_rate}
                </span>
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <span className="text-slate-700 text-sm font-bold">
                {displayedLeaders[currentLeaderIndex].time}
              </span>
              <div
                className={`w-[40px] h-[40px] rounded-full ${badgeColors[displayedLeaders[currentLeaderIndex].badge]
                  } flex items-center justify-center`}
              >
                <MySVGIcon />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Toggle Button */}
      <div className="hidden mt-4 flex justify-center">
        <button
          onClick={() => setShowAll(!showAll)}
          className="bg-indigo-900 text-white py-2 px-4 rounded-full focus:outline-none hover:bg-indigo-900 transition duration-300"
        >
          {showAll ? "Show Less" : "Show More"}
        </button>
      </div>
    </div>
  );
};

export default Leaderboard;