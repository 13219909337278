import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASELINE } from "../../util";

const NavBar = ({ currentQuestion, testIdOptions, actproblemnum, subjectOptions, qidOptions, handleTestIdChange, handleNext, handleBack }) => {
    const navigate = useNavigate();
    const [selectedTestId, setselectedTestId] = useState("");
    const [subject, setSubject] = useState([]);

    // Function to fetch the subject data based on selected test ID
    const fetchTestName = async (q_id) => {
        try {
            let res = await axios.get(BASELINE + "get/act/testname", {
                params: {
                    q_id: q_id
                }
            });
            setSubject(res.data); // Set subject data to state
        } catch (err) {
            console.error(err);
        }
    };

    // Run fetchTestName on initial load with q_id "D03"
    useEffect(() => {
        fetchTestName({ exam_code: "D03" }); // Initial fetch with q_id "D03"
    }, []);

    // Run fetchTestName every time selectedTestId changes
    useEffect(() => {
        if (selectedTestId) {
            fetchTestName(selectedTestId);
        }
    }, [selectedTestId]);


    return (
        <nav className="bg-slate-700 text-white p-4 flex justify-between items-center">

            <div className="flex gap-4">
                <div>
                    <label htmlFor="testId" className="mr-2">Test ID:</label>
                    <select
                        id="testId"
                        className="bg-slate-600 text-white p-1 rounded"
                        onChange={(e) => {
                            const selectedTest = testIdOptions[e.target.selectedIndex]; // Get selected test object
                            setselectedTestId(selectedTest); // Update the state with selected test
                            handleTestIdChange(selectedTest.exam_code); // Call handler with exam_code
                            navigate(`/devact/${selectedTest.qid}`); // Navigate with the correct qid immediately
                        }}
                    >
                        {testIdOptions.map((testId, index) => (
                            <option key={index} value={testId.exam_code}>
                                {testId.exam_code}
                            </option>
                        ))}
                    </select>
                </div>


                {/* Dropdown for Subject */}
                <div>
                    <label htmlFor="subject" className="mr-2">Subject:</label>
                    <select
                        id="subject"
                        className="bg-slate-600 text-white p-1 rounded"
                        onChange={(e) => {
                            const data = subject[e.target.selectedIndex]
                            navigate(`/devact/${data.first_qid}`);
                        }}
                    >
                        {subject.map((subject, index) => (
                            <option key={index} value={subject.subject}>
                                {subject.subject}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='flex'>
                    <div className="mr-2 mt-[8px]">ACT Question ID:</div>
                    <div
                        id="qid"
                        className="bg-slate-600 text-white p-1 rounded"
                    >
                        {currentQuestion?.q_num}
                    </div>
                </div>

                {/* Dropdown for QID */}
                <div className='flex'>
                    <div className="mr-2 mt-[8px]">QID:</div>
                    <div id="qid" className="bg-slate-600 text-white p-1 rounded">
                        <input
                            type="number" // This will restrict the input to numbers only
                            value={qidOptions} // Assuming this holds the default or current value
                            onChange={(e) => {
                                navigate(`/devact/${e.target.value}`); // Navigate when Enter is pressed
                            }}
                            className="bg-slate-600 text-white p-1 rounded" // Add some styling to the input
                        />
                    </div>
                </div>
                <div className="mt-[8px]">
                    Category: {currentQuestion?.category}
                </div>
                <button onClick={() => handleBack()}>Back</button>
                <button onClick={() => handleNext()}> Next</button>

            </div>
        </nav >
    );
};

export default NavBar;
