import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASELINE } from "../../util";

const NavBar = ({ currentQuestion, handleUpdate, qidOptions, handleTestIdChange, handleNext, handleBack, handleOptionClick }) => {
    const navigate = useNavigate();

    return (
        <nav className="bg-slate-700 text-white p-4 flex justify-between items-center">

            <div className="flex gap-4">

                <div className='flex'>
                    <div className="mr-2 mt-[8px]">QuestionIds:</div>
                    <div
                        id="qid"
                        className="bg-slate-600 text-white p-1 rounded p-2"
                    >
                        {currentQuestion?.questionIds}
                    </div>
                </div>

                {/* Dropdown for QID */}
                <div className='flex'>
                    <div className="mr-2 mt-[8px]">Question #:</div>
                    <div id="qid" className="bg-slate-600 text-white p-1 rounded">
                        <input
                            type="number" // This will restrict the input to numbers only
                            value={qidOptions} // Assuming this holds the default or current value
                            onChange={(e) => {
                                navigate(`/devpsat/${e.target.value}`); // Navigate when Enter is pressed
                            }}
                            className="bg-slate-600 text-white p-1 rounded" // Add some styling to the input
                        />
                    </div>
                </div>
                <div className="mt-[8px]">
                    Category: {currentQuestion?.category}
                </div>
                <button className="bg-slate-600 p-2 rounded-lg" onClick={() => handleOptionClick('Pass')}>Remove the Alert</button>
                <button className="ml-[100px]" onClick={() => window.location.reload()}>Reset</button>
                <button onClick={() => handleUpdate()}>Update</button>

                {!isNaN(qidOptions) && (
                    <>
                        <button onClick={() => handleBack()}>Back</button>
                        <button onClick={() => handleNext()}>Next</button>
                    </>
                )}
                <button>math: 718</button>
            </div>
        </nav >
    );
};

export default NavBar;
