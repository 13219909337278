import { Route, Routes, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Header from "./layout/Header";
import Home from "./pages/home/Index";
import Footer from "./layout/Footer";

import Topic from "./pages/topic/Index";
import Topic89 from "./pages/topic/Index89";
import Topic10 from "./pages/topic/Index10";

import EngResult from "./pages/result/Index_Eng";
import PresatResult from "./pages/result/Index";

import SignupPanel from "./login/signup";
import SignInForm from "./login/login";
import Dashboard from "./pages/dashboard/Index";
import TDashboard from "./pages/dashboard/TIndex";
import PDashboard from "./pages/dashboard/PIndex";

import DevQuiz89 from "./pages/dev_quiz89/Index";
import DevQuiz10 from "./pages/dev_quiz10/Index";
import DevQuiz from "./pages/dev_quiz/Index";
import DevACT from "./pages/dev_act/Index";
import DevQuizAPCALC from "./pages/dev_apcalc/Index";

import SATQuiz from "./pages/sat/Index";
import ACTQuizfromdashboard from "./pages/act_quiz/Index";
import LatexRender from "./pages/latex/latex";

import RandomSATReady from "./pages/topic/Random_SAT";
import RandomACTReady from "./pages/topic/Random_ACT";
import RandomSATReady89 from "./pages/topic/Random_SAT89";
import RandomSATReady10 from "./pages/topic/Random_SAT10";
import PresetSAT from "./pages/presetsat/Index";
import ACTScoreboard from "./pages/result/ACT_index";
import FreeTopic from "./pages/topic/freemath";

import { useLocation } from "react-router-dom";

import SatAnswers from "./pages/digital_answers/Digital_sat_answers";
import ACTQuiz from "./pages/act/Index";

const App = () => {
  const location = useLocation();
  useEffect(() => {
    document.title = 'SAT MMTPrep';
  }, []);

  const [isAuthenticated, setIsAuthenticated] = useState(false); // Set the initial authentication status

  // Function to handle successful login and set the authentication status
  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const hiddenRoutes = ["/act/preset", "/sat/quiz", "/sat/preset/quiz", "/dashboard", "/freetrial/sat", "/devact", "/devpsat", "/devapcalc", "/act/quiz"];
  const hiddenRoutes2 = ["/dashboard", "/act/preset", "/sat/quiz", "/sat/preset/quiz", "/freetrial/sat", "/devact", "/devpsat", "/devapcalc", "/act/quiz"];

  useEffect(() => {
  }, [isAuthenticated]);

  return (
    <div className=" bg-white">
      {!hiddenRoutes2.some(route => location.pathname.startsWith(route)) && <Header />}

      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Home />} path="/dev" />

        {/*  <Route element={<SATMATHTOPIC />} path="/topic" /> */}
        <Route element={<SATQuiz />} path="/sat/quiz" />
        <Route element={<ACTQuizfromdashboard />} path="/act/quiz" />

        <Route element={<PresetSAT free={true} freesat={"sat1"} />} path="freetrial/sat" />
        <Route element={<SATQuiz />} path="freetrial/engquiz" />


        <Route element={<Dashboard />} path="/dashboard" />
        <Route element={<TDashboard />} path="/dashboard/teacher" />
        <Route element={<PDashboard />} path="/dashboard/parents" />

        <Route element={<DevQuiz />} path="/devpsat/:id" />
        <Route element={<DevQuiz89 />} path="/devpsat89/:id" />
        <Route element={<DevQuiz10 />} path="/devpsat10/:id" />
        <Route element={<DevACT />} path="/devact/:id" />
        <Route element={<DevQuizAPCALC />} path="/devapcalc/:id" />

        <Route element={<Topic />} path="/sat/topic" />
        <Route element={<Topic89 />} path="/psat89/topic" />
        <Route element={<Topic10 />} path="/psat10/topic" />
        <Route element={<FreeTopic />} path="/freetrial/mathquiz" />

        <Route element={<LatexRender />} path="/latex" />

        <Route element={<RandomSATReady />} path="/sat" />
        <Route element={<RandomACTReady />} path="/act" />
        <Route element={<RandomSATReady89 />} path="/psat89" />
        <Route element={<RandomSATReady10 />} path="/psat10" />

        <Route element={<PresetSAT />} path="/sat/preset/quiz/:id" />
        <Route element={<ACTQuiz />} path="/act/preset/quiz/:id" />

        <Route path="/digital/answer/:folderType" element={<SatAnswers />} />

        {/* <Route element={<Result />} path="/result" /> */}
        <Route element={<PresatResult />} path="/results/presat/:id" />
        <Route element={<EngResult />} path="/results/sat/quiz/:id" />
        <Route element={<EngResult />} path="/results/act/quiz/:id" />
        <Route element={<ACTScoreboard />} path="/results/act/:id" />

        {/* Only allow access to the SignupPanel if user is not authenticated */}

        <Route element={<SignupPanel />} path="/signup" />

        {/* Only allow access to the SignInForm if user is not authenticated */}
        {!isAuthenticated && (
          <Route element={<SignInForm onLogin={handleLogin} />} path="/login" />
        )}
        {/* Redirect users to the home page if they try to access SignupPanel or SignInForm when authenticated */}
        {isAuthenticated && (
          <>
            <Route
              path="/signup"
              element={<Navigate to="/" replace />}
            />
            <Route
              path="/login"
              element={<Navigate to="/" replace />}
            />
          </>
        )}

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {!hiddenRoutes.some(route => location.pathname.startsWith(route)) && <Footer />}
    </div>
  );
};

export default App;
