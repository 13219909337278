import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../util/FadeFromLeftAnimation";

const Study = () => {
  return (
    <div className="wrapper mb-24 my-10 md:mt-20 mt-1 max-w-[1150px] w-10/12 mx-auto ">
      <div className="contain flex-col justify-start items-center sm:items-start gap-10">
        <motion.h3

          className="title !text-black sm:text-4xl text-center text-3xl mb-14"
        >
          Study With Books
        </motion.h3>
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-3 justify-center w-full md:gap-8  place-items-center">
          <BookCard1 />
          <BookCard2 />
          <BookCard3 />
          {/* <div className="w-full bg-contain bg-no-repeat aspect-[1/1.5] bg-studyBg"></div>
          <div className="w-full bg-contain bg-no-repeat aspect-[1/1.5] bg-studyBg"></div>
          <div className="w-full bg-contain bg-no-repeat aspect-[1/1.5] bg-studyBg"></div> */}
        </div>
      </div>
    </div>
  );
};
const BookCard1 = (props) => {
  return (
    <motion.div
      className="min-h-[650px] sm:min-w-[350px] w-11/12 overflow-hidden border-2 rounded-md"
    >
      <img
        src="book_cover.png"
        alt=""
        className="duration-300 hover:scale-105 w-full min-h-[450px]"
      />
      <div className="py-5 flex gap-2 flex-col items-center  text-center">
        <h3 className="sm:text-2xl text-xl font-inter">
          SAT Math Principles: #1
        </h3>
        <h4 className="text-lg font-inter">$29.99</h4>
        <a href="https://www.amazon.com/SAT-Math-Principles-MMT-Prep/dp/B0C8R5WY1M"
          className="w-24 border border-black rounded-xl py-2 bg-black text-white hover:bg-white hover:text-black duration-300 inline-block text-center"
          role="button">
          Buy Now
        </a>
      </div>
    </motion.div>
  );
};

const BookCard2 = (props) => {
  return (
    <motion.div
    className="min-h-[650px] sm:min-w-[350px] w-11/12 overflow-hidden border-2 rounded-md"
    >
      <img
        src="book_cover2.png"
        alt=""
        className="duration-300 hover:scale-105 w-full min-h-[450px]"
      />
      <div className="py-5 flex gap-2 flex-col items-center  text-center">
        <h3 className="sm:text-2xl text-xl font-inter">
          DIGITAL SAT MATH: Pinciples
        </h3>
        <h4 className="text-lg font-inter">$24.99</h4>
        <a href="https://www.amazon.com/DIGITAL-SAT-MATH-MMT-Prep/dp/B0D56SVB78/ref=sr_1_3?crid=1L1YQO8NS9PL1&dib=eyJ2IjoiMSJ9.B7ObKx3j0feNL7o7rmPs1yuDLLsZ4_1wBCHt86tgvLiaijg8ELqUwUKTZga_6pT-FMbuOUU01eRH8fMvIkPbFJELVAvuXacxhBF-hLtxDY0jRFWYwr6i39LZLK3Bh8L6PBiHUenSQRpK0xRBl9jtz-ymwomKJNlPR8TBnv2_us_8jgWOhUA4CGs-dYvzThllBbHqg82v5SPkeoxpv7dxTgbOlUSh1l8hZXJ7QoWtaCk.SUrzTU5MRd-56l2kZ8wzNyrZZRlXoDOPNXbO1lAIzV0&dib_tag=se&keywords=mmt+sat+math&qid=1717025203&sprefix=mmt+sat+mat%2Caps%2C139&sr=8-3"
          className="w-24 border border-black rounded-xl py-2 bg-black text-white hover:bg-white hover:text-black duration-300 inline-block text-center"
          role="button">
          Buy Now
        </a>
      </div>
    </motion.div>
  );
};

const BookCard3 = (props) => {
  return (
    <motion.div
    className="min-h-[650px] sm:w-[350px] w-11/12 overflow-hidden border-2 rounded-md"
    >
      <img
        src="book_cover3.png"
        alt=""
        className="duration-300 hover:scale-105 w-full min-h-[450px]"
      />
      <div className="py-5 flex gap-2 flex-col items-center  text-center">
        <h3 className="sm:text-2xl text-xl font-inter">
          DIGITAL SAT MATH: Advanced
        </h3>
        <h4 className="text-lg font-inter">$24.99</h4>
        <a href="https://www.amazon.com/DIGITAL-SAT-MATH-MMT-Prep/dp/B0D4ZJBDN1/ref=sr_1_4?crid=1L1YQO8NS9PL1&dib=eyJ2IjoiMSJ9.B7ObKx3j0feNL7o7rmPs1yuDLLsZ4_1wBCHt86tgvLiaijg8ELqUwUKTZga_6pT-FMbuOUU01eRH8fMvIkPbFJELVAvuXacxhBF-hLtxDY0jRFWYwr6i39LZLK3Bh8L6PBiHUenSQRpK0xRBl9jtz-ymwomKJNlPR8TBnv2_us_8jgWOhUA4CGs-dYvzThllBbHqg82v5SPkeoxpv7dxTgbOlUSh1l8hZXJ7QoWtaCk.SUrzTU5MRd-56l2kZ8wzNyrZZRlXoDOPNXbO1lAIzV0&dib_tag=se&keywords=mmt+sat+math&qid=1717025069&sprefix=mmt+sat+mat%2Caps%2C139&sr=8-4"
          className="w-24 border border-black rounded-xl py-2 bg-black text-white hover:bg-white hover:text-black duration-300 inline-block text-center"
          role="button">
          Buy Now
        </a>
      </div>
    </motion.div>
  );
};
export default Study;
