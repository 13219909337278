import React, { useState } from 'react';
import axios from 'axios';
import { BASELINE } from "../util/index";
import { useNavigate } from 'react-router-dom';

const SignupPanel = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        grade: '',
        role: '',
        couponCode: '',
    });
    const [isCouponValid, setIsCouponValid] = useState(null);
    const [isVerifying, setIsVerifying] = useState(false);
    const [subscribeNewsletter, setSubscribeNewsletter] = useState(false); // For the newsletter checkbox
    const validCouponCodes = ['MMTFREE3MONTHS', 'MMTPREMIUM123'];

    const getPremiumDates = () => {
        const today = new Date();
        const startPremiumDate = today.toISOString().split('T')[0];

        const endPremiumDate = new Date();
        endPremiumDate.setMonth(endPremiumDate.getMonth() + 3);
        const endPremiumDateString = endPremiumDate.toISOString().split('T')[0];

        return { startPremiumDate, endPremiumDateString };
    };

    const handleVerifyCoupon = () => {
        setIsVerifying(true);
        setTimeout(() => {
            if (validCouponCodes.includes(formData.couponCode)) {
                setIsCouponValid(true);
            } else {
                setIsCouponValid(false);
            }
            setIsVerifying(false);
        }, 1000);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // Automatically set grade to 99 if role is 'parents'
        if (name === "role" && value === "parents") {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                grade: '99', // Automatically set grade to 99
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };


    const handleNewsletterChange = (event) => {
        setSubscribeNewsletter(event.target.checked); // Update the state when checkbox is clicked
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (formData.couponCode && isCouponValid === false) {
            alert('Please enter a valid coupon code or remove it.');
            return;
        }

        const { startPremiumDate, endPremiumDateString } = getPremiumDates();

        try {
            const dataToSend = {
                ...formData,
                premium: isCouponValid ? 1 : 0,
                start_premium_date: isCouponValid ? startPremiumDate : null,
                end_premium_date: isCouponValid ? endPremiumDateString : null,
            };

            const response = await axios.post(BASELINE + 'user/insert/info', dataToSend);

            // After successful signup, check if the user opted to receive newsletters
            if (subscribeNewsletter) {
                await axios.post(BASELINE + 'email', [formData.email]); // Send email data to /email API
            }

            // Reset form
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                grade: '',
                role: '',
                couponCode: '',
            });
            setIsCouponValid(null);
            setSubscribeNewsletter(false);
            alert('Registration successful!');
            navigate('/');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Handle "Email already in use" error
                alert(error.response.data.message || 'Email is already in use. Please use a different email.');
            } else {
                console.error('Error during registration:', error);
                alert('An error occurred during registration. Please try again later.');
            }
        }
    };

    return (
        <div className="pt-6 px-4 sm:px-16 md:px-32 lg:px-40 xl:px-[20%] items-center h-screen bg-white">
            <div className="rounded text-[#232323]">
                <h2 className="text-2xl font-bold mb-4 text-center">Sign Up</h2>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col sm:grid sm:grid-cols-3 gap-4 sm:gap-6">
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="firstName">
                                First Name*
                            </label>
                            <input
                                className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                                type="text"
                                id="first_name"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="lastName">
                                Last Name*
                            </label>
                            <input
                                className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                                type="text"
                                id="last_name"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="role">
                                Role*
                            </label>
                            <select
                                className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                                id="role"
                                name="role"
                                value={formData.role}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select a role</option>
                                <option value="student">Student</option>
                                <option value="parents">Parents</option>
                                <option value="teacher">Teacher</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2" htmlFor="email">
                            Email*
                        </label>
                        <input
                            className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    {/* Newsletter Checkbox */}
                    <div className="mb-4">
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="form-checkbox"
                                checked={subscribeNewsletter}
                                onChange={handleNewsletterChange}
                            />
                            <span className="ml-2 text-sm font-bold text-[#232323]">Get the latest newsletter</span>
                        </label>
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-bold mb-2" htmlFor="password">
                            Password*
                        </label>
                        <input
                            className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                            type="password"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            required
                        />
                    </div>

                    {(formData.role === 'student' || formData.role === 'teacher') && (<>
                        {formData.role === 'student' && (
                            <div className="mb-4">
                                <label className="block text-sm font-bold mb-2" htmlFor="grade">
                                    School Grade (Numbers Only)
                                </label>
                                <input
                                    className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                                    type="number"
                                    id="grade"
                                    name="grade"
                                    value={formData.grade}
                                    onChange={handleInputChange}
                                    min="1"
                                    max="12"
                                />
                            </div>
                        )}
                        <div className="mb-4">
                            <label className="block text-sm font-bold mb-2" htmlFor="couponCode">
                                Coupon Code (Optional)
                            </label>
                            <div className="flex">
                                <input
                                    className="border border-gray-400 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500 text-[#232323]"
                                    type="text"
                                    id="couponCode"
                                    name="couponCode"
                                    value={formData.couponCode}
                                    onChange={handleInputChange}
                                />
                                <button
                                    type="button"
                                    onClick={handleVerifyCoupon}
                                    className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Verify
                                </button>
                            </div>
                            {isCouponValid !== null && (
                                <p className={`mt-2 ${isCouponValid ? 'text-green-500' : 'text-red-500'}`}>
                                    {isCouponValid ? 'Coupon code is valid!' : 'Coupon code is invalid!'}
                                </p>
                            )}
                        </div>
                    </>)}

                    <div className="flex justify-end">
                        <button
                            className="bg-[#001F3F] text-white border border-white hover:shadow-outline-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full sm:w-auto"
                            type="submit"
                        >
                            Sign Up
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignupPanel;
