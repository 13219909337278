import React, { useState, useEffect, useRef } from "react";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { ImSpellCheck } from "react-icons/im";
import { motion } from "framer-motion";
import 'katex/dist/katex.min.css';
import Latex from "react-latex-next";

const Question = ({
    time,
    sat,
    handleAnswerValueChange,
    handleCrossOutOptions,
    handleSelectOption,
    handleBookMark,
    currentQuestion,
    result,
}) => {
    const boxItemLeftRef = useRef();
    const [showCrossOutMenu, setShowCrossOutMenu] = useState(false);

    // State for pane widths
    const [leftWidth, setLeftWidth] = useState(50); // Start with 50% width
    const [isResizing, setIsResizing] = useState(false);

    // Refs
    const dividerRef = useRef(null);

    // Handle mouse down on the divider
    const onMouseDown = (e) => {
        setIsResizing(true);
    };

    // Handle mouse move
    const onMouseMove = (e) => {
        if (!isResizing) return;
        // Calculate new width
        const containerWidth = dividerRef.current.parentNode.getBoundingClientRect().width;
        const newLeftWidth = (e.clientX / containerWidth) * 100;
        if (newLeftWidth > 10 && newLeftWidth < 90) { // Prevent extreme resizing
            setLeftWidth(newLeftWidth);
        }
    };

    // Handle mouse up
    const onMouseUp = () => {
        setIsResizing(false);
    };

    // Add event listeners
    useEffect(() => {
        if (isResizing) {
            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);
            document.body.style.userSelect = 'none';
        } else {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
            document.body.style.userSelect = 'auto';
        }

        // Cleanup
        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
            document.body.style.userSelect = 'auto';
        };
    }, [isResizing]);

    useEffect(() => {
        const script2 = document.createElement('script');
        script2.src = "https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js";
        script2.integrity = "sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05";
        script2.crossOrigin = "anonymous";
        script2.defer = true;

        // Handle script load
        script2.onload = () => {
            if (boxItemLeftRef.current) {
                if (window.renderMathInElement) {
                    const boxItemLeft = document.getElementById("box-item-left");
                    window.renderMathInElement(boxItemLeft, {
                        delimiters: [
                            { left: '\\(', right: '\\)', display: false },
                            { left: '\\[', right: '\\]', display: true },
                        ],
                        throwOnError: false,
                    });
                }
            }
        };
        document.body.appendChild(script2);

        // Cleanup: remove the script when component unmounts
        return () => {
            document.body.removeChild(script2);
        };
    }, [currentQuestion]);

    const preprocessOptions = (optionsString) => {
        try {
            // Check if the input is already a valid JSON array string
            if (typeof optionsString === "string") {
                // Now try to parse the JSON
                return JSON.parse(optionsString);
            }

            // If input is not a string, return an empty array
            return [];
        } catch (e) {
            console.error("Failed to parse options:", e);
            return []; // Fallback to an empty array if parsing fails
        }
    };

    const RenderOptions = () => {
        try {
            let options = preprocessOptions(currentQuestion.options); // Process options
            // Ensure options is always an array
            if (typeof options === "string") {
                options = JSON.parse(options); // Attempt to parse if it's still a string
            }

            return options.length > 0 ? (
                options.map((option, idx) => (
                    <Option
                        sat={sat}
                        key={idx}
                        handleCrossOutOptions={handleCrossOutOptions}
                        currentQuestion={currentQuestion}
                        handleSelectOption={handleSelectOption}
                        option={option}
                        showCrossOutMenu={showCrossOutMenu}
                        crossedOutOptions={currentQuestion.crossedOutOptions}
                    />
                ))
            ) : (
                <p>No options available</p> // Fallback if the options array is empty
            );
        } catch (error) {
            console.error("Error parsing options:", error);
            return <p>Error displaying options</p>; // Return error message if there is a parsing error
        }
    };

    // Parse the options if they are provided as a string
    let options = useRef(preprocessOptions(currentQuestion.options)).current;
    if (typeof options === "string") {
        try {
            options = JSON.parse(options);
        } catch (e) {
            console.error("Failed to parse options:", e);
            console.error("Problematic JSON:", options);
            options = []; // Fallback to an empty array if parsing fails
        }
    }

    // Function to remove the first <b>#.</b> from the prompt
    const sanitizePrompt = (prompt) => {
        // This regex targets the first occurrence of a <b>...</b> tag with any number inside
        return prompt.replace(/<b>\d+\. <\/b>/, '');
    };

    return (<>
        <div id="box-item-left" ref={boxItemLeftRef} className="flex h-full">
            <div
                className="left-pane"
                style={{ width: `${leftWidth}%` }}
            >
                <div className="mb-[160px] mt-[3%] z-[10]">
                    <div className={`flex flex-col justify-start ml-2 mr-2`}>
                        <div>
                            {currentQuestion.subject === "math" ? (
                                <div
                                    id="test-img"
                                    dangerouslySetInnerHTML={{ __html: sanitizePrompt(currentQuestion.prompt) }}
                                    className="mt-[40px] min-h-[40%] overflow-y-auto overflow-x-hidden my-list-style"
                                />
                            ) : (<>
                                <div
                                    dangerouslySetInnerHTML={{ __html: currentQuestion.content }}
                                    className="min-h-[40%] overflow-y-auto overflow-x-hidden my-list-style"
                                />
                            </>)
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Divider */}
            <div
                ref={dividerRef}
                className="divider mt-[20px] h-[800px]"
                onMouseDown={onMouseDown}
            />

            {/* Right Side Content */}
            <div
                className="right-pane"
                style={{ width: `${100 - leftWidth}%` }}
            >
                <div className="mt-[40px] mr-[20px] ml-[20px]">
                    <div className="flex flex-row justify-between bg-gray-100">
                        <div className="flex flex-row gap-2">
                            <div className=" flex flex-row items-center">
                                <p className="py-2 px-3 bg-black text-white">{currentQuestion.index + 1}</p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <motion.div whileTap={{ scale: 0.97 }} onClick={() => handleBookMark(currentQuestion.q_num)} className="m-1 cursor-pointer">
                                    {currentQuestion.bookMarked ? <BsBookmarkFill color="red" size="1.2rem" /> : <BsBookmark size="1.2rem" />}
                                </motion.div>
                                <div>
                                    <p>Mark For Review</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <motion.div whileTap={{ scale: 0.98 }} className={`p-[5px] border-black border-[1px] rounded-md cursor-pointer ${showCrossOutMenu && "bg-blue-700 text-white"}`} onClick={() => setShowCrossOutMenu(pre => !pre)}>
                                <ImSpellCheck />
                            </motion.div>
                        </div>
                    </div>

                    <div className="my-2 min-w-[100px] max-w-[600px] max-h-[580px] overflow-y-auto">
                        {result === true ? (
                            <>
                                <div className="text-black text-l mb-[5px]">
                                    ⏲️Time Spent: {time}sec
                                </div>
                            </>
                        ) : result === false ? (
                            <>
                                <div className="text-black text-l mb-[5px]">
                                    ⏲️Time Spent: {time}sec
                                </div>
                            </>
                        ) : (
                            <span></span>
                        )}
                        {currentQuestion.subject !== "math" &&
                            <div dangerouslySetInnerHTML={{ __html: sanitizePrompt(currentQuestion.prompt) }} className="ml-[20px]" />
                        }

                        {currentQuestion.subject !== "math" && (
                            <div
                                className={`p-2 ${result === true
                                    ? "bg-[#daffb2]"
                                    : result === false
                                        ? "bg-red-400"
                                        : "bg-white"
                                    }`}
                            >
                                <div
                                    className="box-item-left"
                                    dangerouslySetInnerHTML={{ __html: currentQuestion.stem }}
                                />
                            </div>
                        )}

                        <RenderOptions />

                        <div
                            className={`${result === true
                                ? "bg-[#daffb2]"
                                : result === false
                                    ? "bg-red-400"
                                    : "bg-white"
                                }`}
                        >

                            <div
                                className={`ml-[15px] p-4 rounded-xl z-[99999] ${result === true
                                    ? "bg-[#daffb2]"
                                    : result === false
                                        ? "bg-red-400"
                                        : "bg-white"
                                    }`}
                            >
                                {result === true ? (
                                    <>
                                        <div className="w-[180px] text-black font-bold text-2xl border-2 p-2 rounded-xl border-black">
                                            👊 Correct!
                                        </div>
                                        <div
                                            className={`mt-[15px] box-item-left`}
                                            dangerouslySetInnerHTML={{
                                                __html: currentQuestion.expl,
                                            }}
                                        />
                                    </>
                                ) : result === false ? (
                                    <>
                                        <span className="border-2 p-2 rounded-xl text-white font-bold text-2xl">
                                            🚨 Wrong!
                                        </span>
                                        <div
                                            className={`mt-[15px] box-item-left`}
                                            dangerouslySetInnerHTML={{
                                                __html: currentQuestion.expl,
                                            }}
                                        />
                                    </>
                                ) : (
                                    <span></span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};

export default Question;

const Option = ({ sat, handleCrossOutOptions, currentQuestion, handleSelectOption, showCrossOutMenu, crossedOutOptions, option }) => {
    const optionLetter = option.charAt(0); // Extract the first character as the option letter
    const optionText = option.slice(2).trim(); // Extract the text part after the letter and period

    return (
        <div className="flex flex-row gap-1">
            <motion.div whileTap={{ scale: 0.98 }}
                className={`flex flex-row w-full ${currentQuestion.selectedOption === optionLetter.toLowerCase() ? "border-[2px]" : "border-[1px]"} rounded-md ${currentQuestion.selectedOption === optionLetter.toLowerCase() ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative ${crossedOutOptions.includes(optionLetter.toLowerCase()) && "before:absolute before:bg-black before:w-[103%] before:h-[2px] before:top-[50%] before:-left-[6px]"}`}
                onClick={() => handleSelectOption(currentQuestion.q_num, optionLetter.toLowerCase())}
            >
                <div className="flex flex-row items-center">
                    <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
                        {optionLetter}
                    </p>
                </div>
                <div className="flex flex-row items-center p-[2px]">
                    {sat === "Math" || sat === "science" ? (
                        <Latex>{optionText}</Latex> // Use Latex component to render LaTeX content
                    ) : (
                        <p>{optionText}</p>
                    )}
                </div>
            </motion.div>
            {showCrossOutMenu && (
                <motion.div className="flex flex-row items-center" whileTap={{ scale: 0.97 }}>
                    {crossedOutOptions.includes(optionLetter.toLowerCase()) ? (
                        <p className="cursor-pointer underline" onClick={() => handleCrossOutOptions(currentQuestion.q_num, optionLetter.toLowerCase())}>
                            Undo
                        </p>
                    ) : (
                        <>
                            <p onClick={() => handleCrossOutOptions(currentQuestion.q_num, optionLetter.toLowerCase())} className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full cursor-pointer">
                                {optionLetter}
                            </p>
                            <div className="absolute w-6 h-[1.5px] bg-black top-3 cursor-pointer" onClick={() => handleCrossOutOptions(currentQuestion.q_num, optionLetter.toLowerCase())} />
                        </>
                    )}
                </motion.div>
            )}
        </div>
    );
};

