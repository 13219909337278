import { motion } from "framer-motion";
import { fadeInFromLeft } from "../../util/FadeFromLeftAnimation";
import { Link } from "react-router-dom";

const ChooseYourTest = () => {
  return (
    <div className="max-w-[1150px] w-10/12 mx-auto pt-[210px] mb-[60px]">
      <motion.h2
        {...fadeInFromLeft(0.2)}
        className="title !text-black sm:text-4xl text-3xl text-center "
      >
        Choose Your Challenge
      </motion.h2>
      <div className="pt-8 flex justify-evenly gap-4 flex-wrap">
        <ChooseYourTestCard title="Digital SAT" delay={0.2} link="/sat" />
        <ChooseYourTestCard title="Digital NMSQT" delay={0.4} link="/psat10" />
        <ChooseYourTestCard title="Digital SAT 8/9" delay={0.6} link="/psat89" />
        <ChooseYourTestCard title="ACT" delay={0.8} link="/act" />
        {/*  <ChooseYourTestCard title="AP Calc." delay={1.0} link="" /> */}
      </div>
    </div>
  );
};

const ChooseYourTestCard = (props) => {

  return (
    <motion.div
      {...fadeInFromLeft(props.delay)}
      className="hover:bg-gray-100 border flex justify-center items-center hover:scale-90 duration-300 cursor-pointer border-gray-300 shadow-md sm:h-24 h-20 w-[250px] rounded-md"
    >
      <Link to={props.link}>
        <h3 className="font-archivo text-2xl font-semibold ">{props.title}</h3>
      </Link>
    </motion.div>
  );
};

export default ChooseYourTest;
