import React, { useState, useEffect, useRef } from "react";
import { ResizableBox } from "react-resizable";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import { ImSpellCheck } from "react-icons/im";
import { motion } from "framer-motion";
import 'katex/dist/katex.min.css';
import { IoExpand } from "react-icons/io5";

const Question = ({ sat, handleAnswerValueChange, handleCrossOutOptions, handleSelectOption, handleBookMark, currentQuestion }) => {
    const boxItemLeftRef = useRef();
    const [showCrossOutMenu, setShowCrossOutMenu] = useState(false);
    const [crossedOutOptions, setCrossedOutOptions] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isKatexRendered, setIsKatexRendered] = useState(false);

    // State for pane widths
    const [leftWidth, setLeftWidth] = useState(50); // Start with 50% width
    const [isResizing, setIsResizing] = useState(false);

    // Refs
    const dividerRef = useRef(null);

    // Event Handlers for Resizing
    const onMouseDown = (e) => {
        setIsResizing(true);
    };

    const onMouseMove = (e) => {
        if (!isResizing) return;
        const containerWidth = dividerRef.current.parentNode.getBoundingClientRect().width;
        const newLeftWidth = (e.clientX / containerWidth) * 100;
        if (newLeftWidth > 10 && newLeftWidth < 90) {
            setLeftWidth(newLeftWidth);
        }
    };

    const onMouseUp = () => {
        setIsResizing(false);
    };

    // Add event listeners
    useEffect(() => {
        if (isResizing) {
            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);
            document.body.style.userSelect = "none"; // Prevent text selection
        } else {
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
            document.body.style.userSelect = "auto";
        }

        // Cleanup
        return () => {
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
            document.body.style.userSelect = "auto";
        };
    }, [isResizing]);

    useEffect(() => {
        const script2 = document.createElement('script');
        script2.src = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js';
        script2.integrity = 'sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05';
        script2.crossOrigin = 'anonymous';

        script2.onload = () => {
            if (boxItemLeftRef.current) {
                const options = {
                    delimiters: [
                        { left: '$$', right: '$$', display: false },
                        { left: "$", right: "$", display: false },
                        { left: '\\(', right: '\\)', display: false },
                        { left: '\\[', right: '\\]', display: true },
                        { left: '\\begin{array}', right: '\\end{array}', display: true }
                    ],
                    throwOnError: false,
                };
                window.renderMathInElement(boxItemLeftRef.current, options);
                setIsKatexRendered(true);
            }
        };
        document.body.appendChild(script2);
        return () => {
            document.body.removeChild(script2);
        };
    }, [currentQuestion]);

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    //console.log("=currentQuestion", currentQuestion);
    return (<div id="box-item-left" ref={boxItemLeftRef}>
        {currentQuestion.a !== null && currentQuestion.a !== "null" ? (
            <div className="mt-[20px] flex h-full">
                {/* Left Pane */}
                <div
                    className="left-pane"
                    style={{ width: `${leftWidth}%` }}
                >
                    <div className="mb-[160px] mt-[3%] z-[10]">
                        <div
                            className={`flex flex-col justify-start ml-2 mr-2 box-item-left`}
                        // Removed id and used class
                        >
                            <div>
                                {sat === "Math" ? (
                                    <div
                                        id="test-imgsat"
                                        dangerouslySetInnerHTML={{
                                            __html: currentQuestion.stem,
                                        }}
                                        className="min-h-[40%] overflow-y-auto overflow-x-hidden my-list-style stem-content"
                                    />
                                ) : (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: currentQuestion.stimulus,
                                        }}
                                        className="min-h-[40%] overflow-y-auto overflow-x-hidden my-list-style stem-content"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Divider */}
                <div
                    ref={dividerRef}
                    className="divider mt-[20px] h-[800px] bg-gray-300 cursor-col-resize"
                    onMouseDown={onMouseDown}
                />

                {/* Right Pane */}
                <div
                    className="right-pane ml-[15px] mr-[15px]"
                    style={{ width: `${100 - leftWidth}%` }}
                >
                    <div className="flex flex-row justify-between bg-gray-100">
                        <div className="flex flex-row gap-2">
                            <div className="flex flex-row items-center">
                                <p className="py-2 px-3 bg-black text-white">
                                    {currentQuestion.index + 1}
                                </p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <motion.div
                                    whileTap={{ scale: 0.97 }}
                                    onClick={() =>
                                        handleBookMark(currentQuestion.questionIds)
                                    }
                                    className="m-1 cursor-pointer"
                                >
                                    {currentQuestion.bookMarked ? (
                                        <BsBookmarkFill color="red" size="1.2rem" />
                                    ) : (
                                        <BsBookmark size="1.2rem" />
                                    )}
                                </motion.div>
                                <div>
                                    <p>Mark For Review</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <motion.div
                                whileTap={{ scale: 0.98 }}
                                className={`p-[5px] border-black border-[1px] rounded-md cursor-pointer ${showCrossOutMenu && "bg-blue-700 text-white"
                                    }`}
                                onClick={() => setShowCrossOutMenu((pre) => !pre)}
                            >
                                <ImSpellCheck />
                            </motion.div>
                        </div>
                    </div>

                    <div className="my-2 min-w-[100px] overflow-y-auto">

                        <div
                            className="bg-white"
                        >
                            <Option
                                handleCrossOutOptions={handleCrossOutOptions}
                                currentQuestion={currentQuestion}
                                handleSelectOption={handleSelectOption}
                                idx={0}
                                showCrossOutMenu={showCrossOutMenu}
                                crossedOutOptions={crossedOutOptions}
                                data={currentQuestion.a}
                            />
                            <Option
                                handleCrossOutOptions={handleCrossOutOptions}
                                currentQuestion={currentQuestion}
                                handleSelectOption={handleSelectOption}
                                idx={1}
                                showCrossOutMenu={showCrossOutMenu}
                                crossedOutOptions={crossedOutOptions}
                                data={currentQuestion.b}
                            />
                            <Option
                                handleCrossOutOptions={handleCrossOutOptions}
                                currentQuestion={currentQuestion}
                                handleSelectOption={handleSelectOption}
                                idx={2}
                                showCrossOutMenu={showCrossOutMenu}
                                crossedOutOptions={crossedOutOptions}
                                data={currentQuestion.c}
                            />
                            <Option
                                handleCrossOutOptions={handleCrossOutOptions}
                                currentQuestion={currentQuestion}
                                handleSelectOption={handleSelectOption}
                                idx={3}
                                showCrossOutMenu={showCrossOutMenu}
                                crossedOutOptions={crossedOutOptions}
                                data={currentQuestion.d}
                            />
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="mt-[20px] h-full ml-[20%] mr-[20%]">
                {/* Right Pane */}
                <div>
                    <div className="flex flex-row justify-between bg-gray-100">
                        <div className="flex flex-row gap-2">
                            <div className="flex flex-row items-center">
                                <p className="py-2 px-3 bg-black text-white">
                                    {currentQuestion.index + 1}
                                </p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                                <motion.div
                                    whileTap={{ scale: 0.97 }}
                                    onClick={() =>
                                        handleBookMark(currentQuestion.questionIds)
                                    }
                                    className="m-1 cursor-pointer"
                                >
                                    {currentQuestion.bookMarked ? (
                                        <BsBookmarkFill color="red" size="1.2rem" />
                                    ) : (
                                        <BsBookmark size="1.2rem" />
                                    )}
                                </motion.div>
                                <div>
                                    <p>Mark For Review</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center">
                            <motion.div
                                whileTap={{ scale: 0.98 }}
                                className={`p-[5px] border-black border-[1px] rounded-md cursor-pointer ${showCrossOutMenu && "bg-blue-700 text-white"
                                    }`}
                                onClick={() => setShowCrossOutMenu((pre) => !pre)}
                            >
                                <ImSpellCheck />
                            </motion.div>
                        </div>
                    </div>

                    <div
                        id="test-imgsat"
                        className="mt-[20px]"
                        dangerouslySetInnerHTML={{ __html: currentQuestion.stem }}
                    />

                    <input
                        className="mt-[20px] w-[100%] border-[1px] py-1 px-2 border-gray-300 outline-none rounded-md"
                        type="text"
                        value={currentQuestion.selectedOption || ""}
                        onChange={(e) => handleAnswerValueChange(currentQuestion.questionIds, e.target.value)}
                    />
                </div>
            </div>
        )}
    </div>)
}
export default Question;


const Option = ({ handleCrossOutOptions, currentQuestion, idx, handleSelectOption, showCrossOutMenu, crossedOutOptions, data }) => {
    return (
        <div className="flex flex-row gap-1">
            <motion.div whileTap={{ scale: 0.98 }}
                className={`flex flex-row w-full hover:bg-gray-200 ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-[2px]" : "border-[1px]"} rounded-md ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative ${currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) && "before:absolute before:bg-black before:w-[103%] before:h-[2px] before:top-[50%] before:-left-[6px]"}`}
                onClick={() => handleSelectOption(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>
                <div className="flex flex-row items-center">
                    <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
                        {getOptionLetter(idx)}
                    </p>
                </div>
                <div dangerouslySetInnerHTML={{ __html: data }} />

            </motion.div>
            {showCrossOutMenu &&
                <motion.div className="flex flex-row items-center" whileTap={{ scale: 0.97 }}>
                    <div className="relative">
                        {currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) ?
                            (<p className="cursor-pointer underline" onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>Undo</p>) :

                            <>
                                <p onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())} className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full cursor-pointer">
                                    {getOptionLetter(idx)}
                                </p>
                                <div className="absolute w-6 h-[1.5px] bg-black top-3 cursor-pointer" onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>
                                </div>
                            </>

                        }


                    </div>

                </motion.div>
            }
        </div>
    )
}

function getOptionLetter(idx) {
    switch (idx) {
        case 0:
            return 'A'
            break;
        case 1:
            return 'B'
            break;
        case 2:
            return 'C'
            break;
        case 3:
            return 'D'
            break;
        default:
            return "A"
    }
}