import { useLocation } from 'react-router-dom';
import DevHero from "./devHero";
import Brands from "./Brands";
import Choose from "./Choose";
import ChooseYourTest from "./ChooseYourTest";
import Faq from "./Faq";
import Hero from "./Hero";
import Study from "./Study";
import Testinomials from "./Testinomials";
import Why from "./Why";
import AiEducationComponent from "./AiEducation";
const Home = () => {
  const location = useLocation();
  const isDev = location.pathname.endsWith('/dev');

  return (
    <>
      {isDev ? <DevHero /> : <Hero />}
      <ChooseYourTest />
      <Why />
      <AiEducationComponent /> 
      <Study />
      <Faq />
      <Testinomials />
    </>
  );
};

export default Home;