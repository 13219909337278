import React, { useEffect, useRef, useState } from 'react';
import {
  AiOutlineClose,
  AiOutlinePlayCircle,
  AiOutlinePauseCircle,
} from 'react-icons/ai';
import { MdOutlineCleaningServices } from 'react-icons/md';
import { fabric } from 'fabric';
import axios from "axios";
import { BASELINE } from '../../util';
import { v4 as uuidv4 } from 'uuid';
import { FaRegSave } from "react-icons/fa";
import { MdOutlineCloudUpload } from "react-icons/md";

const FabricJSCanvas = ({ shareuserid, currentQuestion, test_id, user, onClose, screenshotData }) => {
  const canvasRef = useRef(null);
  const rewindTimeRef = useRef(0);
  const playbackIntervalRef = useRef(null);
  const [objectTimeline, setObjectTimeline] = useState([]);
  const [rewindTime, setRewindTime] = useState(0);
  const [canvasInstance, setCanvasInstance] = useState(null);
  const [isDrawingComplete, setIsDrawingComplete] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false); // Track play/stop state
  const [wid, setwid] = useState(uuidv4());
  const [startTimestamp, setStartTimestamp] = useState(null);
  const [shared, setshared] = useState("");
  const [owner, setowner] = useState("");
  const [com, setcom] = useState("");
  const [continues, setcontinues] = useState(true);
  const [block, setblock] = useState(false);
  const [hasObjects, setHasObjects] = useState(false);

  // Store the original canvas dimensions
  const originalDimensions = useRef({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const resetBackgroundImage = () => {
    if (screenshotData) {
      fabric.Image.fromURL(screenshotData, (img) => {
        canvasInstance.setBackgroundImage(img, canvasInstance.renderAll.bind(canvasInstance), {
          scaleX: canvasInstance.width / img.width,
          scaleY: canvasInstance.height / img.height,
        });
      }, { crossOrigin: 'anonymous' });
    }
  };

  useEffect(() => {
    // Function to fetch and update whiteboard data
    const fetchAndUpdateWhiteboard = async () => {
      try {
        const response = await axios.post(BASELINE + 'whiteboard/search/by/id', { id: shareuserid });
        const main = response.data.data[0];

        if (main) {
          const whiteboard_db = JSON.parse(main.whiteboard);
          const objectTimelineData = JSON.parse(main.objectTimeline);

          // Clear the current canvas
          canvasInstance.clear();
          resetBackgroundImage();
          if (screenshotData) {
            fabric.Image.fromURL(screenshotData, (img) => {
              canvasInstance.setBackgroundImage(img, canvasInstance.renderAll.bind(canvasInstance), {
                scaleX: canvasInstance.width / img.width,
                scaleY: canvasInstance.height / img.height,
              });
            }, { crossOrigin: 'anonymous' });
          }

          // Load the new whiteboard JSON
          canvasInstance.loadFromJSON(whiteboard_db, () => {
            canvasInstance.forEachObject((object) => {
              object.set({
                selectable: false,  // Disable object selection
                evented: false      // Disable mouse events for objects
              });
            });

            // Reconstruct and update the object timeline using the extracted data
            const timeline = extractObjectTimeline(objectTimelineData);
            setObjectTimeline(timeline);
            canvasInstance.renderAll();
          });

          setshared(main.shared);
          setowner(main.user_id);
          setcom(main.stat);
        }
      } catch (error) {
        console.error('Error fetching whiteboard data:', error);
      }
    };

    // Check if the whiteboard is shared and the user is not the owner
    if (com === "writing" && block === true) {
      console.log("== right")
      // Initial fetch to ensure the canvas is up-to-date
      fetchAndUpdateWhiteboard();

      // Set up the interval to fetch data every second (1000ms)
      const intervalId = setInterval(fetchAndUpdateWhiteboard, 1000);

      // Cleanup function to clear the interval when conditions change
      return () => clearInterval(intervalId);
    }
  }, [shared, block, shareuserid, canvasInstance]);

  useEffect(() => {
    serializeObjectTimeline();
    sendUpdateData();
  }, [objectTimeline]);

  const extractObjectTimeline = (data) => {
    if (!Array.isArray(data)) {
      console.error('Invalid whiteboard data:', data);
      return [];
    }

    const timeline = [];
    const firstTimestamp = data.length > 0 ? data[0].timestamp : 0;

    data.forEach(({ object: objData, timestamp }) => {
      fabric.util.enlivenObjects([objData], (enlivenedObjects) => {
        enlivenedObjects.forEach((enlivenedObject) => {
          timeline.push({ object: enlivenedObject, timestamp: timestamp - firstTimestamp });
        });
      });
    });

    return timeline;
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axios.post(BASELINE + 'whiteboard/search/by/id', { id: shareuserid });
        const main = response.data.data[0];

        if (canvasInstance) {
          setcontinues(false);
          canvasInstance.clear();
          resetBackgroundImage();
          const whiteboard_db = JSON.parse(main.whiteboard);
          const objectTimelineData = JSON.parse(main.objectTimeline);

          canvasInstance.loadFromJSON(whiteboard_db, () => {
            canvasInstance.forEachObject((object) => {
              object.set({
                selectable: false,  // Disable object selection
                evented: false      // Disable mouse events for objects
              });
            });

            // Reconstruct and update the object timeline using the extracted data
            const timeline = extractObjectTimeline(objectTimelineData);
            setObjectTimeline(timeline);
          });

          setshared(main.shared);
          setowner(main.user_id);
          setcom(main.stat);
          if (main.shared === "true" || main.stat === "complete") {
            if (main.user_id !== user.u_id) { setblock(true); }
            setIsDrawingComplete(true);
            canvasInstance.isDrawingMode = false;  // Disable drawing mode
            canvasInstance.selection = false;      // Disable group selection
            canvasInstance.hoverCursor = 'default'; // Set cursor back to default
            canvasInstance.off('object:added');    // Remove listener for adding objects
            canvasInstance.off('object:modified'); // Remove listener for modifying objects
          } else {
            if (main.user_id === user.u_id) {
              setIsDrawingComplete(false);
              canvasInstance.isDrawingMode = true;  // Disable drawing mode
              canvasInstance.selection = true;      // Disable group selection
              canvasInstance.on('object:added');    // Remove listener for adding objects
              canvasInstance.on('object:modified'); // Remove listener for modifying objects
            } else {
              //here render every second
              if (main.user_id !== user.u_id) { setblock(true); }
              setIsDrawingComplete(true);
              canvasInstance.isDrawingMode = false;  // Disable drawing mode
              canvasInstance.selection = false;      // Disable group selection
              canvasInstance.hoverCursor = 'default'; // Set cursor back to default
              canvasInstance.off('object:added');    // Remove listener for adding objects
              canvasInstance.off('object:modified'); // Remove listener for modifying objects
            }
          }

          // Load the whiteboard from parsed JSON and then configure the canvas


          canvasInstance.renderAll();
          setcontinues(true);
        }

      } catch (error) {
        console.error('Error sending initial data:', error);
      }
    };

    if (shareuserid) {
      setwid(shareuserid);
      fetchInitialData();
    }
  }, [shareuserid, canvasInstance]);

  const serializeObjectTimeline = () => {
    return objectTimeline.map(({ object, timestamp }) => ({
      object: object.toObject(),
      timestamp,
    }));
  };

  const sendInitialData = async () => {
    if (!canvasInstance) return; // Add this check
    if (shareuserid) return;
    if (block) return;
    const canvasJSON = canvasInstance?.toJSON();

    const initialData = {
      id: wid,
      created_date: new Date().toISOString().split('T')[0],
      user_id: user.u_id,
      test_id: test_id,
      question_id: currentQuestion.questionIds,
      whiteboard: JSON.stringify(canvasJSON),
      objectTimeline: "",
      shared: "false",
      type: currentQuestion.programs,
      stat: "writing",
    };

    try {
      const response = await axios.post(BASELINE + 'whiteboard/insert', initialData);
      console.log('Initial data sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending initial data:', error);
    }
  };

  const sendUpdateData = async () => {
    if (block) return;

    if (canvasInstance && continues === true && shared !== "true") {
      const serializedTimeline = serializeObjectTimeline();
      const canvasJSON = canvasInstance.toJSON();

      var updateData;
      if (com !== "complete") {
        updateData = {
          id: wid,
          created_date: new Date().toISOString().split('T')[0],
          user_id: user.u_id,
          test_id: test_id,
          question_id: currentQuestion.questionIds,
          whiteboard: JSON.stringify(canvasJSON),
          objectTimeline: JSON.stringify(serializedTimeline),
          shared: "false",
          type: currentQuestion.programs,
          stat: "writing",
        };
      } else {
        updateData = {
          id: wid,
          created_date: new Date().toISOString().split('T')[0],
          user_id: user.u_id,
          test_id: test_id,
          question_id: currentQuestion.questionIds,
          whiteboard: JSON.stringify(canvasJSON),
          objectTimeline: JSON.stringify(serializedTimeline),
          shared: "false",
          type: currentQuestion.programs,
          stat: "complete",
        };
      }

      try {
        const response = await axios.post(BASELINE + 'whiteboard/insert', updateData);
        console.log('Whiteboard updated successfully:', response.data);
      } catch (error) {
        console.error('Error updating whiteboard:', error);
      }
    }
  };

  const sendSavedData = async () => {
    if (block) return;

    const serializedTimeline = serializeObjectTimeline();
    const canvasJSON = canvasInstance.toJSON();

    const updateData = {
      id: wid,
      created_date: new Date().toISOString().split('T')[0],
      user_id: user.u_id,
      test_id: test_id,
      question_id: currentQuestion.questionIds,
      whiteboard: JSON.stringify(canvasJSON),
      objectTimeline: JSON.stringify(serializedTimeline),
      shared: "false",
      type: currentQuestion.programs,
      stat: "complete",
    };

    try {
      const response = await axios.post(BASELINE + 'whiteboard/insert', updateData);
      console.log('Whiteboard updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating whiteboard:', error);
    }
  };

  const handleCloudClick = async () => {
    if (block) return;

    // Prompt the user for confirmation
    const confirmShare = window.confirm(
      "By sharing this note, it will be made public and accessible to everyone. Do you want to proceed?"
    );

    if (!confirmShare) {
      // If the user cancels, do nothing
      return;
    }

    // Proceed with sharing the note
    const updateData = {
      id: wid,
      created_date: new Date().toISOString().split('T')[0],
      user_id: user.u_id,
      shared: "true",
      cloud: true
    };

    try {
      const response = await axios.post(BASELINE + 'whiteboard/insert', updateData);
      console.log('Whiteboard updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating whiteboard:', error);
    }
  };


  // Initialize canvas and setup resize handling
  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current, {
      isDrawingMode: true,
      height: originalDimensions.current.height,
      width: originalDimensions.current.width,
    });

    setCanvasInstance(canvas);

    const updateHasObjects = () => {
      if (canvas) {
        setHasObjects(canvas.getObjects().length > 0);
      }
    };

    const handleObjectAdded = (e) => {
      if (!isDrawingComplete) {
        const timestamp = Date.now();
        if (startTimestamp === null) {
          setStartTimestamp(timestamp);
        }
        const relativeTimestamp = timestamp - startTimestamp;

        const object = fabric.util.object.clone(e.target);
        setObjectTimeline((prev) => [...prev, { object, timestamp: relativeTimestamp }]);
        if (!shareuserid) {
          if (canvasInstance) { sendUpdateData(); }
        }
      }
    };

    const handleObjectModified = (e) => {
      if (!isDrawingComplete) {
        const timestamp = Date.now();
        if (startTimestamp === null) {
          setStartTimestamp(timestamp);
        }
        const relativeTimestamp = timestamp - startTimestamp;

        const object = fabric.util.object.clone(e.target);
        setObjectTimeline((prev) => [...prev, { object, timestamp: relativeTimestamp }]);
        if (!shareuserid) {
          if (canvasInstance) { sendUpdateData(); }
        }
      }
    };


    canvas.on('object:added', handleObjectAdded);
    canvas.on('object:modified', handleObjectModified);

    canvas.on('object:added', updateHasObjects);
    canvas.on('object:removed', updateHasObjects);

    updateHasObjects();

    const resizeCanvas = () => {
      const { width: originalWidth, height: originalHeight } = originalDimensions.current;

      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      const scaleX = newWidth / originalWidth;
      const scaleY = newHeight / originalHeight;
      const scale = Math.min(scaleX, scaleY);

      // Update the canvas dimensions
      canvas.setWidth(newWidth);
      canvas.setHeight(newHeight);

      // Apply scaling using viewportTransform
      canvas.setZoom(scale);

      // Center the canvas content
      canvas.viewportTransform[4] = (newWidth - originalWidth * scale) / 2;
      canvas.viewportTransform[5] = (newHeight - originalHeight * scale) / 2;

      canvas.renderAll();
    };

    window.addEventListener('resize', resizeCanvas);
    if (!shareuserid) {
      sendInitialData();
    }
    return () => {
      // Cleanup when component unmounts
      if (playbackIntervalRef.current) {
        clearInterval(playbackIntervalRef.current);
        playbackIntervalRef.current = null;
      }
      window.removeEventListener('resize', resizeCanvas);
      if (canvasInstance) {
        canvasInstance.off('object:added', handleObjectAdded);
        canvasInstance.off('object:modified', handleObjectModified);
        canvasInstance.off('object:added', updateHasObjects);
        canvasInstance.off('object:removed', updateHasObjects);
        canvasInstance.dispose();
      }
    };
  }, []);

  useEffect(() => {
    const setBackground = async () => {
      if (screenshotData && canvasInstance) {
        try {
          const img = await new Promise((resolve, reject) => {
            fabric.Image.fromURL(screenshotData, (img) => {
              resolve(img);
            }, { crossOrigin: 'anonymous' });
          });

          canvasInstance.setBackgroundImage(img, canvasInstance.renderAll.bind(canvasInstance), {
            scaleX: canvasInstance.width / img.width,
            scaleY: canvasInstance.height / img.height,
          });
        } catch (error) {
          console.error('Error loading image:', error);
        }
      }
    };

    setBackground();
  }, [screenshotData, canvasInstance]);

  const handlePlayPause = () => {
    if (isPlaying) {
      if (playbackIntervalRef.current) {
        clearInterval(playbackIntervalRef.current);
        playbackIntervalRef.current = null;
      }
      setIsPlaying(false);
    } else {
      let startTime = rewindTime;

      const lastTimestamp = objectTimeline.length ? objectTimeline[objectTimeline.length - 1].timestamp : 0;

      if (rewindTime >= lastTimestamp) {
        startTime = 0; // Start from the beginning
        setRewindTime(0);
      }

      // Prepare the canvas for playback
      canvasInstance.clear();
      resetBackgroundImage();

      // Reset background image after clearing
      if (screenshotData) {
        fabric.Image.fromURL(screenshotData, (img) => {
          canvasInstance.setBackgroundImage(img, canvasInstance.renderAll.bind(canvasInstance), {
            scaleX: canvasInstance.width / img.width,
            scaleY: canvasInstance.height / img.height,
          });
        }, { crossOrigin: 'anonymous' });
      }

      objectTimeline.forEach(({ object, timestamp }) => {
        if (timestamp <= startTime) {
          canvasInstance.add(object);
        }
      });
      canvasInstance.renderAll();

      playObjects(startTime);
      setIsPlaying(true);
    }
  };

  const playObjects = (startTime) => {
    let index = objectTimeline.findIndex(({ timestamp }) => timestamp >= startTime);

    const interval = setInterval(() => {
      if (index >= objectTimeline.length) {
        clearInterval(interval);
        playbackIntervalRef.current = null;
        setIsPlaying(false);
        setRewindTime(objectTimeline[objectTimeline.length - 1].timestamp);
      } else {
        const { object, timestamp } = objectTimeline[index];
        if (canvasInstance) { // Add null check here
          canvasInstance.add(object);
          canvasInstance.renderAll();
        }
        rewindTimeRef.current = timestamp;
        setRewindTime(timestamp);
        index++;
      }
    }, 500);

    playbackIntervalRef.current = interval; // Store the interval ID
  };

  const handleRewindChange = (event) => {
    const newRewindTime = parseInt(event.target.value, 10);
    setRewindTime(newRewindTime);
    rewindTimeRef.current = newRewindTime;

    // Update the canvas
    canvasInstance.clear();
    resetBackgroundImage();
    // Reset background image after clearing
    if (screenshotData) {
      fabric.Image.fromURL(screenshotData, (img) => {
        canvasInstance.setBackgroundImage(img, canvasInstance.renderAll.bind(canvasInstance), {
          scaleX: canvasInstance.width / img.width,
          scaleY: canvasInstance.height / img.height,
        });
      }, { crossOrigin: 'anonymous' });
    }

    objectTimeline.forEach(({ object, timestamp }) => {
      if (timestamp <= newRewindTime) {
        canvasInstance.add(object);
      }
    });
    canvasInstance.renderAll();
  };

  const handleDoneClick = () => {
    setIsDrawingComplete(true);
    if (canvasInstance) {
      canvasInstance.clear();
      resetBackgroundImage();
      sendSavedData();

      canvasInstance.isDrawingMode = false;
      canvasInstance.off('object:added');
      canvasInstance.off('object:modified');
      canvasInstance.selection = false; // Disable group selection

      // Make all objects non-selectable and non-movable
      canvasInstance.getObjects().forEach((object) => {
        object.selectable = false;
        object.evented = false;
      });

      canvasInstance.renderAll();
    }
  };

  const handleClearClick = () => {
    if (canvasInstance) {
      canvasInstance.clear();
      resetBackgroundImage();
      setObjectTimeline([]);
      setRewindTime(0);
      setIsDrawingComplete(false);
      canvasInstance.isDrawingMode = true; // Allow drawing again after clearing
      canvasInstance.selection = true; // Re-enable selection

      // Reset original dimensions
      originalDimensions.current.width = window.innerWidth;
      originalDimensions.current.height = window.innerHeight;

      // Reset zoom and viewportTransform
      canvasInstance.setZoom(1);
      canvasInstance.viewportTransform = [1, 0, 0, 1, 0, 0];
    }
  };

  const handleCloseClick = () => onClose(false);

  return (
    <div
      className="fixed inset-0 flex"
      style={{ zIndex: 1000 }}
    >
      <div className="relative w-full h-full">
        <canvas
          ref={canvasRef}
          className="rounded-lg shadow-lg w-full h-full"
        />

        {(com === "writing" && block === true) ? (
          <></>
        ) : (
          <div className="absolute bottom-[90px] left-1/2 transform -translate-x-1/2 w-3/4 flex items-center space-x-4">
            <button
              onClick={handlePlayPause}
              disabled={!isDrawingComplete}
              className={`py-2 px-4 flex items-center space-x-2 rounded-lg ${isDrawingComplete
                ? 'bg-slate-500 hover:bg-slate-700 text-white'
                : 'bg-gray-400 text-gray-700'
                }`}
            >
              {isPlaying ? <AiOutlinePauseCircle /> : <AiOutlinePlayCircle />}
              <span>{isPlaying ? 'Stop' : 'Play'}</span>
            </button>

            <input
              type="range"
              min={0}
              max={objectTimeline.length ? objectTimeline[objectTimeline.length - 1].timestamp : 0}
              value={rewindTime}
              onChange={handleRewindChange}
              className="w-full"
              disabled={!isDrawingComplete}
            />
          </div>
        )}

        <div className="absolute top-4 right-4 flex space-x-4">
          {(!shareuserid || shared === "false") && (
            <>
              {shared !== "true" && owner === user.u_id && (
                <button
                  onClick={handleCloudClick}
                  disabled={!isDrawingComplete}
                  className={`py-2 px-4 flex items-center space-x-2 rounded-lg ${isDrawingComplete
                    ? 'bg-blue-400 hover:bg-blue-600 text-white'
                    : 'bg-gray-400'
                    }`}
                >
                  <MdOutlineCloudUpload />
                </button>
              )}

              {com !== "complete" && isDrawingComplete === false && (
                <button
                  onClick={handleDoneClick}
                  disabled={isDrawingComplete || !hasObjects}
                  className={`py-2 px-4 flex items-center space-x-2 rounded-lg ${isDrawingComplete || !hasObjects
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-blue-500 hover:bg-blue-700 text-white'
                    }`}
                >
                  <FaRegSave />
                </button>
              )}

              {isDrawingComplete === false && (
                <button
                  onClick={handleClearClick}
                  className="py-2 px-4 flex items-center space-x-2 bg-red-500 hover:bg-red-700 text-white rounded-lg"
                >
                  <MdOutlineCleaningServices />
                </button>
              )}
            </>
          )}


          <button
            onClick={handleCloseClick}
            className="py-2 px-4 flex items-center space-x-2 bg-gray-500 hover:bg-gray-700 text-white rounded-lg"
          >
            <AiOutlineClose />
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FabricJSCanvas;
