import { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import { BASELINE, AFTER_LOGIN } from "../../util/index";
const AiEducationComponent = () => {

  const [users, serusers] = useState(0);
  const [classes, setclasses] = useState(0);
  const [worksheets, setworksheets] = useState(0);

  useEffect(() => {

    axios.post(BASELINE + 'get/info', {})
      .then(function (response) {

        serusers(response.data.number_of_users);
        setclasses(response.data.numberclass);
        setworksheets(response.data.number_test);

      })
      .catch(function (error) {
        alert("Something went wrong.");
      });

  }, []);

  return (
    <div className="relative text-white p-8 min-h-[800px]">
      <img
        src="space.png"
        alt="Background"
        className="absolute inset-0 w-full h-full sm:h-[800px] object-cover"
      />

      <div className="relative z-10">
        <header className="text-center">
          <h1 className="text-3xl font-bold mt-[40px] mb-[40px]">Sponsored by MMTPrep Education</h1>
        </header>

        <img src={"./mmt_logo.png"} alt={`slide2`} className="filter invert mx-auto mb-[80px]" />


        <div className="text-center mb-8">
          <p className="text-2xl mb-[40px]">We are moving forward with AI-powered Education</p>
          <p className="text-2xl font-bold">WE BELIEVE</p>
          <p className="text-4xl gradient-text font-bold mb-[80px]">AI can offer new horizons in education</p>
        </div>

        <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-8 mb-8">
          <div className="text-center">
            <p className="text-6xl font-bold">{users}</p>
            <p>Users</p>
            <p>are studying with built-in AI</p>
          </div>
          <div className="text-center">
            <p className="text-6xl font-bold">{classes}</p>
            <p>Classes</p>
            <p>are running under our service</p>
          </div>
          <div className="text-center">
            <p className="text-6xl font-bold">{worksheets}</p>
            <p>Tests</p>
            <p>have been completed</p>
          </div>
        </div>

        <div className="text-center">
          <a href="https://mmtprep.com/" target="_blank" rel="noopener noreferrer">
            <button className="bg-white text-black px-4 py-2 rounded transform transition duration-500 ease-in-out hover:scale-105 hover:shadow-lg">
              Visit MMTPrep for more info
            </button>
          </a>
        </div>

      </div>
      <div className="absolute inset-0 bg-black opacity-50 z-0"></div>
    </div>
  );
};

export default AiEducationComponent;
