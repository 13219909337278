import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASELINE } from "../../util";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { toast } from "react-hot-toast";

const DevHero = () => {

  async function getQuestions(event) {
    event.stopPropagation();

    try {
      var res;
      res = await axios.get(BASELINE + "sat/random", {});

      if (res.data.length < 1) {
        toast.error("Questions With these topics not found. Try other topics for now by going back")
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <section className="wrapper relative">
      <div className="contain">
        <img
          src="/hero.png"
          className="w-full object-cover min-h-[500px] rounded-2xl"
          alt=""
        />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-start items-center flex-col gap-8 text-center w-full">
          <h3 className="text-white font-archivo leading-[1.2] text-[26px] sm:text-[40px] md:text-[50px] lg:text-[60px] font-bold uppercase">
            Master SAT & ACT  <br />  with Confidence
          </h3>
          <p className="text-white font-archivo leading-[1.2] text-[20px] font-bold">
            Dive into our comprehensive problem bank designed to <br /> challenge, teach, and prepare you for success.
          </p>
          <div className="flex gap-4"> {/* Add this line */}
            <motion.div
              whileTap={{ scale: 0.97 }}
              className="text-white w-[190px] h-[60px] grid place-items-center rounded text-xl font-bold font-archivo"
              style={{
                background: 'linear-gradient(to right, #001F3F, #000f3f)'
              }}
            >
              <Link to="/devpsat/1">
                DEV PSAT
              </Link>
            </motion.div>

            <motion.div
              whileTap={{ scale: 0.97 }}
              className="text-white w-[190px] h-[60px] grid place-items-center rounded text-xl font-bold font-archivo"
              style={{
                background: 'linear-gradient(to right, #001F3F, #000f3f)'
              }}
            >
              <Link to="/devpsat89/1">
                DEV PSAT 8/9
              </Link>
            </motion.div>

            <motion.div
              whileTap={{ scale: 0.97 }}
              className="text-white w-[190px] h-[60px] grid place-items-center rounded text-xl font-bold font-archivo"
              style={{
                background: 'linear-gradient(to right, #001F3F, #000f3f)'
              }}
            >
              <Link to="/devpsat10/1">
                DEV PSAT 10
              </Link>
            </motion.div>

            <motion.div
              whileTap={{ scale: 0.97 }}
              className="text-white w-[190px] h-[60px] grid place-items-center rounded text-xl font-bold font-archivo"
              style={{
                background: 'linear-gradient(to right, #001F3F, #000f3f)'
              }}
            >
              <Link to="/devact/1">
                DEV ACT
              </Link>
            </motion.div>

            <motion.div
              whileTap={{ scale: 0.97 }}
              className="text-white w-[190px] h-[60px] grid place-items-center rounded text-xl font-bold font-archivo"
              style={{
                background: 'linear-gradient(to right, #001F3F, #000f3f)'
              }}
            >
              <Link to="/devapcalc/1">
                DEV AP.
              </Link>
            </motion.div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default DevHero;