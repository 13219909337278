import React, { useState, useContext, useEffect } from 'react';
import { FaTimes, FaBars } from "react-icons/fa";
import axios from "axios";
import Modal from 'react-modal'; // Import a modal component like react-modal
import { BASELINE } from "../../util";
import { toast } from "react-hot-toast";

const SDM = ({ handleHomeworkClick, activeIndex, setActiveIndex, user, onLogout, testType, setdashboard, setIsOpen }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false); // State to manage the modal
    const [isParentsModalOpen, setIsParentsModalOpen] = useState(false); // State to manage the parents modal
    const [couponCode, setCouponCode] = useState(""); // State for coupon code
    const [classid, setclassid] = useState("");
    const [isCouponValid, setIsCouponValid] = useState(null); // State for validation status
    const [isVerifying, setIsVerifying] = useState(false); // State to show loading while verifying
    const [parentData, setParentData] = useState([]); // State to store parent data
    const [loading, setLoading] = useState(false); // State to handle loading
    const [registerclass, setregisterclass] = useState(false); // State to manage the modal

    const validCouponCodes = ['MMTFREE3MONTHS']; // Replace with actual coupon codes

    const handleregisterclass = async () => {
        const data = {
            u_id: user.u_id,
            shortid: classid,
        };

        axios.post(BASELINE + 'register/class/by/student', data)
            .then(response => {
                toast.success("Class Registered!");
                setclassid("");
                setregisterclass(false);
                // Close the modal or perform other UI updates
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    // Specific handling for 400 status code
                    toast.error("Duplicate registration: You are already registered in this class.");
                } else {
                    // General error handling
                    toast.error("Error registering the class. Please try again.");
                }
            });
    };


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleDashboardChange = (index) => {
        setdashboard(index);
        setActiveIndex(index); // Set active menu item index
    };

    const handleVerifyCoupon = async () => {
        setIsVerifying(true); // Start verification loading

        // Simulate an API call to verify the coupon code
        setTimeout(() => {
            if (validCouponCodes.includes(couponCode)) {
                setIsCouponValid(true); // Coupon is valid
                handlePremiumActivation(); // Call function to send the data to the server
            } else {
                setIsCouponValid(false); // Coupon is invalid
            }
            setIsVerifying(false); // End verification loading
        }, 1000); // Simulated delay
    };

    const handlePremiumActivation = () => {
        const today = new Date();
        const startPremiumDate = today.toISOString().split('T')[0]; // Format as YYYY-MM-DD

        const endPremiumDate = new Date();
        endPremiumDate.setMonth(endPremiumDate.getMonth() + 3); // Add 3 months
        const endPremiumDateString = endPremiumDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD

        const premiumData = {
            u_id: user.u_id,
            start_date: startPremiumDate,
            end_date: endPremiumDateString
        };

        // Send the premium data to your API
        axios.post(BASELINE + 'premium/activate', premiumData)
            .then(response => {
                alert("Premium service activated!");
                setIsPremiumModalOpen(false); // Close the modal
                window.location.reload(); // Refresh the website
            })
            .catch(error => {
                console.error(error);
                alert("Error activating premium service.");
            });
    };

    // Function to fetch pending parents data
    const fetchPendingParents = async () => {
        setLoading(true); // Set loading to true while fetching
        try {
            const response = await axios.post(BASELINE + "check/pending", { uid: user.u_id });
            setParentData(response.data); // Update state with the response data
        } catch (error) {
            console.error("Error fetching parents data:", error);
        }
        setLoading(false); // Stop loading after the fetch
    };

    // This useEffect will trigger when the modal opens
    useEffect(() => {
        if (isParentsModalOpen) {
            fetchPendingParents(); // Fetch pending parents when the modal opens
        }
    }, [isParentsModalOpen]);

    // Handle approval logic
    const handleApprove = async (parentUId) => {
        try {

            // Send the approve request
            await axios.post(BASELINE + "send/approve", {
                u_id: user.u_id,  // Student's u_id (assuming you have access to user object)
                p_id: parentUId,  // Parent's u_id
                status: 'approve' // Status is "approve"
            });

            // Update the parentData to remove the approved parent
            setParentData(prevData => prevData.filter(parent => parent.u_id !== parentUId));

            // Show success message if the request is successful
            toast.success('Parent approved successfully!');
        } catch (error) {
            console.error('Error approving parent:', error);
            toast.error('Failed to approve the parent. Please try again.');
        }
    };

    // Handle denial logic
    const handleDeny = async (parentUId) => {
        try {

            // Send the deny request
            await axios.post(BASELINE + "send/approve", {
                u_id: user.u_id,  // Student's u_id (assuming you have access to user object)
                p_id: parentUId,  // Parent's u_id
                status: 'deny'    // Status is "deny"
            });

            // Update the parentData to remove the denied parent
            setParentData(prevData => prevData.filter(parent => parent.u_id !== parentUId));

            // Show success message if the request is successful
            toast.success('Parent denied successfully!');
        } catch (error) {
            console.error('Error denying parent:', error);
            toast.error('Failed to deny the parent. Please try again.');
        }
    };

    return (
        <div className="w-[250px] bg-white md:h-screen ml-0 rounded-lg md:rounded-r-lg mb-4 md:mb-0">
            <div className="flex justify-between items-center md:hidden">
                <h1 className="font-bold text-xl">Dashboard Menu</h1>
                <button onClick={toggleMenu}>
                    {isMenuOpen ? <FaTimes /> : <FaBars />}
                </button>
            </div>
            <ul className={`py-4 !text-black pl-4 ${isMenuOpen ? 'block' : 'hidden'} md:block`}>
                {user && (
                    <li className="font-bold text-xl md:text-xl">Student ID: {user.u_id}</li>
                )}
                <div className={user && user.premium ? " pl-2 pb-2 text-sm font-bold gradient-text" : " text-sm font-bold"}>
                    Membership: {user && user.premium ? "Premium" : "Free Trial"}
                </div>
                <li className="p-2 font-bold text-xl md:text-xl">My Home</li>

                {testType === "SAT" && (
                    <>
                        <li className={`p-2 cursor-pointer ${activeIndex === 0 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(0)}>Digital SAT View</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 1 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(1)}>Create Digital SAT</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 2 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(2)}>Ditigal SAT Scores</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 3 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(3)}>SAT Performance</li>
                        <li className={`hidden p-2 cursor-pointer ${activeIndex === 4 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(4)}>Resume Digital SAT</li>
                    </>
                )}

                {testType === "ACT" && (
                    <>
                        <li className={`p-2 cursor-pointer ${activeIndex === 5 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(5)}>Digital ACT View</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 6 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(6)}>Create Digital ACT</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 7 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(7)}>Ditigal ACT Scores</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 8 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(8)}>ACT Performance</li>
                    </>
                )}

                {testType === "NMSQT" && (
                    <>
                        <li className={`p-2 cursor-pointer ${activeIndex === 9 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(9)}>Digital NMSQT View</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 10 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(10)}>Create Digital NMSQT</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 11 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(11)}>Ditigal NMSQT Scores</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 12 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(12)}>NMSQT Performance</li>
                    </>
                )}
                {testType === "PSAT89" && (
                    <>
                        <li className={`p-2 cursor-pointer ${activeIndex === 13 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(13)}>Digital PSAT 8/9 View</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 14 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(14)}>Create Digital PSAT 8/9</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 15 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(15)}>Ditigal PSAT 8/9 Scores</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 16 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(16)}>PSAT 8/9 Performance</li>
                    </>
                )}

                <li className="pl-2 pt-6 font-bold text-xl md:text-xl">My Class</li>
                <li className={`p-2 cursor pointer" ${activeIndex === 97 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => { setregisterclass(true); }}>Register Class</li>
                <li className={`p-2 cursor pointer" ${activeIndex === 99 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => { handleDashboardChange(99); handleHomeworkClick(); }}>Homework</li>

                <li className={`mt-[300px] p-2 cursor-pointer ${activeIndex === 98 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => setIsParentsModalOpen(true)}>Parents Request</li> {/* Opens Parents Modal */}

                {user && user.premium ? (
                    <li className="p-2 cursor-pointer" onClick={() => setIsOpen(true)}>Password Reset</li>
                ) : (
                    <>
                        <li className="p-2 cursor-pointer gradient-text font-bold" onClick={() => setIsPremiumModalOpen(true)}>Go Premium</li>
                        <li className={`p-2 cursor-pointer ${activeIndex === 6 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => handleDashboardChange(6)}>Password Reset</li>
                    </>
                )}
                <li className={`p-2 cursor-pointer ${activeIndex === 100 ? 'bg-gray-500 text-white' : 'hover:bg-gray-300'}`} onClick={() => onLogout()}>Logout</li>
            </ul>

            <Modal
                isOpen={registerclass}
                onRequestClose={() => setregisterclass(false)}
                contentLabel="Go Premium Modal"
                className="absolute bg-white p-6 rounded-lg shadow-md max-w-lg mx-auto mt-12"
                overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
            >
                <h2 className="text-lg font-bold mb-4">Register Class</h2>
                <p>Enter the Class Code from the Teacher Dashboard:</p>
                <div className="mt-4">
                    <input
                        type="text"
                        value={classid}
                        onChange={(e) => setclassid(e.target.value)}
                        className="border p-2 rounded w-full"
                        placeholder="Enter Class Code"
                    />
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        onClick={handleregisterclass}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Register
                    </button>
                    <button
                        onClick={() => { setregisterclass(false) }}
                        className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Cancel
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={isPremiumModalOpen}
                onRequestClose={() => setIsPremiumModalOpen(false)}
                contentLabel="Go Premium Modal"
                className="absolute bg-white p-6 rounded-lg shadow-md max-w-lg mx-auto mt-12"
                overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
            >
                <h2 className="text-lg font-bold mb-4">Go Premium</h2>
                <p>Enter your coupon code to activate premium service:</p>
                <div className="mt-4">
                    <input
                        type="text"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        className="border p-2 rounded w-full"
                        placeholder="Enter Coupon Code"
                    />
                </div>
                <div className="mt-2 text-sm text-gray-600">
                    <p>Need a code? Contact <a href="mailto:angiek@mmtprep.com" className="text-blue-500">angiek@mmtprep.com</a> for assistance.</p>
                </div>
                {isCouponValid !== null && (
                    <div className={`mt-2 ${isCouponValid ? 'text-green-500' : 'text-red-500'}`}>
                        {isCouponValid ? 'Coupon code is valid!' : 'Invalid coupon code.'}
                    </div>
                )}
                <div className="flex justify-end mt-4">
                    <button
                        onClick={handleVerifyCoupon}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        disabled={isVerifying}
                    >
                        {isVerifying ? 'Verifying...' : 'Verify Coupon'}
                    </button>
                    <button
                        onClick={() => setIsPremiumModalOpen(false)}
                        className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Cancel
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={isParentsModalOpen}
                onRequestClose={() => setIsParentsModalOpen(false)}
                contentLabel="Check Parents Modal"
                className="absolute bg-white p-6 rounded-lg shadow-md max-w-lg mx-auto mt-12 relative"
                overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
            >
                <button
                    onClick={() => setIsParentsModalOpen(false)}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                    <FaTimes size={20} />
                </button>
                <h2 className="text-lg font-bold mb-4">Check Parents</h2>

                {/* Display loading while fetching data */}
                {loading ? (
                    <p>Loading...</p>
                ) : parentData.length === 0 ? (
                    <p>No pending parents found.</p>
                ) : (
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 text-left">Full Name</th>
                                <th className="py-2 px-4 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {parentData.map((parent, index) => (
                                <tr key={index}>
                                    <td className="py-2 px-4">
                                        {parent.first_name} {parent.last_name}
                                    </td>
                                    <td className="py-2 px-4">
                                        <button
                                            onClick={() => handleApprove(parent.u_id)}
                                            className="bg-green-500 text-white py-1 px-3 rounded hover:bg-green-600"
                                        >
                                            Approve
                                        </button>

                                        <button
                                            onClick={() => handleDeny(parent.u_id)}
                                            className="bg-red-500 ml-2 text-white py-1 px-3 rounded hover:bg-red-600"
                                        >
                                            Deny
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </Modal>
        </div>
    );
};

export default SDM;
