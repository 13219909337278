import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASELINE } from "../../util";
import { CiFlag1 } from "react-icons/ci";
import { FaFlag } from "react-icons/fa";

const NavBar = ({
    index,
    questions,
    code,
    topics,
    currentQuestion,
    testIdOptions,
    handleUpdate,
    qidOptions,
    handleTestIdChange,
    handleNext,
    handleBack,
    handleOptionClick,
    handleNext2,
    handleBack2,
    handleflag
}) => {
    const navigate = useNavigate();
    const [selectedTestId, setselectedTestId] = useState("");
    const [subject, setSubject] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedHeader, setSelectedHeader] = useState(null);
    const [qid, setQid] = useState(qidOptions);
    const [flag, setflag] = useState(null);

    useEffect(() => {
        if (currentQuestion && currentQuestion.flag !== flag) {
            setflag(currentQuestion.flag);
        }
    }, [currentQuestion, flag]);


    const fetchTestName = async (q_id) => {
        try {
            let res = await axios.get(BASELINE + "get/apcalc/testname", {
                params: {
                    q_id: q_id,
                },
            });
            setSubject(res.data); // Set subject data to state
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchTestName({ exam: "AP Art History 2008" }); // Initial fetch with default exam
    }, []);

    useEffect(() => {
        if (selectedTestId) {
            fetchTestName(selectedTestId);
        }
    }, [selectedTestId]);

    // Function to find the closest exam based on qid
    const findClosestExam = (qid) => {
        let closest = testIdOptions[0];
        for (let i = 0; i < testIdOptions.length; i++) {
            if (testIdOptions[i].question_id <= qid) {
                closest = testIdOptions[i];
            } else {
                break;
            }
        }
        return closest;
    };

    const handleQidChange = (e) => {
        const newQid = parseInt(e.target.value, 10);
        setQid(newQid);

        const closestExam = findClosestExam(newQid);
        setselectedTestId(closestExam);
        handleTestIdChange(closestExam.exam);
        navigate(`/devapcalc/${newQid}`);
    };

    const handleNextQuestion = () => {
        const newQid = parseInt(qid, 10) + 1;
        setQid(newQid);

        const closestExam = findClosestExam(newQid);
        setselectedTestId(closestExam);
        handleTestIdChange(closestExam.exam);
        navigate(`/devapcalc/${newQid}`);
    };

    const handlePreviousQuestion = () => {
        const newQid = parseInt(qid, 10) - 1;
        setQid(newQid);

        const closestExam = findClosestExam(newQid);
        setselectedTestId(closestExam);
        handleTestIdChange(closestExam.exam);
        navigate(`/devapcalc/${newQid}`);
    };

    const handleExamChange = (e) => {
        const selectedTest = testIdOptions[e.target.selectedIndex];
        setselectedTestId(selectedTest);
        setQid(selectedTest.question_id); // Update qid based on selected exam's question_id
        handleTestIdChange(selectedTest.exam);
        navigate(`/devapcalc/${selectedTest.question_id}`);
    };

    return (
        <nav className="bg-slate-700 text-white p-4 flex justify-between items-center relative">
            <div className="flex gap-4">
                {code === false && (
                    <>
                        <div>
                            <label htmlFor="testId" className="mr-2">
                                Exam ID:
                            </label>
                            <select
                                id="testId"
                                className="bg-slate-600 text-white p-1 rounded"
                                value={selectedTestId.exam}
                                onChange={handleExamChange}
                            >
                                {testIdOptions.map((testId, index) => (
                                    <option key={index} value={testId.exam}>
                                        {testId.exam}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="flex">
                            <div className="mr-2 mt-[8px]">Question #:</div>
                            <div id="qid" className="bg-slate-600 text-white p-1 rounded">
                                <input
                                    type="number"
                                    value={qid}
                                    onChange={handleQidChange}
                                    className="bg-slate-600 text-white p-1 rounded"
                                />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="mr-2 mt-[8px]">GroupId:</div>
                            <div id="qid" className="bg-slate-600 text-white p-1 rounded">
                                {currentQuestion?.group_id}
                            </div>
                        </div>
                        <div className="flex">
                            <div className="mr-2 mt-[8px]">Tag:</div>
                            <div id="qid" className="bg-slate-600 text-white p-1 rounded">
                                {currentQuestion?.tags}
                            </div>
                        </div>
                    </>
                )}
                {/*
                {flag ? (
                    <button className="rounded-md border border-2 m-1 p-1" onClick={() => handleflag()}>
                        <FaFlag />
                    </button>
                ) : (
                    <button className="rounded-md border border-2 m-1 p-1" onClick={() => handleflag()}>
                        <CiFlag1 />
                    </button>
                )}
*/}
                <button className="ml-[100px]" onClick={() => window.location.reload()}>
                    Reset
                </button>
                <button onClick={() => handleUpdate()}>Update</button>
                {code === false && (
                    <>
                        <button onClick={handlePreviousQuestion}>Back</button>
                        <button onClick={handleNextQuestion}>Next</button>
                    </>
                )}
                {code === true && (
                    <>
                        <button onClick={() => handleBack2()}>Back</button>
                        <button onClick={() => handleNext2()}>Next</button>
                    </>
                )}
            </div>
        </nav>
    );
};

export default NavBar;
