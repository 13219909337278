// TestList.js

import React from 'react';
import { FaAngleDoubleDown } from 'react-icons/fa';

const TestList = ({ currentTests, handleTestClick, selectedTest }) => {
  console.log(currentTests);
  return (
    <ul className="p-6 space-y-4">
      {currentTests.map((test, index) => (
        <li key={index} className="pr-[10px]">
          <button
            className={`pt-[15px] pb-[15px] bg-white border-black border text-black py-2 px-4 border-slate-700 w-full flex items-center justify-between ${
              selectedTest && selectedTest.test_id === test.test_id ? 'bg-blue-100' : ''
            }`}
            onClick={() => handleTestClick(test)}
          >
            {test.test_name} on {test.date.split('T')[0]}
            <FaAngleDoubleDown />
          </button>
        </li>
      ))}
    </ul>
  );
};

export default TestList;
