import { Link } from "react-router-dom";
import Select from 'react-select'
import { useEffect, useState, useContext } from "react";
import { BASELINE } from "../../util";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";

const RandomSATReady89 = () => {
  const { user } = useContext(UserContext);
  const [selectedButton, setSelectedButton] = useState(null);
  const navigate = useNavigate();


  const startQuiz = async () => {
    if (selectedButton == null) {
      toast.error('Please select a SAT module before starting the quiz.');
    } else {
      if (user) {
        navigate(`/sat/preset/quiz/p89_${selectedButton}`);
      } else {
        toast.error('Please login to start the quiz.');
      }
    }
  };

  return (
    <section className="wrapper mt-[100px]">
      <div className="w-[90%] max-w-[1000px] 2xl:max-w-[1300px] flex justify-start items-center flex-col gap-5 pt-8 px-5 bg-white rounded-2xl">
        <h2 className="text-black text-[28px] sm:text-[40px] md:text-[48px] font-bold">
          Pick The 2024 Digital SAT 8/9
        </h2>
        <div className="flex flex-row flex-wrap justify-center gap-2">
          {Array.from({ length: 6 }, (_, i) => i + 1).map((num) => (
            <button
              key={num}
              onClick={() => setSelectedButton(num)}
              className={`border border-black border-solid text-black font-bold py-2 px-4 rounded m-2 ${selectedButton === num ? 'bg-gray-400' : 'bg-white hover:bg-gray-300'}`}
            >
              #{num} PSAT8/9
              <div className={`w-[150px] p-2 mt-[5px] rounded-xl text-[12px] font-normal ${selectedButton === num ? 'bg-gray-400' : 'bg-gray-100'}`}>
                Total of 4 section <br /> English, 64 minutes <br /> Math, 64 minutes
              </div>
            </button>
          ))}
        </div>
        <div className="w-full flex flex-row justify-center py-4">
          <motion.button
            whileTap={{ scale: 0.97 }}
            className="bg-gray-500 px-4 py-2 outline-none rounded-md text-white hover:bg-gray-600 w-full glow-outline"
            onClick={() => {
              if (selectedButton === null) {
                toast.error('Please select a SAT module before starting the quiz.');
              } else {
                startQuiz();
              }
            }}
          >
            Start Quiz
          </motion.button>
        </div>
      </div>
    </section>
  );

};

export default RandomSATReady89;
