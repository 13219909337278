import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { BASELINE, AFTER_LOGIN } from "../../util/index";

import { toast } from "react-hot-toast";
import { motion } from "framer-motion";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js';
import TestList from "./testlist";
import CircleGraph from './circlegraph'; // Import the CircleGraph component
import SDM from "./student_dashboard_menu";

import Confetti from 'react-confetti';

import DigitalSatView from "./DigitalSatView";
import DigitalActView from "./DigitalActView";
import DigitalNMSQTView from "./DigitalNMSQTView";
import DigitalSAT89View from "./DigitalSAT89View";

import RandomSATReady from "./Dashboard_SAT";
import RandomACTReady from "./Dashboard_ACT";
import RandomNMSQTReady from "./Dashboard_NMSQT";
import RandomSAT89Ready from "./Dashboard_SAT89";

import OverallSATANAL from "./OverallSATANAL";
import OverallACTANAL from "./OverallACTANAL";
import OverallNMSQTAnal from "./OverallNMSQTANAL";
import OverallSAT89Anal from "./OverallSAT89ANAL";

import GetHelp from "./get_help";

ChartJS.register(BarElement, CategoryScale, LinearScale);

const LoadingScreen = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    color: 'white',
    fontSize: '24px',
    zIndex: 1000, // High z-index to cover everything else
  }}>
    Loading...
  </div>
);

const Dashboard = ({ s_uid, smallmode, parents }) => {
  const boxItemLeftRef = useRef();
  const [activeTab, setActiveTab] = useState('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { logoutUser, user } = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedTest, setSelectedTest] = useState(null);
  const [testResults, setTestResults] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [picked, setPicked] = useState('');
  const [questionid, setQuestionid] = useState('');
  const [tag, settag] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [selectedTypes, setSelectedTypes] = useState(['SAT']);
  const filteredTests = testResults.filter(test => selectedTypes.includes(test.type));
  const itemsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(0);
  const pageCount = Math.ceil(filteredTests.length / itemsPerPage);
  const currentTests = filteredTests.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const [hideExplanation, setHideExplanation] = useState(false);
  const handleChange = () => {
    setHideExplanation(!hideExplanation);
  };
  const [c_ans, set_cans] = useState(0);
  const [w_ans, set_wans] = useState(0);
  const [engtag, setengtag] = useState([]);
  const [mathtag, setmathtag] = useState([]);
  const [readingtag, setreadingtag] = useState([]);
  const [sciencetag, setsciencetag] = useState([]);

  const [eng, seteng] = useState(0);
  const [math, setmath] = useState(0);
  const [missing_topics, setMissing_topics] = useState([]);
  const [autosave, setautosave] = useState([]);
  const [homework, sethomework] = useState([]);
  const [dashboard, setdashboard] = useState(0);
  const [lastTest, setLastTest] = useState("???");
  const [percentage, setPercentage] = useState(0);
  const maxScore = 1600;
  const [isOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [testName, setTestName] = useState("");
  const [testType, setTestType] = useState('SAT');  // State to track the selected test type
  const [avg_sat, setavg_sat] = useState(0);
  const [avg_eng, setavg_eng] = useState(0);
  const [avg_math, setavg_math] = useState(0);
  const [Incorrect_questionIds, setIncorrect_questionIds] = useState([]);
  const [allSATScores, setAllSATScores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actscores, setactscores] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [overview, setoverview] = useState([]);
  const [index, setindex] = useState(0);
  const [type, settype] = useState("");

  useEffect(() => {
    setCurrentPage(0);
  }, [selectedTypes]);

  useEffect(() => {

    if (smallmode !== true) {
      if (!user || user.role === 'parents' || user.role === 'teacher') {
        navigate('/');
      }
    } else {
      setdashboard(2);
    }
  }, [user, navigate, smallmode, parents]);

  const preprocessOptions = (optionsString) => {
    try {
      // First, replace problematic backslashes (assuming they are not needed for JSON format)
      let formattedOptions = optionsString.replace(/\\(?![/uU])/g, '');

      // Escape double quotes inside HTML attributes
      formattedOptions = formattedOptions.replace(/<img([^>]+)>/gi, (match) => {
        return match.replace(/"/g, '\\"');
      });

      // Now try to parse the JSON
      return JSON.parse(optionsString);
    } catch (e) {
      console.error("Failed to parse options:", e);
      return []; // Fallback to an empty array if parsing fails
    }
  };

  const RenderOptions = () => {
    try {
      var options;
      if (selectedTopic.options) {
        options = preprocessOptions(selectedTopic.options); // Process options
      }
      if (typeof options === "string") {
        try {
          options = JSON.parse(options); // Attempt to parse if it's still a string
        } catch (e) {
          console.error("Failed to parse options:", e);
          console.error("Problematic JSON:", options);
          options = []; // Fallback to an empty array if parsing fails
        }
      }

      // If options is an array, map over it
      return options.length > 0 ? (
        options.map((option, idx) => (
          <Option2
            sat={selectedTopic.subject}
            key={idx}
            currentQuestion={selectedTopic}
            option={option}
          />
        ))
      ) : (
        <p>No options available</p> // Fallback if the options array is empty
      );
    } catch (error) {
      //  console.error("Error parsing options:", error);
      return null; // Return nothing if there is an error
    }
  };

  const handleTestTypeChange = (event) => {
    if (event.target.value === 'SAT') {
      setdashboard(0);
      setActiveIndex(0);
      setSelectedTypes(['SAT']);
    } else if (event.target.value === 'ACT') {
      setdashboard(5);
      setActiveIndex(5);
      setSelectedTypes(['ACT']);
    }
    else if (event.target.value === 'NMSQT') {
      setdashboard(9);
      setActiveIndex(9);
      setSelectedTypes(['SAT10']);
    }
    else if (event.target.value === 'PSAT89') {
      setdashboard(13);
      setActiveIndex(13);
      setSelectedTypes(['SAT89']);
    }
    setTestType(event.target.value);
    // Additional logic to handle test type change, if necessary
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = (testid) => {
    if (testName.length > 3) {
      axios.post(BASELINE + 'tests/title', { testid, testName })
        .then(function (response) {
          setIsEditing(false);
          setSelectedTest((prevState) => ({
            ...prevState,
            test_name: testName,
          }));

          // Update testResults with the new name
          setTestResults((prevTestResults) =>
            prevTestResults.map((test) =>
              test.test_id === testid ? { ...test, test_name: testName } : test
            )
          );
        })
        .catch(function (error) {
          alert("Something went wrong.");
        });
    } else {
      alert("Test name must be more than three letters.");
    }
  };

  const handleInputChange = (e) => {
    setTestName(e.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    if (password.length < 6) {
      alert("Password must be at least 6 characters long.");
      return;
    }

    axios.post(BASELINE + 'user/setpassword', [user.u_id, password])
      .then(function (response) {
        setPassword('');
        alert("Password Reset Successfully!");
      })
      .catch(function (error) {
        alert("Something Went Wrong.");
      });

    setIsOpen(false);
  };

  function logout() {
    logoutUser();
    axios.post(BASELINE + 'user/logout', {})
      .then(function (response) {
        alert(response.data.message);
        window.location.replace(AFTER_LOGIN);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  const tabs = [
    { id: 'all', title: 'All Questions' },
    { id: 'readingWriting', title: 'Reading and Writing' },
    { id: 'math', title: 'Math' },
  ];

  const tabs2 = [
    { id: 'all', title: 'All Questions' },
  ];

  const tabs3 = [
    { id: 'all', title: 'All Questions' },
    { id: 'english', title: 'English' },
    { id: 'math', title: 'Math' },
    { id: 'reading', title: 'Reading' },
    { id: 'science', title: 'Science' },
  ];

  const getInitialData2 = () => ({
    labels: ['Target Score'],
    datasets: [
      {
        label: 'Target Score',
        data: [maxScore],
        backgroundColor: 'rgba(201, 203, 207, 0.6)',
      },
    ],
  });
  const [data2, setData2] = useState(getInitialData2());

  const resetData2 = () => {
    setData2(getInitialData2());
  };

  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        max: maxScore,
        beginAtZero: true,
      },
    },
  };

  const data = {
    labels: [''],
    datasets: [
      {
        label: 'Predicted Score',
        data: [percentage],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Target Score',
        data: [maxScore],
        backgroundColor: 'rgba(201, 203, 207, 0.6)',
      },
    ],
  };

  const options2 = {
    indexAxis: 'y',
    scales: {
      x: {
        max: maxScore,
        beginAtZero: true,
      },
    },
  };

  const setPage = (newPage) => {
    if (newPage < 0 || newPage >= pageCount) return;
    setCurrentPage(newPage);
  };

  useEffect(() => {
    // Dynamically load the KaTeX script
    const script2 = document.createElement('script');
    script2.src = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js';
    script2.integrity = 'sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05';
    script2.crossOrigin = 'anonymous';

    // Handle script load
    script2.onload = () => {
      if (boxItemLeftRef.current) {
        const options = {
          delimiters: [
            { left: '$$', right: '$$', display: false },
            { left: "$", right: "$", display: false },
            { left: '\\(', right: '\\)', display: false },
            { left: '\\[', right: '\\]', display: true },
            { left: '\\begin{array}', right: '\\end{array}', display: true }
          ],
          throwOnError: false,
        };

        window.renderMathInElement(boxItemLeftRef.current, options);
      }
    };
    document.body.appendChild(script2);

    // Cleanup: remove the script when component unmounts
    return () => {
      document.body.removeChild(script2);
    };
  }, [selectedTest, selectedTopic, hideExplanation]);

  const handleNextQuestion = () => {
    const nextIndex = index + 1;
    if (nextIndex < overview.length) {
      setindex(nextIndex);
      setSelectedTopic(overview[nextIndex]);
      if (overview[nextIndex].type === 'ACT') {
        setQuestionid(overview[nextIndex].qid);
        settype(overview[nextIndex].type);
      } else {
        setQuestionid(overview[nextIndex].questionIds);
        settype(overview[nextIndex].type);
      }
    }
  };



  const handlePreviousQuestion = () => {
    const nextIndex = index - 1;
    if (nextIndex >= 0) {
      setindex(nextIndex);
      setSelectedTopic(overview[nextIndex]);
      if (overview[nextIndex].type === 'ACT') {
        setQuestionid(overview[nextIndex].qid);
        settype(overview[nextIndex].type);
      } else {
        setQuestionid(overview[nextIndex].questionIds);
        settype(overview[nextIndex].type);
      }
    }
  };

  const handleButtonClick = async (userpick, questionid, tag, type, data, index) => {
    console.log("=|", selectedTest, userpick, questionid, tag, type, data, "=index: ", index)
    setindex(index);
    if (data.length > 0) { setoverview(data); }
    setPicked(userpick);
    setQuestionid(questionid);

    let section;
    if (tag === "eng") {
      section = "Eng";
    } else {
      section = "Math";
    }
    settag(section);

    if (!type) { settype("SAT") } else { settype(type) }
    getquestion(type, section);
  };

  const getquestion = async (type, section) => {
    if (type === "SAT89") {
      if (tag === "eng") {
        section = "eng";
      } else {
        section = "math";
      }

      try {
        let res = await axios.get(BASELINE + "questions/sat89", { params: { id: [questionid], section: section } });
        let arr = [res.data];
        let mappedQuestions = arr[0].map((question, idx) => {
          return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
        });

        if (mappedQuestions[0].stimulus) {
          settag("Eng");
        } else {
          settag("Math");
        }

        setSelectedTopic(mappedQuestions[0]);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    } else if (type === "SAT10") {
      if (tag === "eng") {
        section = "eng";
      } else {
        section = "math";
      }

      try {
        let res = await axios.get(BASELINE + "questions/sat10", { params: { id: [questionid], section: section } });
        let arr = [res.data];
        let mappedQuestions = arr[0].map((question, idx) => {
          return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
        });

        if (mappedQuestions[0].stimulus) {
          settag("Eng");
        } else {
          settag("Math");
        }

        setSelectedTopic(mappedQuestions[0]);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    } else if (type === "SAT") {
      try {
        let res = await axios.get(BASELINE + "questions/sat", { params: { id: [questionid], section: section } });
        let arr = [res.data];
        let mappedQuestions = arr[0].map((question, idx) => {
          return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
        });

        if (mappedQuestions[0].stimulus) {
          settag("Eng");
        } else {
          settag("Math");
        }

        setSelectedTopic(mappedQuestions[0]);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    } else if (type === 'ACT') {
      try {
        let res = await axios.get(BASELINE + "questions/act", { params: { id: questionid } });
        let arr = [res.data];

        let mappedQuestions = arr[0].map((question, idx) => {
          return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
        });

        settag("Eng");

        setSelectedTopic(mappedQuestions[0]);
        console.log("setSelectedTopic", mappedQuestions[0]);
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    }

    setIsModalOpen(true);
  };

  useEffect(() => {
    if (questionid && type) {
      console.log("+++", type, tag);
      getquestion(type, tag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionid, tag, type]);


  useEffect(() => {
    if (selectedTest !== null) {
      if (selectedTest.section_data !== null) {
        if (selectedTest.type === 'SAT' || selectedTest.type === 'SAT10' || selectedTest.type === 'SAT89') {
          setPercentage(parseInt(selectedTest.eng_score, 10) + parseInt(selectedTest.math_score, 10));
          let sat = JSON.parse(selectedTest.section_data);

          let eng = sat.filter(s => s.tag === 'eng');
          let math = sat.filter(s => s.tag === 'math');
          setengtag(eng);
          setmathtag(math);

          let grouped = sat.reduce((r, a) => {
            r[a.section] = [...r[a.section] || [], a];
            return r;
          }, {});

          let scores = [];

          for (let section in grouped) {
            let maxScore = section.includes('Hard') ? 800 : 600;
            let totalScore = grouped[section].reduce((total, a) => a.correct ? total + 1 : total, 0);
            let score = Math.round(totalScore / grouped[section].length * maxScore);
            scores.push(Math.max(score, 100));
          }

          seteng(scores[0] + scores[1]);
          setmath(scores[2] + scores[3]);

          let incorrectElements = sat.filter(s => !s.correct);
          setMissing_topics([...incorrectElements]);

          let correctCount = sat.reduce((total, a) => a.correct ? total + 1 : total, 0);
          let incorrectCount = sat.length - correctCount;

          set_cans(correctCount);
          set_wans(incorrectCount);
        } else if (selectedTest.type === 'ACT') {
          let sat = JSON.parse(selectedTest.section_data);

          let eng = sat.filter(s => s.tag === 'english');
          let math = sat.filter(s => s.tag === 'math');
          let reading = sat.filter(s => s.tag === 'reading');
          let science = sat.filter(s => s.tag === 'science');

          setengtag(eng);
          setmathtag(math);
          setreadingtag(reading);
          setsciencetag(science);

          let grouped = sat.reduce((r, a) => {
            r[a.tag] = [...r[a.tag] || [], a];
            return r;
          }, {});

          let scores = [];

          for (let section in grouped) {
            let maxScore = 36;
            let totalScore = grouped[section].reduce((total, a) => a.correct ? total + 1 : total, 0);
            let score = Math.round(totalScore / grouped[section].length * maxScore);
            scores.push(Math.max(score, 100));
          }

          seteng(scores[0] + scores[1]);
          setmath(scores[2] + scores[3]);

          let incorrectElements = sat.filter(s => !s.correct);
          setMissing_topics([...incorrectElements]);

          let correctCount = sat.reduce((total, a) => a.correct ? total + 1 : total, 0);
          let incorrectCount = sat.length - correctCount;

          set_cans(correctCount);
          set_wans(incorrectCount);
        }
      }
    }
  }, [selectedTest]);

  const handleTestClick = (test) => {
    if (test.type === 'ACT') {
      if (test.eng_score) {
        setactscores(JSON.parse(test.eng_score));
      } else {

      }
    }

    setTestName(test.test_name);
    setSelectedTest(test);
  };

  const closeModal = () => {
    setSelectedTest("");
  };

  async function handleStartQuiz() {
    if (!user) {
      toast.error("Please login before starting the test");
      navigate("/login"); // Redirect user to the /login page
      return;
    }

    let data;

    if (s_uid) {
      data = {
        id: s_uid
      };
    } else {
      data = {
        id: user.u_id
      };
    }

    axios.post(BASELINE + 'testresult/get', data)
      .then(response => {
        setTestResults(response.data);
        let sum = 0;
        let totalEng = 0;
        let totalMath = 0;
        let count = 0;
        let accumulatedIds = [];
        let scores = []; // Array to store individual SAT scores

        for (let item of response.data.reverse()) {
          if (selectedTypes.includes(item.type)) {
            if (item.eng_score != null && item.math_score != null) {
              const engScore = Number(item.eng_score);
              const mathScore = Number(item.math_score);
              const individualSAT = engScore + mathScore; // Calculate individual SAT score
              scores.push(individualSAT); // Store it in the array

              if (sum === 0) {
                sum = individualSAT; // Assign the first valid SAT score
              }
              totalEng += engScore;
              totalMath += mathScore;
              count++;

              let arr = item.Incorrect_questionIds ? JSON.parse(item.Incorrect_questionIds) : [];
              accumulatedIds = accumulatedIds.concat(arr);
            }
          }
        }

        setIncorrect_questionIds(accumulatedIds);
        const reversedScores = [...scores].reverse();
        setAllSATScores(reversedScores); // Update the state with all SAT scores

        const avg_sat = count > 0 ? parseInt(Math.round((totalEng + totalMath) / count)) : 0;
        const avg_eng = count > 0 ? parseInt(Math.round(totalEng / count)) : 0;
        const avg_math = count > 0 ? parseInt(Math.round(totalMath / count)) : 0;

        setData2(prevData2 => ({
          ...prevData2,
          datasets: [
            ...prevData2.datasets,
            {
              label: 'Latest Score',
              data: [sum],
              backgroundColor: `rgba(51, 65, 85, 1)`,
            },
          ],
        }));

        setLastTest(sum);
        setavg_sat(avg_sat);
        setavg_eng(avg_eng);
        setavg_math(avg_math);
      })
      .catch(error => {
        console.error(error);
      });

    axios.get(BASELINE + 'getautosave', { params: { id: user.u_id } })
      .then(response => {
        setautosave(response.data.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(() => {
    resetData2();
    handleStartQuiz();
  }, [user, selectedTypes]);

  useEffect(() => {
    if (user && user.premium === 0 && pageCount > 0) {
      setCurrentPage(pageCount - 1);
    }
  }, [pageCount]);

  const handleNavigate = (parsedData) => {
    if (parsedData) {
      localStorage.setItem('currentTest', parsedData.testid);
      if (parsedData.type === 'SAT') {
        navigate(`/sat/quiz`);
      } else if (parsedData.type === 'SAT10') {
        navigate(`/sat/quiz`);
      } else if (parsedData.type === 'SAT89') {
        navigate(`/sat/quiz`);
      } else {
        navigate(`/sat/preset/quiz/${parsedData.type}`);
      }
    }
  };

  const handleNavigatequiz = (parsedData, quiz_type, testname) => {
    if (parsedData) {
      if (window.confirm('Are you sure you want to navigate to another page?')) {
        localStorage.setItem('currentTest', parsedData);
        localStorage.setItem('type', quiz_type);
        localStorage.setItem('testname', testname);
        navigate(`/sat/quiz`);
      }
    }
  };

  const handleHomeworkClick = () => {
    var data = {
      userid: user.u_id
    };

    axios.post(BASELINE + 'gethomework', [data])
      .then(response => {
        sethomework(response.data.matchedHomeworks);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isTestComplete = (testId) => {
    return testResults.some((test) => {
      return test.ori_id === testId;
    });
  };

  const retakemissingquestion = (ids) => {
    var data = {
      lst: ids.eng_incorrect_questionIds,
      type: ids.type
    }
    axios.post(BASELINE + 'tests/retake/q', [data])
      .then(response => {
        alert("Leave this page to retake missing questions.");
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function Retake() {
    if (selectedTest.type === 'SAT') {
      const parsedData = JSON.parse(selectedTest.quiz_data);
      const questionIds = parsedData.map(item => item.questionIds);

      var tag;
      if (JSON.parse(selectedTest.quiz_data)[0].tag === "math") {
        tag = "Math"
      }

      const quizData = {
        questionIds: questionIds,
        tutorModeActive: false,
        timedModeActive: false,
        timeLimit: null,
        tag: tag,
        type: selectedTest.type,
      };
      localStorage.clear();
      navigate(`/sat/quiz/`, { state: { data: quizData } });
    }
    else if (selectedTest.type === 'SAT10') {
      const parsedData = JSON.parse(selectedTest.quiz_data);
      const questionIds = parsedData.map(item => item.questionIds);

      var tag;
      if (JSON.parse(selectedTest.quiz_data)[0].tag === "math") {
        tag = "Math"
      }

      const quizData = {
        questionIds: questionIds,
        tutorModeActive: false,
        timedModeActive: false,
        timeLimit: null,
        tag: tag,
        type: selectedTest.type,
      };
      localStorage.clear();
      navigate(`/sat/quiz/`, { state: { data: quizData } });
    }
    else if (selectedTest.type === 'SAT89') {
      const parsedData = JSON.parse(selectedTest.quiz_data);
      const questionIds = parsedData.map(item => item.questionIds);

      var tag;
      if (JSON.parse(selectedTest.quiz_data)[0].tag === "math") {
        tag = "Math"
      }

      const quizData = {
        questionIds: questionIds,
        tutorModeActive: false,
        timedModeActive: false,
        timeLimit: null,
        tag: tag,
        type: selectedTest.type,
      };
      localStorage.clear();
      navigate(`/sat/quiz/`, { state: { data: quizData } });
    }
    else if (selectedTest.type == 'ACT') {
      const parsedData = JSON.parse(selectedTest.quiz_data);
      const questionIds = parsedData.map(item => item.qid);

      var tag = JSON.parse(selectedTest.quiz_data)[0].section;

      const quizData = {
        questionIds: questionIds,
        tutorModeActive: false,
        timedModeActive: false,
        timeLimit: null,
        tag: tag,
        type: selectedTest.type,
      };
      localStorage.clear();
      navigate(`/act/quiz/`, { state: { data: quizData } });
    }
  }

  async function gethelp() {
    var quizDataArray;
    var questionIdsArray;
    var data;
    if (selectedTest.type === 'SAT' || selectedTest.type === 'SAT89' || selectedTest.type === 'SAT10') {
      quizDataArray = JSON.parse(selectedTest.quiz_data);
      questionIdsArray = quizDataArray.map(item => item.questionIds);

      data = {
        questionidsarr: questionIdsArray,
        student_id: user.u_id,
        type: selectedTest.type,
        tag: quizDataArray[0].tag,
        test_id: selectedTest.test_id
      };
    }
    else if (selectedTest.type === 'ACT') {
      quizDataArray = JSON.parse(selectedTest.quiz_data);
      questionIdsArray = quizDataArray.map(item => item.qid);

      data = {
        questionidsarr: questionIdsArray,
        student_id: user.u_id,
        type: selectedTest.type,
        tag: quizDataArray[0].section,
        test_id: selectedTest.test_id
      };
    }

    if (window.confirm("Are you sure you want to get help?")) {
      axios.post(BASELINE + 'post/question/set', [data])
        .then(response => {
          // Handle different responses based on status codes
          if (response.status === 201) {
            window.alert("If you have an assigned teacher, then the teacher will contact you.");
          } else if (response.status === 409) {
            window.alert("A help request with this test ID already exists.");
          } else {
            window.alert("Unexpected response from the server: " + response.status);
          }
        })
        .catch(error => {
          // Handle errors based on the status code returned from the server
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const status = error.response.status;
            if (status === 409) {
              window.alert("A help request with this test ID already exists.");
            } else if (status === 500) {
              window.alert("Internal server error. Please try again later.");
            } else {
              window.alert("An error occurred: " + error.response.status);
            }
          } else if (error.request) {
            // The request was made but no response was received
            window.alert("No response from the server. Please check your network connection.");
          } else {
            window.alert("Error: " + error.message);
          }
        });
    } else {
      window.alert("Help request cancelled.");
    }

  }

  return (
    <div className={`${parents ? 'flex flex-col' : ''} ${smallmode ? '' : 'flex flex-col mt-[10px]'} md:flex-row`}>
      {loading && <LoadingScreen />}
      <Confetti
        width={window.width}
        height={window.height}
        numberOfPieces={450}
        recycle={false}
        colors={['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']}
      />

      {smallmode !== true && (<>
        <div className="flex max-w-[250px] flex-col fixed top-0 z-50 bg-white" style={{ height: '100vh' }}>
          <select
            id="testTypeSelect"
            value={testType}
            onChange={handleTestTypeChange}
            className="mt-[10px] bg-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4 shadow-sm"
          >
            <option value="SAT">SAT</option>
            {user && (
              <>
                <option value="ACT">ACT</option>
                <option value="NMSQT">NMSQT</option>
                <option value="PSAT89">PSAT 8/9</option>
              </>
            )}

          </select>

          <SDM handleHomeworkClick={handleHomeworkClick} activeIndex={activeIndex} setActiveIndex={setActiveIndex} user={user} onLogout={logout} testType={testType} setdashboard={setdashboard} setIsOpen={setIsOpen} />
        </div>

      </>
      )}

      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Password Reset
                    </h3>
                    <div className="mt-2">
                      <input type="password" value={password} onChange={handlePasswordChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password" placeholder="New Password" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handleSubmit}>
                  Submit
                </button>
                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" onClick={() => setIsOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={`${parents ? '' : 'w-full mt-[-10px] h-screen flex flex-col'} ${!parents && !smallmode ? 'ml-[10px] ml-[250px]' : 'pl-[250px]'}`}>
        {/* Content Area */}

        {dashboard === 201 && (
          <div className="mr-[20px]">
            <GetHelp
              studentid={user?.u_id}
            />
          </div>
        )}

        {dashboard === 0 && (
          <DigitalSatView
            setActiveIndex={setActiveIndex}
            user={user}
            lastTest={lastTest}
            data2={data2}
            options2={options2}
            setdashboard={setdashboard}
            avg_sat={avg_sat}
            avg_eng={avg_eng}
            avg_math={avg_math}
            Incorrect_questionIds={Incorrect_questionIds}
            allSATScores={allSATScores}
          />
        )}

        {dashboard === 1 && (
          <RandomSATReady className="ml-[250px]" setLoading={setLoading} />
        )}

        {dashboard === 2 && (
          <>
            <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
              <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="font-semibold text-black">Previous SAT Tests</span>
            </div>
            <h1 className="text-2xl font-bold mt-4 pl-6">View Digital SAT Scores</h1>
            <div className="bg-white">
              <TestList
                currentTests={currentTests}
                handleTestClick={handleTestClick}
                selectedTest={selectedTest}
              />

              {user && user.premium === 1 && (<>
                <div className="bg-white flex justify-center items-center space-x-4 mt-4">
                  <button
                    className="p-2 rounded bg-gray-200 hover:bg-gray-300 disabled:bg-gray-500"
                    onClick={() => setPage(currentPage - 1)}
                    disabled={currentPage === 0}
                  >
                    <FaChevronLeft />
                  </button>
                  {Array.from({ length: pageCount }, (_, i) => (
                    <button
                      key={i}
                      className={`p-2 rounded ${i === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                      onClick={() => setPage(i)}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button
                    className="p-2 rounded bg-gray-200 hover:bg-gray-300 disabled:bg-gray-500"
                    onClick={() => setPage(currentPage + 1)}
                    disabled={currentPage === pageCount - 1}
                  >
                    <FaChevronRight />
                  </button>
                </div>
              </>)}
            </div>
          </>
        )}

        {dashboard === 3 && (
          <>
            <OverallSATANAL />
          </>
        )}

        {dashboard === 4 && smallmode !== true && (
          <>
            <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
              <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="font-semibold text-black">Resume Digital Test</span>
            </div>
            <h1 className="text-2xl font-bold mt-4 p-6">Resume Digital Test</h1>
            <div className="overflow-y-auto grid grid-cols-1 md:grid-cols-4 gap-4">
              {autosave.length > 0 ? (
                <>
                  {(user.premium ? autosave : autosave.slice(-3)).map((test, index) => {
                    return (
                      <div key={index} className="h-[250px] bg-gradient-to-br from-gray-700 to-gray-900 rounded-lg text-white flex flex-col text-center m-2">
                        <div className="flex items-center">
                          {test.type.length === 1 && (
                            <span className="mt-[10px] ml-[10px] w-[100px] bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">Digital SAT</span>
                          )}
                          {test.type === 'SAT' && (
                            <span className="mt-[10px] ml-[10px] w-[100px] bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">SAT Quiz</span>
                          )}
                          {test.type.slice(0, 3) === 'p89' && (
                            <span className="mt-[10px] ml-[10px] w-[140px] bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">Digital SAT 8/9</span>
                          )}
                          {test.type.slice(0, 3) === 'p10' && (
                            <span className="mt-[10px] ml-[10px] w-[140px] bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">Digital NMSQT</span>
                          )}
                          {test.type === 'SAT10' && (
                            <span className="mt-[10px] ml-[10px] w-[140px] bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">NMSQT Quiz</span>
                          )}
                          {test.type === 'SAT89' && (
                            <span className="mt-[10px] ml-[10px] w-[140px] bg-gray-700 text-white text-sm font-semibold mr-2 px-2.5 py-0.5 rounded border border-white">SAT 8/9 Quiz</span>
                          )}
                        </div>
                        <p className="mt-[60px] font-bold uppercase text-4xl">{test.name}</p>
                        <p className="font-bold uppercase text-md">{test.created_date}</p>

                        <button
                          className="mt-[30px] bg-gray-200 text-black rounded m-2 p-2"
                          onClick={() => handleNavigate(test)}
                        >
                          Resume Test
                        </button>
                      </div>
                    );
                  })}

                  {user && user.premium === true ? (
                    <></>
                  ) : (
                    <div className="hidden relative bg-white p-6 rounded-xl shadow-md h-[250px] m-2">
                      <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
                        <div className="h-full w-full bg-white rounded-xl"></div>
                      </div>
                      <div className="relative text-center">
                        <div className="flex items-center mt-4">
                          <p className="mt-[3%] font-bold uppercase text-4xl">Save  SAT tests to resume later</p>
                        </div>
                        <p className="mt-[5%] font-bold gradient-text">✨Go Premium!✨</p>
                      </div>
                    </div>
                  )}

                </>
              ) : (
                <div className="pl-6">Empty Test</div>
              )}
            </div>
          </>
        )}

        {dashboard === 5 && (
          <DigitalActView
            user={user}
            setdashboard={setdashboard}
          />
        )}

        {dashboard === 6 && (
          <RandomACTReady className="ml-[250px]" setLoading={setLoading} />
        )}

        {dashboard === 7 && (
          <>
            <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
              <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="font-semibold text-black">Digital ACT Scores</span>
            </div>
            <h1 className="text-2xl font-bold mt-4 pl-6">View Digital ACT Scores</h1>
            <div className="bg-white">
              <TestList
                type={'ACT'}
                currentTests={currentTests}
                handleTestClick={handleTestClick}
                selectedTest={selectedTest}
              />

              {user && user.premium === 1 && (<>
                <div className="bg-white flex justify-center items-center space-x-4 mt-4">
                  <button
                    className="p-2 rounded bg-gray-200 hover:bg-gray-300 disabled:bg-gray-500"
                    onClick={() => setPage(currentPage - 1)}
                    disabled={currentPage === 0}
                  >
                    <FaChevronLeft />
                  </button>
                  {Array.from({ length: pageCount }, (_, i) => (
                    <button
                      key={i}
                      className={`p-2 rounded ${i === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                      onClick={() => setPage(i)}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button
                    className="p-2 rounded bg-gray-200 hover:bg-gray-300 disabled:bg-gray-500"
                    onClick={() => setPage(currentPage + 1)}
                    disabled={currentPage === pageCount - 1}
                  >
                    <FaChevronRight />
                  </button>
                </div>
              </>)}
            </div>
          </>
        )}

        {dashboard === 8 && (
          <>
            <OverallACTANAL />
          </>
        )}

        {dashboard === 9 && (
          <DigitalNMSQTView
            setActiveIndex={setActiveIndex}
            user={user}
            lastTest={lastTest}
            data2={data2}
            options2={options2}
            setdashboard={setdashboard}
            avg_sat={avg_sat}
            avg_eng={avg_eng}
            avg_math={avg_math}
            Incorrect_questionIds={Incorrect_questionIds}
            allSATScores={allSATScores}
          />
        )}

        {dashboard === 10 && (
          <RandomNMSQTReady className="ml-[250px]" setLoading={setLoading} />
        )}

        {dashboard === 11 && (
          <>
            <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
              <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="font-semibold text-black">Digital NMSQT Scores</span>
            </div>
            <h1 className="text-2xl font-bold mt-4 pl-6">View Digital NMSQT Scores</h1>
            <div className="bg-white">
              <TestList
                type={'SAT10'}
                currentTests={currentTests}
                handleTestClick={handleTestClick}
                selectedTest={selectedTest}
              />

              {user && user.premium === 1 && (<>
                <div className="bg-white flex justify-center items-center space-x-4 mt-4">
                  <button
                    className="p-2 rounded bg-gray-200 hover:bg-gray-300 disabled:bg-gray-500"
                    onClick={() => setPage(currentPage - 1)}
                    disabled={currentPage === 0}
                  >
                    <FaChevronLeft />
                  </button>
                  {Array.from({ length: pageCount }, (_, i) => (
                    <button
                      key={i}
                      className={`p-2 rounded ${i === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                      onClick={() => setPage(i)}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button
                    className="p-2 rounded bg-gray-200 hover:bg-gray-300 disabled:bg-gray-500"
                    onClick={() => setPage(currentPage + 1)}
                    disabled={currentPage === pageCount - 1}
                  >
                    <FaChevronRight />
                  </button>
                </div>
              </>)}
            </div>
          </>
        )}

        {dashboard === 12 && (
          <>
            <OverallNMSQTAnal />
          </>
        )}

        {dashboard === 13 && (
          <DigitalSAT89View
            setActiveIndex={setActiveIndex}
            user={user}
            lastTest={lastTest}
            data2={data2}
            options2={options2}
            setdashboard={setdashboard}
            avg_sat={avg_sat}
            avg_eng={avg_eng}
            avg_math={avg_math}
            Incorrect_questionIds={Incorrect_questionIds}
            allSATScores={allSATScores}
          />
        )}

        {dashboard === 14 && (
          <RandomSAT89Ready className="ml-[250px]" setLoading={setLoading} />
        )}

        {dashboard === 15 && (
          <>
            <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
              <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="font-semibold text-black">Digital PSAT 8/9 Scores</span>
            </div>
            <h1 className="text-2xl font-bold mt-4 pl-6">View Digital PSAT 8/9 Scores</h1>
            <div className="bg-white">
              <TestList
                type={'SAT89'}
                currentTests={currentTests}
                handleTestClick={handleTestClick}
                selectedTest={selectedTest}
              />

              {user && user.premium === 1 && (<>
                <div className="bg-white flex justify-center items-center space-x-4 mt-4">
                  <button
                    className="p-2 rounded bg-gray-200 hover:bg-gray-300 disabled:bg-gray-500"
                    onClick={() => setPage(currentPage - 1)}
                    disabled={currentPage === 0}
                  >
                    <FaChevronLeft />
                  </button>
                  {Array.from({ length: pageCount }, (_, i) => (
                    <button
                      key={i}
                      className={`p-2 rounded ${i === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'}`}
                      onClick={() => setPage(i)}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button
                    className="p-2 rounded bg-gray-200 hover:bg-gray-300 disabled:bg-gray-500"
                    onClick={() => setPage(currentPage + 1)}
                    disabled={currentPage === pageCount - 1}
                  >
                    <FaChevronRight />
                  </button>
                </div>
              </>)}
            </div>
          </>
        )}

        {dashboard === 16 && (
          <>
            <OverallSAT89Anal />
          </>
        )}

        {dashboard === 99 && (
          <div>
            <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
              <span className="hover:underline cursor-pointer">My Class</span> &gt; <span className="font-semibold text-black">Homework</span>
            </div>

            <div className="md:pl-6 bg-white flex-1 pt-3 rounded relative">
              <h2 className="text-2xl font-bold">Homework View</h2>

              <div className="overflow-y-auto mr-[30px]">
                {homework && homework.length > 0 ? (
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr>
                        <th className="py-2">Homework Title</th>
                        <th className="py-2">Status</th>
                        <th className="py-2">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {homework.map((test, index) => {
                        // Destructure the properties from the test object
                        const { name, testid, type } = test;
                        // Check if the test is complete using the testid
                        const isComplete = isTestComplete(testid);

                        return (
                          <tr key={index} className="bg-gray-100 border-b">
                            <td className="py-2 px-6 text-center">{name}</td>
                            <td className="py-2 px-6 text-center">
                              {isComplete ? (
                                <span className="bg-green-200 text-green-800 px-2 py-1 rounded">Complete</span>
                              ) : (
                                <span className="bg-red-200 text-red-800 px-2 py-1 rounded">Incomplete</span>
                              )}
                            </td>
                            <td className="py-2 px-6 text-center">
                              <button
                                className="bg-slate-500 hover:bg-slate-700 text-white px-4 py-2 rounded"
                                onClick={() => handleNavigatequiz(testid, type, name)}
                              >
                                {isComplete ? 'Retake Homework' : 'Start Homework'}
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <div>Loading Homework...</div>
                )}
              </div>
            </div>
          </div>
        )}

      </div>

      {/* Modal */}
      {selectedTest && (
        <div className="fixed inset-0 flex items-center justify-center z-50 mt-[-15px]">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div className="bg-white p-4 shadow-lg relative w-[100%] h-[96%] overflow-x-auto">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closeModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {selectedTest.section_data == null && (<>
              {user && (
                <div className="flex items-center">
                  {isEditing ? (
                    <>
                      <input
                        type="text"
                        value={testName}
                        onChange={handleInputChange}
                        className="font-bold text-l"
                      />
                      <button
                        onClick={() => handleSaveClick(selectedTest.test_id)}
                        className="ml-2 bg-blue-500 text-white px-2 py-1 rounded"
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <>
                      <p className="font-bold text-2xl">{selectedTest.test_name}</p>
                      {selectedTest && selectedTest.ori_id === null && (
                        user && user.u_id === selectedTest.user_id && (
                          <button
                            onClick={() => handleEditClick()}
                            className="ml-2 bg-gray-200 text-black px-2 py-1 rounded"
                          >
                            Edit
                          </button>
                        )
                      )}
                    </>
                  )}
                </div>
              )}
              <div className="mb-4 mt-[15px] bg-gray-900 text-white p-6 rounded-lg shadow-md flex flex-col md:flex-row justify-between items-center">
                <span className="text-[24px] font-bold mb-4 md:mb-0">
                  {selectedTest.test_name} Report Card
                </span>

                <div className="flex flex-col md:flex-row items-center mt-2 w-full md:w-auto">
                  <div className="hidden md:block">
                    <CircleGraph
                      correctQuestions={JSON.parse(selectedTest.Correct_questionIds).length}
                      wrongQuestions={JSON.parse(selectedTest.Incorrect_questionIds).length}
                    />
                  </div>


                  {/* Container for the scores, displayed in a row on mobile */}
                  <div className="ml-0 md:ml-8 flex flex-col-reverse md:flex-col space-y-2 w-full justify-around">
                    <span className="bg-purple-600 text-white text-[14px] px-2 py-1 rounded-full text-center w-full">
                      Total Score: {Math.round((JSON.parse(selectedTest.Correct_questionIds).length / (JSON.parse(selectedTest.Correct_questionIds).length + JSON.parse(selectedTest.Incorrect_questionIds).length)) * 100)}
                    </span>
                    <span className="bg-gray-700 text-white text-[14px] px-2 py-1 rounded-full text-center w-full">
                      Number of Correct Questions: {JSON.parse(selectedTest.Correct_questionIds).length}
                    </span>
                    <span className="bg-slate-500 text-white text-[14px] px-2 py-1 rounded-full text-center w-full">
                      Number of Wrong Questions: {JSON.parse(selectedTest.Incorrect_questionIds).length}
                    </span>
                    {user?.role !== "teacher" && selectedTest.ori_id === null && (<>
                      <button className="p-4 bg-white border-1 text-black rounded-md hover:bg-gray-200" onClick={() => Retake()}>Retake the Test</button>
                    </>)}
                  </div>
                </div>
              </div>

            </>)}

            {selectedTest.section_data != null && (<>
              {smallmode !== true && (<>
                {(selectedTest.type === 'SAT' || selectedTest.type === 'SAT10' || selectedTest.type === 'SAT89') ? (
                  <div className="mb-4 mt-[15px] bg-gray-900 text-white p-6 rounded-lg shadow-md flex justify-between items-center">
                    <div className="flex flex-col">
                      <span className="text-[24px] font-bold">
                        {selectedTest.test_name} Report Card
                      </span>
                      <div className="flex items-center mt-2">
                        <span className="text-[32px] sm:text-[48px] md:text-[64px] font-bold mr-4">
                          {parseInt(selectedTest.eng_score) + parseInt(selectedTest.math_score)} / 1600
                        </span>
                        <div className="flex flex-col">
                          <span className="bg-purple-600 text-white text-[14px] px-2 py-1 rounded-full text-center">English Score: {selectedTest.eng_score}</span>
                          <span className="bg-gray-700 text-white text-[14px] px-2 py-1 rounded-full mt-2 text-center">Math Score: {selectedTest.math_score}</span>
                          <span className="bg-white text-black text-[14px] px-2 py-1 rounded-full mt-2 text-center">Date: {selectedTest.date.split('T')[0]}</span>
                        </div>
                      </div>
                    </div>
                    {selectedTest.section_data && (
                      <div className="relative mb-4 hidden md:block">
                        <Bar data={data} options={options} />
                      </div>
                    )}
                    <div className="mt-[-20px] text-sm hidden md:block">
                      {selectedTest.type === 'SAT' && (
                        <span className="block gradient-text">Your SAT Score</span>
                      )}
                      {selectedTest.type === 'SAT10' && (
                        <span className="block gradient-text">Your NMSQT Score</span>
                      )}
                      <span className="mt-[20px] block text-gray-500">Target Score</span>
                    </div>
                  </div>
                ) : (
                  <div className="mb-4 mt-[15px] bg-gray-900 text-white p-6 shadow-md flex justify-between items-center">
                    <div className="flex flex-col">
                      <span className="text-[24px] font-bold">
                        {selectedTest.test_name} Report Card
                      </span>
                    </div>
                    <div className="flex items-center mt-2 space-x-4">
                      <div className="flex flex-col items-center bg-purple-500 text-white rounded-lg shadow-md p-2">
                        <span className="text-lg font-medium">📖 English</span>
                        <span className="text-2xl font-bold">{actscores.english}/36</span>
                      </div>
                      <div className="flex flex-col items-center bg-gray-700 text-white rounded-lg shadow-md p-2">
                        <span className="text-lg font-medium">🧮 Math</span>
                        <span className="text-2xl font-bold">{actscores.math}/36</span>
                      </div>
                      <div className="flex flex-col items-center bg-green-500 text-white rounded-lg shadow-md p-2">
                        <span className="text-lg font-medium">📚 Reading</span>
                        <span className="text-2xl font-bold">{actscores.reading}/36</span>
                      </div>
                      <div className="flex flex-col items-center bg-blue-500 text-white rounded-lg shadow-md p-2">
                        <span className="text-lg font-medium">🧪 Science</span>
                        <span className="text-2xl font-bold">{actscores.science}/36</span>
                      </div>
                    </div>

                  </div>
                )}
              </>
              )}

              {smallmode === true && (
                <div className="mb-4 mt-[15px] bg-slate-900 text-white p-6 rounded-lg shadow-md flex flex-col items-center">
                  <div className="flex flex-col items-center">
                    <span className="text-[24px] font-bold">
                      {selectedTest.test_name} Report Card
                    </span>
                    <span className="text-[32px] sm:text-[48px] md:text-[64px] font-bold">
                      {parseInt(selectedTest.eng_score) + parseInt(selectedTest.math_score)} / 1600
                    </span>
                    <span className="bg-purple-600 text-white text-[14px] px-2 py-1 rounded-full mt-2 text-center">English Score: {selectedTest.eng_score}</span>
                    <span className="bg-gray-700 text-white text-[14px] px-2 py-1 rounded-full mt-2 text-center">Math Score: {selectedTest.math_score}</span>
                    <span className="bg-white text-black text-[14px] px-2 py-1 rounded-full mt-2 text-center">Date: {selectedTest.date.split('T')[0]}</span>
                  </div>
                </div>
              )}
            </>)}

            {selectedTest.section_data != null && (
              <div>
                <div>
                  <div className="bg-gray-100 p-4">
                    <ul className="flex cursor-pointer">
                      {(selectedTest.type === 'SAT' || selectedTest.type === 'SAT10' || selectedTest.type === 'SAT89') && (<>
                        {
                          tabs.map(tab => (
                            <li
                              key={tab.id}
                              className={`flex-1 text-center py-2 px-4 ${activeTab === tab.id ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}
                              onClick={() => setActiveTab(tab.id)}
                            >
                              {tab.title}
                            </li>
                          ))
                        }
                      </>)}
                      {selectedTest.type === 'ACT' && (<>
                        {
                          tabs3.map(tab => (
                            <li
                              key={tab.id}
                              className={`flex-1 text-center py-2 px-4 ${activeTab === tab.id ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}
                              onClick={() => setActiveTab(tab.id)}
                            >
                              {tab.title}
                            </li>
                          ))
                        }
                      </>)}
                    </ul>
                  </div>
                  <div>
                    {activeTab === 'all' && <div>
                      <div className="overflow-y-auto mt-[5px] flex justify-center items-center bg-blue-100 py-4">
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{JSON.parse(selectedTest.section_data).length}</p>
                          <p className="text-sm font-medium">Total Questions</p>
                        </div>
                        <div className="border-l-2 border-gray-400"></div>
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{c_ans}</p>
                          <p className="text-sm font-medium">Correct Answers</p>
                        </div>
                        <div className="border-l-2 border-gray-400"></div>
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{w_ans}</p>
                          <p className="text-sm font-medium">Incorrect Answers</p>
                        </div>
                      </div>

                      <div className="max-h-[540px] mt-4">
                        <table className="min-w-full bg-white">
                          <thead>
                            <tr className="w-full bg-gray-200 text-left">
                              <th className="px-4 py-2">Question Id</th>
                              <th className="px-4 py-2">Section</th>
                              <th className="px-4 py-2">Correct Answers</th>
                              <th className="px-4 py-2">Your Answer</th>
                              <th className="px-4 py-2">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(selectedTest.type === 'SAT' || selectedTest.type === 'SAT10' || selectedTest.type === 'SAT89') && (
                              engtag.map((questionObj, index) => {
                                return (
                                  <tr key={index} className={`border-b border-gray-200 hover:bg-gray-100 ${questionObj.correct ? '' : 'bg-red-200'}`}>
                                    <td className="px-4 py-2">{index + 1}</td>
                                    <td className="px-4 py-2">Reading and Writing</td>
                                    <td className="px-4 py-2 capitalize">
                                      {Array.isArray(questionObj.correct_answer) ? questionObj.correct_answer.join(', ') : questionObj.correct_answer}
                                    </td>
                                    <td className="px-4 py-2">{questionObj.selectedOption || 'Omitted'}</td>
                                    <td className="px-4 py-2">
                                      <button
                                        className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                        onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.questionIds, questionObj.tag, selectedTest.type, engtag, index)}
                                      >
                                        View
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })
                            )}

                            {selectedTest.type === 'ACT' && (
                              engtag.map((questionObj, index) => {
                                return (
                                  <tr key={index} className={`border-b border-gray-200 hover:bg-gray-100 ${questionObj.correct ? '' : 'bg-red-200'}`}>
                                    <td className="px-4 py-2">{index + 1}</td>
                                    <td className="px-4 py-2">{questionObj.tag.charAt(0).toUpperCase() + questionObj.tag.slice(1)}</td>
                                    <td className="px-4 py-2 capitalize">
                                      {Array.isArray(questionObj.correct_answer) ? questionObj.correct_answer.join(', ') : questionObj.correct_answer}
                                    </td>
                                    <td className="px-4 py-2">{questionObj.selectedOption || 'Omitted'}</td>
                                    <td className="px-4 py-2">
                                      <button
                                        className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                        onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.qid, questionObj.tag, selectedTest.type, engtag, index)}
                                      >
                                        View
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })
                            )}
                          </tbody>
                        </table>

                        <table className="mt-[40px] min-w-full bg-white">
                          <thead>
                            <tr className="w-full bg-gray-200 text-left">
                              <th className="px-4 py-2">Question Id</th>
                              <th className="px-4 py-2">Section</th>
                              <th className="px-4 py-2">Correct Answers</th>
                              <th className="px-4 py-2">Your Answer</th>
                              <th className="px-4 py-2">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(selectedTest.type === 'SAT' || selectedTest.type === 'SAT10' || selectedTest.type === 'SAT89') && (
                              mathtag.map((questionObj, index) => {
                                return (
                                  <tr key={index} className={`border-b border-gray-200 hover:bg-gray-100 ${questionObj.correct ? '' : 'bg-red-200'}`}>
                                    <td className="px-4 py-2">{index + 1}</td>
                                    <td className="px-4 py-2">Math</td>
                                    <td className="px-4 py-2 capitalize">
                                      {
                                        Array.isArray(
                                          (() => {
                                            try {
                                              return JSON.parse(questionObj.correct_answer);
                                            } catch (e) {
                                              return questionObj.correct_answer;
                                            }
                                          })()
                                        )
                                          ? JSON.parse(questionObj.correct_answer).join(', ')
                                          : questionObj.correct_answer
                                      }
                                    </td>
                                    <td className="px-4 py-2">{questionObj.selectedOption || 'Ommitted'}</td>
                                    <td className="px-4 py-2">
                                      <button
                                        className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                        onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.questionIds, questionObj.tag, selectedTest.type, mathtag, index)}
                                      >
                                        View
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })
                            )}

                            {selectedTest.type === 'ACT' && (
                              mathtag.map((questionObj, index) => {
                                return (
                                  <tr key={index} className={`border-b border-gray-200 hover:bg-gray-100 ${questionObj.correct ? '' : 'bg-red-200'}`}>
                                    <td className="px-4 py-2">{index + 1}</td>
                                    <td className="px-4 py-2">Math</td>
                                    <td className="px-4 py-2 capitalize">
                                      {
                                        Array.isArray(
                                          (() => {
                                            try {
                                              return JSON.parse(questionObj.correct_answer);
                                            } catch (e) {
                                              return questionObj.correct_answer;
                                            }
                                          })()
                                        )
                                          ? JSON.parse(questionObj.correct_answer).join(', ')
                                          : questionObj.correct_answer
                                      }
                                    </td>
                                    <td className="px-4 py-2">{questionObj.selectedOption || 'Ommitted'}</td>
                                    <td className="px-4 py-2">
                                      <button
                                        className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                        onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.qid, questionObj.tag, selectedTest.type, mathtag, index)}
                                      >
                                        View
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })
                            )}
                          </tbody>
                        </table>

                        {selectedTest.type === 'ACT' && (
                          <table className="mt-6 min-w-full bg-white">
                            <thead>
                              <tr className="w-full bg-gray-200 text-left">
                                <th className="px-4 py-2">Question Id</th>
                                <th className="px-4 py-2">Section</th>
                                <th className="px-4 py-2">Correct Answers</th>
                                <th className="px-4 py-2">Your Answer</th>
                                <th className="px-4 py-2">View</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedTest.type === 'ACT' && (
                                readingtag.map((questionObj, index) => {
                                  return (
                                    <tr key={index} className={`border-b border-gray-200 hover:bg-gray-100 ${questionObj.correct ? '' : 'bg-red-200'}`}>
                                      <td className="px-4 py-2">{index + 1}</td>
                                      <td className="px-4 py-2">{questionObj.tag.charAt(0).toUpperCase() + questionObj.tag.slice(1)}</td>
                                      <td className="px-4 py-2 capitalize">
                                        {Array.isArray(questionObj.correct_answer) ? questionObj.correct_answer.join(', ') : questionObj.correct_answer}
                                      </td>
                                      <td className="px-4 py-2">{questionObj.selectedOption || 'Omitted'}</td>
                                      <td className="px-4 py-2">
                                        <button
                                          className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                          onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.qid, questionObj.tag, selectedTest.type, readingtag, index)}
                                        >
                                          View (ACT Q)
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        )}

                        {selectedTest.type === 'ACT' && (
                          <table className="mt-6 min-w-full bg-white">
                            <thead>
                              <tr className="w-full bg-gray-200 text-left">
                                <th className="px-4 py-2">Question Id</th>
                                <th className="px-4 py-2">Section</th>
                                <th className="px-4 py-2">Correct Answers</th>
                                <th className="px-4 py-2">Your Answer</th>
                                <th className="px-4 py-2">View</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedTest.type === 'ACT' && (
                                sciencetag.map((questionObj, index) => {
                                  return (
                                    <tr key={index} className={`border-b border-gray-200 hover:bg-gray-100 ${questionObj.correct ? '' : 'bg-red-200'}`}>
                                      <td className="px-4 py-2">{index + 1}</td>
                                      <td className="px-4 py-2">{questionObj.tag.charAt(0).toUpperCase() + questionObj.tag.slice(1)}</td>
                                      <td className="px-4 py-2 capitalize">
                                        {Array.isArray(questionObj.correct_answer) ? questionObj.correct_answer.join(', ') : questionObj.correct_answer}
                                      </td>
                                      <td className="px-4 py-2">{questionObj.selectedOption || 'Omitted'}</td>
                                      <td className="px-4 py-2">
                                        <button
                                          className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                          onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.qid, questionObj.tag, selectedTest.type, sciencetag, index)}
                                        >
                                          View
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                    }

                    {activeTab === 'english' && <div>
                      <div className="overflow-y-auto mt-[5px] flex justify-center items-center bg-blue-100 py-4">
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{engtag.length}</p>
                          <p className="text-sm font-medium">Total Questions</p>
                        </div>
                        <div className="border-l-2 border-gray-400"></div>
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{engtag.filter(e => e.correct).length}</p>
                          <p className="text-sm font-medium">Correct Answers</p>
                        </div>
                        <div className="border-l-2 border-gray-400"></div>
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{engtag.filter(e => !e.correct).length}</p>
                          <p className="text-sm font-medium">Incorrect Answers</p>
                        </div>
                      </div>

                      <div className="max-h-[540px] mt-4">
                        <table className="min-w-full bg-white">
                          <thead>
                            <tr className="w-full bg-gray-200 text-left">
                              <th className="px-4 py-2">Question Id</th>
                              <th className="px-4 py-2">Section</th>
                              <th className="px-4 py-2">Correct Answers</th>
                              <th className="px-4 py-2">Your Answer</th>
                              <th className="px-4 py-2">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {engtag.map((questionObj, index) => {
                              return (
                                <tr key={index} className={`border-b border-gray-200 hover:bg-gray-100 ${questionObj.correct ? '' : 'bg-red-200'}`}>
                                  <td className="px-4 py-2">{index + 1}</td>
                                  <td className="px-4 py-2">English</td>
                                  <td className="px-4 py-2 capitalize">
                                    {(() => {
                                      try {
                                        const parsedAnswer = JSON.parse(questionObj.correct_answer);
                                        return Array.isArray(parsedAnswer) ? parsedAnswer.join(', ') : questionObj.correct_answer;
                                      } catch {
                                        return questionObj.correct_answer;
                                      }
                                    })()}
                                  </td>
                                  <td className="px-4 py-2">{questionObj.selectedOption || 'Omitted'}</td>
                                  <td className="px-4 py-2">
                                    <button
                                      className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                      onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.qid, questionObj.tag, selectedTest.type, engtag, index)}
                                    >
                                      View
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>}

                    {activeTab === 'readingWriting' && <div>
                      <div className="overflow-y-auto mt-[5px] flex justify-center items-center bg-blue-100 py-4">
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{engtag.length}</p>
                          <p className="text-sm font-medium">Total Questions</p>
                        </div>
                        <div className="border-l-2 border-gray-400"></div>
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{engtag.filter(e => e.correct).length}</p>
                          <p className="text-sm font-medium">Correct Answers</p>
                        </div>
                        <div className="border-l-2 border-gray-400"></div>
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{engtag.filter(e => !e.correct).length}</p>
                          <p className="text-sm font-medium">Incorrect Answers</p>
                        </div>
                      </div>

                      <div className="max-h-[540px] mt-4">
                        <table className="min-w-full bg-white">
                          <thead>
                            <tr className="w-full bg-gray-200 text-left">
                              <th className="px-4 py-2">Question Id</th>
                              <th className="px-4 py-2">Section</th>
                              <th className="px-4 py-2">Correct Answers</th>
                              <th className="px-4 py-2">Your Answer</th>
                              <th className="px-4 py-2">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {engtag.map((questionObj, index) => {
                              return (
                                <tr key={index} className={`border-b border-gray-200 hover:bg-gray-100 ${questionObj.correct ? '' : 'bg-red-200'}`}>
                                  <td className="px-4 py-2">{index + 1}</td>
                                  <td className="px-4 py-2">Reading and Writing</td>
                                  <td className="px-4 py-2 capitalize">
                                    {(() => {
                                      try {
                                        const parsedAnswer = JSON.parse(questionObj.correct_answer);
                                        return Array.isArray(parsedAnswer) ? parsedAnswer.join(', ') : questionObj.correct_answer;
                                      } catch {
                                        return questionObj.correct_answer;
                                      }
                                    })()}
                                  </td>
                                  <td className="px-4 py-2">{questionObj.selectedOption || 'Ommited'}</td>
                                  <td className="px-4 py-2">
                                    <button
                                      className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                      onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.questionIds, questionObj.tag, selectedTest.type, engtag, index)}
                                    >
                                      View
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>}

                    {activeTab === 'math' && <div>
                      <div className="overflow-y-auto mt-[5px] flex justify-center items-center bg-blue-100 py-4">
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{mathtag.length}</p>
                          <p className="text-sm font-medium">Total Questions</p>
                        </div>
                        <div className="border-l-2 border-gray-400"></div>
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{mathtag.filter(e => e.correct).length}</p>
                          <p className="text-sm font-medium">Correct Answers</p>
                        </div>
                        <div className="border-l-2 border-gray-400"></div>
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{mathtag.filter(e => !e.correct).length}</p>
                          <p className="text-sm font-medium">Incorrect Answers</p>
                        </div>
                      </div>

                      <div className="max-h-[540px] mt-4">
                        <table className="min-w-full bg-white">
                          <thead>
                            <tr className="w-full bg-gray-200 text-left">
                              <th className="px-4 py-2">Question Id</th>
                              <th className="px-4 py-2">Section4</th>
                              <th className="px-4 py-2">Correct Answers</th>
                              <th className="px-4 py-2">Your Answer</th>
                              <th className="px-4 py-2">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mathtag.map((questionObj, index) => {
                              return (
                                <tr key={index} className={`border-b border-gray-200 hover:bg-gray-100 ${questionObj.correct ? '' : 'bg-red-200'}`}>
                                  <td className="px-4 py-2">{index + 1}</td>
                                  <td className="px-4 py-2">Math</td>
                                  <td className="px-4 py-2 capitalize">
                                    {
                                      Array.isArray(
                                        (() => {
                                          try {
                                            return JSON.parse(questionObj.correct_answer);
                                          } catch (e) {
                                            return questionObj.correct_answer;
                                          }
                                        })()
                                      )
                                        ? JSON.parse(questionObj.correct_answer).join(', ')
                                        : questionObj.correct_answer
                                    }                                  </td>
                                  <td className="px-4 py-2">{questionObj.selectedOption || 'Ommitted'}</td>
                                  <td className="px-4 py-2">
                                    {(selectedTest.type === 'SAT' || selectedTest.type === 'SAT10' || selectedTest.type === 'SAT89') && (
                                      <button
                                        className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                        onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.questionIds, questionObj.tag, selectedTest.type, mathtag, index)}
                                      >
                                        View
                                      </button>
                                    )}

                                    {selectedTest.type === 'ACT' && (
                                      <button
                                        className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                        onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.qid, questionObj.tag, selectedTest.type, mathtag, index)}
                                      >
                                        View
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>}

                    {activeTab === 'reading' && <div>
                      <div className="overflow-y-auto mt-[5px] flex justify-center items-center bg-blue-100 py-4">
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{engtag.length}</p>
                          <p className="text-sm font-medium">Total Questions</p>
                        </div>
                        <div className="border-l-2 border-gray-400"></div>
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{engtag.filter(e => e.correct).length}</p>
                          <p className="text-sm font-medium">Correct Answers</p>
                        </div>
                        <div className="border-l-2 border-gray-400"></div>
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{engtag.filter(e => !e.correct).length}</p>
                          <p className="text-sm font-medium">Incorrect Answers</p>
                        </div>
                      </div>

                      <div className="max-h-[540px] mt-4">
                        <table className="min-w-full bg-white">
                          <thead>
                            <tr className="w-full bg-gray-200 text-left">
                              <th className="px-4 py-2">Question Id</th>
                              <th className="px-4 py-2">Section</th>
                              <th className="px-4 py-2">Correct Answers</th>
                              <th className="px-4 py-2">Your Answer</th>
                              <th className="px-4 py-2">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {readingtag.map((questionObj, index) => {
                              return (
                                <tr key={index} className={`border-b border-gray-200 hover:bg-gray-100 ${questionObj.correct ? '' : 'bg-red-200'}`}>
                                  <td className="px-4 py-2">{index + 1}</td>
                                  <td className="px-4 py-2">Reading</td>
                                  <td className="px-4 py-2 capitalize">
                                    {(() => {
                                      try {
                                        const parsedAnswer = JSON.parse(questionObj.correct_answer);
                                        return Array.isArray(parsedAnswer) ? parsedAnswer.join(', ') : questionObj.correct_answer;
                                      } catch {
                                        return questionObj.correct_answer;
                                      }
                                    })()}
                                  </td>
                                  <td className="px-4 py-2">{questionObj.selectedOption || 'Omitted'}</td>
                                  <td className="px-4 py-2">
                                    <button
                                      className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                      onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.qid, questionObj.tag, selectedTest.type, readingtag, index)}
                                    >
                                      View
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>}

                    {activeTab === 'science' && <div>
                      <div className="overflow-y-auto mt-[5px] flex justify-center items-center bg-blue-100 py-4">
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{engtag.length}</p>
                          <p className="text-sm font-medium">Total Questions</p>
                        </div>
                        <div className="border-l-2 border-gray-400"></div>
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{engtag.filter(e => e.correct).length}</p>
                          <p className="text-sm font-medium">Correct Answers</p>
                        </div>
                        <div className="border-l-2 border-gray-400"></div>
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{engtag.filter(e => !e.correct).length}</p>
                          <p className="text-sm font-medium">Incorrect Answers</p>
                        </div>
                      </div>

                      <div className="max-h-[540px] mt-4">
                        <table className="min-w-full bg-white">
                          <thead>
                            <tr className="w-full bg-gray-200 text-left">
                              <th className="px-4 py-2">Question Id</th>
                              <th className="px-4 py-2">Section</th>
                              <th className="px-4 py-2">Correct Answers</th>
                              <th className="px-4 py-2">Your Answer</th>
                              <th className="px-4 py-2">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sciencetag.map((questionObj, index) => {
                              return (
                                <tr key={index} className={`border-b border-gray-200 hover:bg-gray-100 ${questionObj.correct ? '' : 'bg-red-200'}`}>
                                  <td className="px-4 py-2">{index + 1}</td>
                                  <td className="px-4 py-2">Science</td>
                                  <td className="px-4 py-2 capitalize">
                                    {(() => {
                                      try {
                                        const parsedAnswer = JSON.parse(questionObj.correct_answer);
                                        return Array.isArray(parsedAnswer) ? parsedAnswer.join(', ') : questionObj.correct_answer;
                                      } catch {
                                        return questionObj.correct_answer;
                                      }
                                    })()}
                                  </td>
                                  <td className="px-4 py-2">{questionObj.selectedOption || 'Omitted'}</td>
                                  <td className="px-4 py-2">
                                    <button
                                      className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                      onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.qid, questionObj.tag, selectedTest.type, sciencetag, index)}
                                    >
                                      View
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>}

                    {activeTab === 'topics' && <div>
                      <p className="text-2xl font-bold mt-[20px]">Missing Topics Overview</p>
                      <div className="overflow-y-auto flex justify-center items-center bg-blue-100 py-4">
                        <div className="text-center px-8">
                          <p className="text-4xl font-semibold">{missing_topics.length}</p>
                          <p className="text-sm font-medium">Total Missing Questions</p>
                        </div>
                      </div>

                      <div className="max-h-[450px] mt-4">
                        <table className="mt-[40px] min-w-full bg-white">
                          <thead>
                            <tr className="w-full bg-gray-200 text-left">
                              <th className="px-4 py-2">Question Id</th>
                              <th className="px-4 py-2">Topics</th>
                              <th className="px-4 py-2">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {missing_topics.map((questionObj, index) => {
                              return (
                                <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                                  <td className="px-4 py-2">{index + 1}</td>
                                  <td className="px-4 py-2">{questionObj.topic}</td>
                                  <td className="px-4 py-2">
                                    <button
                                      className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                      onClick={() => handleButtonClick(questionObj.selectedOption, questionObj.questionIds, selectedTest.test_name, questionObj.tag)}
                                    >
                                      View
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            )}

            {selectedTest.section_data == null && (
              <>
                <div className="bg-gray-100 p-4">
                  <ul className="flex cursor-pointer">
                    {tabs2.map(tab => (
                      <li
                        key={tab.id}
                        className={`flex-1 text-center py-2 px-4 ${activeTab === tab.id ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-600 hover:text-blue-600'}`}
                        onClick={() => setActiveTab(tab.id)}
                      >
                        {tab.title}
                      </li>
                    ))}
                  </ul>
                </div>

                {activeTab === 'all' && <div>
                  <div className="overflow-y-auto flex">
                    <p className="text-2xl font-bold mt-[20px]">Test Question Overview</p>
                    <button className="mt-[20px] text-sm p-2 bg-slate-600 hover:bg-slate-700 rounded-lg ml-[10px] text-white" onClick={() => gethelp()}>Get Help</button>
                  </div>
                  <div className="max-h-[450px] mt-4">
                    <table className="min-w-full bg-white">
                      <thead>
                        <tr className="w-full bg-gray-200 text-left">
                          <th className="px-4 py-2">Question Id</th>
                          <th className="px-4 py-2">Section</th>
                          <th className="px-4 py-2">Correct Answers</th>
                          <th className="px-4 py-2">Your Answer</th>
                          <th className="px-4 py-2">View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {JSON.parse(selectedTest.quiz_data).map((question, index) => {
                          const correctQuestion = question;
                          let correctAnswer;
                          try {
                            correctAnswer = correctQuestion.correct_answer;
                            if (Array.isArray(correctAnswer)) {
                              correctAnswer = correctAnswer.join(', ');
                            }
                          } catch {
                            correctAnswer = correctQuestion.correct_answer;
                          }

                          if (!correctAnswer) {
                            try {
                              correctAnswer = correctQuestion.correctAnswer[2];
                              if (Array.isArray(correctAnswer)) {
                                correctAnswer = correctAnswer.join(', ');
                              }
                            } catch {
                              correctAnswer = correctQuestion.correctAnswer;
                            }
                          }
                          return (
                            <tr key={index} className={`border-b border-gray-200 hover:bg-gray-100 ${question.correct ? '' : 'bg-red-200'}`}>
                              <td className="px-4 py-2">{index + 1}</td>
                              <td className="px-4 py-2">{question.topic} {question.category}</td>
                              <td className="px-4 py-2 capitalize">
                                {correctAnswer} {question.answer}
                              </td>
                              <td className="px-4 py-2">{question.selectedOption || 'Ommited'}</td>
                              <td className="px-4 py-2">
                                {selectedTest.type === 'ACT' ? (<>
                                  <button
                                    className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                    onClick={() => handleButtonClick(JSON.parse(selectedTest.quiz_data)[index].selectedOption, JSON.parse(selectedTest.quiz_data)[index].qid, question.section, selectedTest.type, JSON.parse(selectedTest.quiz_data), index)}
                                  >
                                    View
                                  </button>
                                </>) : (<>
                                  <button
                                    className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                    onClick={() => handleButtonClick(JSON.parse(selectedTest.quiz_data)[index].selectedOption, JSON.parse(selectedTest.quiz_data)[index].questionIds, question.tag, selectedTest.type, JSON.parse(selectedTest.quiz_data), index)}
                                  >
                                    View
                                  </button>
                                </>)}
                              </td>
                            </tr>
                          );

                        })}
                      </tbody>
                    </table>
                  </div>
                </div>}


              </>
            )}
          </div>
        </div >
      )}

      <div className={`mt-[-15px] fixed inset-0 flex items-center justify-center z-50 ${isModalOpen ? '' : 'hidden'}`}>
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-85"></div>
        </div>
        {tag === 'Eng' && (
          <div ref={boxItemLeftRef} className="relative bg-white w-[100%] h-[95%] overflow-auto">

            <div className="flex bg-slate-700 relative">
              {(selectedTest.type === 'SAT' || selectedTest.type === 'SAT10' || selectedTest.type === 'SAT89') && (<>
                <div className="text-white text-[24px] p-2 pl-[20px]">
                  Reading and Writing
                </div>
                <button className="bg-slate-700 text-white p-2 rounded-xl m-1" onClick={handlePreviousQuestion}>&lt; Left</button>
                <button className="bg-slate-700 text-white p-2 rounded-xl m-1" onClick={handleNextQuestion}>Right &gt;</button>
              </>)}
              {selectedTest?.type === 'ACT' && (<>
                <div className="text-[32px] font-bold pt-[5px] pl-[20px] pt-[20px] text-white">
                  {selectedTopic?.subject?.charAt(0).toUpperCase() + selectedTopic?.subject?.slice(1)}
                </div>
                <button className="bg-slate-700 text-white p-2 rounded-xl m-1" onClick={handlePreviousQuestion}>&lt; Left</button>
                <button className="bg-slate-700 text-white p-2 rounded-xl m-1" onClick={handleNextQuestion}>Right &gt;</button>
              </>)}
              <button className="absolute top-2 right-2 p-2" onClick={() => setIsModalOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="flex flex-col md:flex-row mt-[20px]">
              <div className="md:w-1/2 w-full mt-[20px] border-b-[1px] md:border-b-0 md:border-r-[1px] border-gray-300">
                {selectedTest.type === 'ACT' && (
                  selectedTopic?.subject === 'math' ? (
                    <div dangerouslySetInnerHTML={{ __html: selectedTopic?.prompt }} className="ml-[20px]" />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: selectedTopic?.content }} className="ml-[20px]" />
                  )
                )}
                {(selectedTest.type === 'SAT' || selectedTest.type === 'SAT10' || selectedTest.type === 'SAT89') && (
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: selectedTopic.stimulus }} className="max-h-[500px] overflow-y-auto overflow-x-hidden ml-[20px] mt-[10px]" />
                  </div>
                )}
                <label className="ml-[20px] mt-[20px] md:mt-[100px] flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={hideExplanation}
                    onChange={handleChange}
                    className="form-checkbox h-5 w-5 text-gray-600"
                  />
                  <span>Hide correct answer and explanation</span>
                </label>
              </div>
              <div className="md:w-1/2 w-full md:ml-[20px] md:mr-[20px]">
                <div className="flex flex-row justify-between bg-gray-100">
                  <div className="flex flex-row gap-2">
                    <div className="ml-[10px] p-2 flex flex-row items-center gap-1">
                      <div>
                        <p>Question Review</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-2 max-w-[600px] max-h-[480px] overflow-y-auto">
                  {(selectedTest.type === 'SAT' || selectedTest.type === 'SAT10' || selectedTest.type === 'SAT89') && (
                    <div dangerouslySetInnerHTML={{ __html: selectedTopic.stem }} className="ml-[20px]" />
                  )}
                  {selectedTest.type === 'ACT' && (
                    <div dangerouslySetInnerHTML={{ __html: selectedTopic?.prompt }} className="ml-[20px]" />
                  )}
                  {(selectedTest.type === 'SAT' || selectedTest.type === 'SAT10' || selectedTest.type === 'SAT89') && (
                    <>
                      <Option idx={0} data={selectedTopic.a} />
                      <Option idx={1} data={selectedTopic.b} />
                      <Option idx={2} data={selectedTopic.c} />
                      <Option idx={3} data={selectedTopic.d} />
                    </>
                  )}
                  {selectedTest.type === 'ACT' && <RenderOptions />}


                </div>

                {picked && selectedTopic?.correct_answer ?
                  `The user picked ${picked.charAt(0).toUpperCase() + picked.slice(1)} as the answer and the correct answer is ${selectedTopic.correct_answer.slice(2, -2)}`
                  : ''
                }
                {(selectedTest.type === 'SAT' || selectedTest.type === 'SAT10' || selectedTest.type === 'SAT89') && !hideExplanation && (
                  <div className="mt-[20px] mb-[40px]">
                    <div dangerouslySetInnerHTML={{ __html: selectedTopic.rationale2 ? selectedTopic.rationale2 : selectedTopic.rationale }} />
                  </div>
                )}

                {selectedTest.type === 'ACT' && !hideExplanation && (
                  <div className="mt-[20px]">
                    <div ref={boxItemLeftRef} dangerouslySetInnerHTML={{ __html: selectedTopic?.expl }} />
                  </div>
                )}

              </div>
            </div>
          </div>
        )}

        {tag === 'Math' && (
          <div className="relative bg-white w-[100%] h-[95%] overflow-auto">

            <div className="flex bg-slate-700 relative">
              <div className="text-white text-[24px] p-2 pl-[20px]">
                Math
              </div>

              <button className="bg-slate-700 text-white p-2 rounded-xl m-1" onClick={handlePreviousQuestion}>&lt; Left</button>
              <button className="bg-slate-700 text-white p-2 rounded-xl m-1" onClick={handleNextQuestion}>Right &gt;</button>

              <button className="absolute top-2 right-2 p-2" onClick={() => setIsModalOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 text-white">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {/* Main container for the two sections */}
            <div className="flex flex-col md:flex-row h-full mt-[20px]" ref={boxItemLeftRef}>
              {/* Top section on mobile, left section on desktop */}
              <div className="w-full md:w-1/2 md:border-r-[1px] border-gray-300 mt-5 md:mt-0">
                <div>
                  <div
                    dangerouslySetInnerHTML={{ __html: selectedTopic.stem }}
                    className="ml-[20px] mr-[20px] max-h-[500px] overflow-y-auto overflow-x-hidden mt-[10px]"
                  />
                </div>
                <label className="ml-[20px] mt-[20px] flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={hideExplanation}
                    onChange={handleChange}
                    className="form-checkbox h-5 w-5 text-gray-600"
                  />
                  <span>Hide correct answer and explanation</span>
                </label>
              </div>

              {/* Bottom section on mobile, right section on desktop */}
              <div className="w-full md:w-1/2 mt-[20px] md:ml-[20px] mr-[20px]">
                <div className="flex flex-row justify-between bg-gray-100">
                  <div className="flex flex-row gap-2">
                    <div className="ml-[20px] p-2 flex flex-row items-center gap-1">
                      <div>
                        <p>Question Review</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-2 max-w-[600px] max-h-[480px] overflow-y-auto">
                  {selectedTopic.a != null ? (
                    <>
                      <Option idx={0} data={selectedTopic.a} />
                      <Option idx={1} data={selectedTopic.b} />
                      <Option idx={2} data={selectedTopic.c} />
                      <Option idx={3} data={selectedTopic.d} />
                    </>
                  ) : (
                    <input className="ml-[20px] w-[90%] border-[1px] py-1 px-2 border-gray-300 outline-none rounded-md" type="text" />
                  )}
                </div>

                {picked && selectedTopic?.correct_answer ? (
                  `The user picked ${picked.charAt(0).toUpperCase() + picked.slice(1)} as the answer and the correct answer is ${selectedTopic.correct_answer.slice(2, -2)}`
                ) : (
                  ''
                )}

                {!hideExplanation && (
                  <div className="mt-[20px] ml-[15px] mr-[15px] mb-[60px]">
                    <div dangerouslySetInnerHTML={{ __html: selectedTopic.rationale2 ? selectedTopic.rationale2 : selectedTopic.rationale }} />
                  </div>
                )}
              </div>
            </div>
          </div>

        )}
      </div>
    </div >
  );
};

export default Dashboard;

const Option = ({ idx, data }) => {
  return (
    <div className="flex flex-row gap-1">
      <motion.div whileTap={{ scale: 0.98 }} className={`flex flex-row w-full border-[1px] rounded-md border-black gap-2 p-1 m-3 cursor-pointer relative`} >
        <div className="flex flex-row items-center">
          <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
            {getOptionLetter(idx)}
          </p>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </motion.div>
    </div>
  );
};

function getOptionLetter(idx) {
  switch (idx) {
    case 0:
      return 'A';
    case 1:
      return 'B';
    case 2:
      return 'C';
    case 3:
      return 'D';
    default:
      return "A";
  }
}

const Option2 = ({ sat, currentQuestion, option }) => {
  const optionLetter = option.charAt(0); // Extract the first character as the option letter
  const optionText = option.slice(2).trim(); // Extract the text part after the letter and period
  return (
    <div className="flex flex-row gap-1">
      <motion.div whileTap={{ scale: 0.98 }}
        className={`flex flex-row w-full ${currentQuestion.selectedOption === optionLetter.toLowerCase() ? "border-[2px]" : "border-[1px]"} rounded-md ${currentQuestion.selectedOption === optionLetter.toLowerCase() ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative"}`}
      >
        <div className="flex flex-row items-center">
          <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
            {optionLetter}
          </p>
        </div>
        <div className="flex flex-row items-center p-[2px]">
          {sat === "math" ? (<>
            <div dangerouslySetInnerHTML={{ __html: optionText }} />
          </>
          ) : (
            <p>{optionText}</p>
          )}
        </div>
      </motion.div>
    </div>
  );
}